import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable, of, } from 'rxjs';
import { catchError, finalize, } from 'rxjs/operators';

import { OAuthService, } from 'angular-oauth2-oidc';

import { Apollo, QueryRef, } from 'apollo-angular';
import { WatchQueryOptions, QueryOptions, ApolloQueryResult, MutationOptions, } from '@apollo/client/core';
import { FetchPolicy, FetchResult, OperationVariables, SubscriptionOptions } from '@apollo/client/core';//'apollo-link';

import { ToastrService } from 'ngx-toastr';
import { KeeMultiSelectComponent, SpinnerService } from '@saliente/library';
import { EmptyObject, ExtraSubscriptionOptions } from 'apollo-angular/types';


export interface KMutationOptions<T = {
    [key: string]: any;
}, TVariables = OperationVariables> extends MutationOptions<T, TVariables>{
	error?: boolean;
	showSpinner?: boolean;
}

export interface KQueryOptions extends QueryOptions {
	error?: boolean;
	showSpinner?: boolean;
	errorFunction?: (error: any) => void;
}


@Injectable()
export class RestService extends Apollo {

	constructor(private apollo: Apollo, private spinner: SpinnerService, private toastr: ToastrService, private http: HttpClient, private oauthService: OAuthService, ) {
		super(null, null, null, null);
	}

	public authHeaderValue(): string {
		if (this.oauthService.hasValidIdToken()) {
			return 'Bearer ' + this.oauthService.getIdToken();
		}
		return null;
	}

	public authHeader(): any {
		if (this.oauthService.hasValidIdToken()) {
			return { authorization: this.authHeaderValue() };
		}
		return null;
	}

	public watchQuery<TData, TVariables = EmptyObject>(options: WatchQueryOptions<TVariables, TData>): QueryRef<TData, TVariables> {
		return this.apollo.watchQuery<TData, TVariables>(options);
	}

	public query<T>(options: QueryOptions, handleOptions?: any): Observable<ApolloQueryResult<T>> {
		handleOptions = handleOptions || {};
		options.fetchPolicy = 'no-cache';

		let koptions: KQueryOptions = options as KQueryOptions;
		
		let showSpinner = true;
		if (handleOptions.spinner !== undefined)
			showSpinner = handleOptions.spinner !== false;
		if (koptions.showSpinner !== undefined)
			showSpinner = koptions.showSpinner;
		if (showSpinner) {
			this.spinner.show();
		}
		let result = this.apollo.query<T>(options);
		if (handleOptions.error !== false && koptions.error !== false) {
			result = result
				.pipe(
					catchError((error) => {
						if (handleOptions.error instanceof Function) {
							handleOptions.error(error);
						}
						else if (koptions.errorFunction != null) {
							koptions.errorFunction(error);
						}
						else {
							this.handleError(error);
						}
						if (showSpinner) {
							this.spinner.hide();
						}
						return of(null);
					})
				);
		}
		return result
			.pipe(
				finalize(() => {
					if (showSpinner) {
						this.spinner.hide();
					}
				})
			);
	}

	public mutate<T>(options: MutationOptions<T>, handleOptions?: any): Observable<FetchResult<T>> {

		let koptions: KMutationOptions = options as KMutationOptions;

		handleOptions = handleOptions || {};
		const showSpinner = handleOptions.spinner !== false || koptions.showSpinner;
		if (showSpinner) {
			this.spinner.show();
		}
		options.fetchPolicy = options.fetchPolicy || 'no-cache';
		let result = this.apollo.mutate<T>(options);
		if (handleOptions.error !== false && koptions.error !== false) {
			result = result
				.pipe(
					catchError((error) => {
						this.handleError(error);
						if (showSpinner) {
							this.spinner.hide();
						}
						if (handleOptions.error instanceof Function) {
							handleOptions.error(error);
						}
						return of(null);
					})
				);
		}
		return result
			.pipe(
				finalize(() => {
					if (showSpinner) {
						this.spinner.hide();
					}
				})
			);
	}

	public subscribe<T, V = EmptyObject>(handleOptions: any, extra?: ExtraSubscriptionOptions): Observable<FetchResult<T>> {
		handleOptions = handleOptions || {};
		const showSpinner = handleOptions.spinner !== false;
		if (showSpinner) {
			this.spinner.show();
		}
		let result = this.apollo.subscribe<T>(handleOptions, extra);
		if (handleOptions.error !== false) {
			result = result
				.pipe(
					catchError((error) => {
						this.handleError(error);
						if (handleOptions.error instanceof Function) {
							handleOptions.error(error);
						}
						if (showSpinner) {
							this.spinner.hide();
						}
						return of(null);
					})
				);
		}
		return result
			.pipe(
				finalize(() => {
					if (showSpinner) {
						this.spinner.hide();
					}
				})
			);
	}

	public httpGet(url: string, options?: any) {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authHeaderValue());
		const showSpinner = options.spinner !== false;
		if (showSpinner) {
			this.spinner.show();
		}
		return this.http
			.get(url, {
				headers,
			})
			.pipe(
				finalize(() => {
					if (showSpinner) {
						this.spinner.hide();
					}
				})
			);
	}

	public httpPost(url: string, body?: any, options?: any) {
		let headers = new HttpHeaders();
		headers = headers.append('authorization', this.authHeaderValue());
		const showSpinner = options.spinner !== false;
		if (showSpinner) {
			this.spinner.show();
		}
		return this.http
			.post(url, body, {
				headers,
			})
			.pipe(
				finalize(() => {
					if (showSpinner) {
						this.spinner.hide();
					}
				})
			);
	}

	public handleError(error: any) {
		let handled = false;
		if (error.graphQLErrors) {
			handled = this.handleGraphQLErrors(error.graphQLErrors);
		}
		else {
			handled = this.handleRestErrors(error);
		}
		if (!handled) {
			this.toastr.error('Am întâmpinat o eroare necunoscută. Te rog sa încerci din nou sau să ne contactezi.');
		}
	}

	public handleGraphQLErrors(errors: any[]): boolean {
		if (errors && errors.length) {
			const errorMessages: string[] = [];
			errors.forEach((error: any) => {
				if (error.httpError) {
					if (error.httpError.errors && error.httpError.errors.length) {
						error.httpError.errors.forEach((errorDetail: any) => {
							if (errorDetail.message) {
								errorMessages.push(errorDetail.message);
							}
						});
					} else if (error.httpError.Message) {
						errorMessages.push(error.httpError.Message);
					}
					else {
						errorMessages.push(error.httpError);
					}
				}
			});
			return this.displayErrors(errorMessages);
		}
		return false;
	}

	public handleRestErrors(httpError: any) {
		const errorMessages: string[] = [];
		
		if (httpError && httpError.error) {
			let error = httpError.error;
			if (error.errors && error.errors.length) {
				error.errors.forEach((errorDetail: any) => {
					if (errorDetail.message) {
						errorMessages.push(errorDetail.message);
					}
				});

				return this.displayErrors(errorMessages);
			}
		}

		return false;
	}

	public displayErrors(errorMessages: string[]): boolean {
		if (errorMessages.length) {
			let toastrMessage = errorMessages[0];
			if (errorMessages.length > 1) {
				toastrMessage = '';
				errorMessages.forEach((errorMessage: string) => {
					toastrMessage += `<li>${errorMessage}</li>`;
				});
				toastrMessage = `<ul>${toastrMessage}</ul>`;
			} else {
				toastrMessage = `<span class="ws-pre-line">${toastrMessage}</span>`;
			}
			this.toastr.error(toastrMessage, null, {
				enableHtml: true,
				messageClass: 'pl-3',
				timeOut: 5000,
			});
			return true;
		}
		return false;
	}

	public getErrorsCodes(error: any): string[] {
		var result: string[] = [],
			errors: any[] = error.graphQLErrors;

		if (errors && errors.length) {
			errors.forEach((error: any) => {
				if (error.httpError && error.httpError.Code) {
					result.push(error.httpError.Code);
				}
				if (error.httpError.errors) {
					error.httpError.errors.forEach((innerError: any) => {
						if (innerError.code) {
							result.push(innerError.code);
						}
					});
				}
			});
		}
		return result;
	}

}
