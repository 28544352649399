import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from "@angular/core";
import { ExpertDocumentItemComponent } from "src/app/expert/documents/expert-document-item.component";
import { ExpertDocumentsService } from "src/app/expert/documents/expert-documents.service";
import { AuthService } from "../auth/auth.service";
import { FinancialDocumentFileModel } from "../documents/financial-documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";
import { IClientDocumentItemParent } from "./client-document-item-parent";
import { DocumentDisplayService } from "./document-display.service";
import { IExpertDocumentItemParent } from "./expert-document-item-parent";

@Component({
	selector: 'expert-document-host',
	templateUrl: 'expert-document-host.component.html',
	providers: [{ provide: IExpertDocumentItemParent, useExisting: forwardRef(() => ExpertDocumentHostComponent) }]
})
export class ExpertDocumentHostComponent extends IExpertDocumentItemParent {
	@Input() public model: FinancialDocumentFileModel;
	@Input() public documentType: string;
	@Input() public documentsNavigator: NavigableDataSource<string, FinancialDocumentFileModel>;
	@Output() public close = new EventEmitter();

	@ViewChild('expertDocumentItem') private expertDocumentItem: ExpertDocumentItemComponent;

	public documentId: string;
	public isSuperExpert: boolean = false;
	public hasAdnotateRight: boolean = false;
	public hasDeleteConfirmationRight: boolean = false;
	public hasMarkNotUsableRight: boolean = false;
	public hasExpertOpsRight: boolean = false;

	constructor(public documentsService: ExpertDocumentsService, private authService: AuthService) {
		super();

		this.isSuperExpert = this.authService.isInRole('SUPEREXPERT');
		this.hasAdnotateRight = this.authService.isInRole('ACCNT_DOCUMENTS_ADNOTATE');
		this.hasDeleteConfirmationRight = this.authService.isInRole('ACCNT_DOCUMENTS_DELETE_CONFIRMATION');
		this.hasMarkNotUsableRight = this.authService.isInRole('ACCNT_DOCUMENTS_MARK_NOT_USABLE');
		this.hasExpertOpsRight = this.authService.isInRole('EXPERT_OPS');
	}

	ngOnInit() {
		this.documentId = this.model.id;

		setTimeout(() => {
			if (this.expertDocumentItem != undefined) {
				this.expertDocumentItem.showDocument = true;
			}
		}, 100);
	}

}