import { Type, Expose, Exclude } from 'class-transformer';

import { dateToInt, intToDate, isNullOrUndefined } from '@saliente/library';

export enum AccountingPeriodType {
	Accounting = 'ACCOUNTING',
	Payroll = 'PAYROLL',
}

export class AccountingPeriodTaskDefinitionModel {
	@Expose({ name: 'externalId' })
	id: string;
	description: string;
	displayOrder: number;
	isActive = true;

	@Exclude()
	startDate: Date;

	static typeProvider(): typeof AccountingPeriodTaskDefinitionModel {
		return AccountingPeriodTaskDefinitionModel;
	}
}

export class AccountingPeriodTaskModel {
	@Expose({ name: 'externalId' })
	id: string;
	isPerformed: boolean;
	comments: string;
	@Expose({ name: 'accountingPeriodTaskDefinition' })
	@Type(AccountingPeriodTaskDefinitionModel.typeProvider)
	definition: AccountingPeriodTaskDefinitionModel;

	static typeProvider(): typeof AccountingPeriodTaskModel {
		return AccountingPeriodTaskModel;
	}
}

export class AccountingPeriodCheckDefinitionModel {
	@Expose({ name: 'externalId' })
	id: string;
	checkCode: string;
	checkType: string;
	description: string;
	displayOrder: number;
	isActive = true;

	static typeProvider(): typeof AccountingPeriodCheckDefinitionModel {
		return AccountingPeriodCheckDefinitionModel;
	}
}
export class AccountingPeriodCheckModel {
	@Exclude()
	public displayIndex: number;

	@Expose({ name: 'externalId' })
	id: string;
	isChecked: boolean;
	isIgnored: boolean;
	comments: string;
	lastCheckResult: string;
	lastCheckedBy: string;

	@Type(AccountingPeriodCheckDefinitionModel.typeProvider)
	@Expose({ name: 'accountingPeriodCheckDefinition' })
	definition: AccountingPeriodCheckDefinitionModel;

	static typeProvider(): typeof AccountingPeriodCheckModel {
		return AccountingPeriodCheckModel;
	}
}

export type MonthClosingRepresentation = {
	comments: string;
  closedOn: string;
	isClosed: boolean;
	monthEnd: number;
	monthStart: number;
	turnover: number;
	type: string;
};

export type MonthClosingsRepresentation = {
	monthClosings: MonthClosingRepresentation[];
};

export class AccountingPeriodActionsStatusRepresentation {
	public bypassCanNotChangeIgnoreVat: boolean;
	public bypassCanNotChangeIgnoreVatDisabled: boolean;
	public canChangeIgnoreVAT: boolean;
	public canChangePNL: boolean;
	public canChangeVAT: boolean;
	public canRunRecordings: boolean;
	public isPNLOpen: boolean;
	public isVATOpen: boolean;
	public vatIgnored: boolean;
	public proposedVATForDateStart: number;
	public lastRunAmortizationsSuccess: boolean;
	public lastRunDeferralsSuccess: boolean;
	public lastRunRevaluationsSuccess: boolean;
	public lastRunIncomeTaxSuccess: boolean;
	public monthOpenInCiel: boolean;
  public decisionCaseId: number;
  public prevVATOpen: boolean;
  public nextVATOpen: boolean;
	monthClosings: MonthClosingsRepresentation;
	static typeProvider(): typeof AccountingPeriodActionsStatusRepresentation {
		return AccountingPeriodActionsStatusRepresentation;
	}
}

export class AccountingPeriodModel {
	public accountingPeriodType: string;
	public canChangeStatus: boolean;

	@Expose({ name: 'forDate' })
	public forDateInt: number;
	@Exclude()
	private _forDateEx: Date;
	@Exclude()
	get forDateEx(): Date {
		if (!this._forDateEx) {
			this._forDateEx = intToDate(this.forDateInt);
		}
		return this._forDateEx;
	}
	public isOpen: boolean;

	@Expose({ name: 'accountingPeriodCheckInstanceRepresentations' })
	@Type(AccountingPeriodCheckModel.typeProvider)
	checks: AccountingPeriodCheckModel[];

	@Expose({ name: 'accountingPeriodTaskInstanceRepresentations' })
	@Type(AccountingPeriodTaskModel.typeProvider)
	tasks: AccountingPeriodTaskModel[];

	@Type(AccountingPeriodActionsStatusRepresentation.typeProvider)
	public actionsStatus: AccountingPeriodActionsStatusRepresentation;

	public lastActionInsertedNotesResponse: GenericInsertedNotesResponseRepresentation;
  public errors: string[];

	public resetChecksIgnoredStatus: boolean;
	public vatComment: string;

	/* date bind to closeVAT date picker*/
	public forDateStartDate: Date = null;
	public accountingNotesTotal = 0;
	monthClosingsSortedDescVAT: MonthClosingRepresentation[];
	monthClosingsSortedDescPNL: MonthClosingRepresentation[];

	initializeForDateStartDate(): void {
		if (this.actionsStatus.proposedVATForDateStart != null) {
			this.forDateStartDate = intToDate(this.actionsStatus.proposedVATForDateStart);
		} else {
			this.forDateStartDate = null;
		}
	}
	computeAccountingNotesTotal(): void {
		this.accountingNotesTotal = 0;
		this.lastActionInsertedNotesResponse.accountingNoteInfos.forEach((ani) => {
			this.accountingNotesTotal += ani.Amount;
		});
	}

	getForDateStart(): number {
		if (this.forDateStartDate == null) {
			return null;
		} else {
			return dateToInt(this.forDateStartDate);
		}
	}

	/* we called a close action so we must update actionStatus related coputed properties*/
	public updateWithNewData(actionResult: AccountingPeriodActionResultRepresentation): void {
		this.actionsStatus = actionResult.actionsStatus;
		this.lastActionInsertedNotesResponse = actionResult.insertedNotesResponse;
		this.resetChecksIgnoredStatus = actionResult.resetChecksIgnoredStatus;
    /*we might have called a delete action so lastActionInsertedNotesResponse will be null*/
		if (!isNullOrUndefined(this.lastActionInsertedNotesResponse) && this.lastActionInsertedNotesResponse.response.ResultCode === 0) {
			this.computeAccountingNotesTotal();
		}
		this.initializeForDateStartDate();
		if (this.resetChecksIgnoredStatus === true) {
			this.checks.forEach((c) => {
				c.isIgnored = false;
			});
		}
		this.updateWithExistingData();
	}
	/* this is called after page load or action completed*/
	public updateWithExistingData(): void {
		this.monthClosingsSortedDescVAT = [
			...this.actionsStatus.monthClosings.monthClosings.filter((element) => {
				return element.type === 'VAT';
			}),
		];
		this.monthClosingsSortedDescVAT.reverse();
		this.monthClosingsSortedDescPNL = [
			...this.actionsStatus.monthClosings.monthClosings.filter((element) => {
				return element.type === 'PNL';
			}),
		];
		this.monthClosingsSortedDescPNL.reverse();
	}
	static typeProvider(): typeof AccountingPeriodModel {
		return AccountingPeriodModel;
	}
}
/*------------------Action (closeVAT/PNL, runAmortizations/Revaluations/Defferals)----------------------------*/
/*----------------Action variables-----------*/
export class CloseActionUsingPostMutationVariables {
	clientEid: string;
	forDate: number;
	isTestClosing: boolean;
}
export class CloseVATActionUsingPostMutationVariables {
	clientEid: string;
	forDateStart: number;
	forDate: number;
	isTestClosing: boolean;
}
/*----------------Action Response-------------*/
export class Response {
	Errors: string[];
	InsertedIds: number[];
	ResultCode: number;
	jsonResponse: string;
}

export class AccountingNoteInfo {
	Amount: number;
	Comment: string;
	Credit: string;
	Debit: string;
	// eslint-disable-next-line id-blacklist
	Number: string;
}

export class GenericInsertedNotesResponseRepresentation {
	accountingNoteInfos: AccountingNoteInfo[];
	response: Response;
}
export type GenericAccountingSystemResponseRepresentation = {
	Errors: string[];
	ResultCode: number;
	jsonResponse: string;
};
export class AccountingPeriodActionResultRepresentation {
	actionsStatus: AccountingPeriodActionsStatusRepresentation;
	insertedNotesResponse: GenericInsertedNotesResponseRepresentation;
  deleteActionResponse?: GenericAccountingSystemResponseRepresentation;
	resetChecksIgnoredStatus: boolean;
}
/*-------------runAmortizations--------------------*/
export type Accounts_RunAmortizationsUsingPostMutation = {
	accounts?: {
		runAmortizationsUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};
/*-------------runRevaluations--------------------*/
export type Accounts_RunRevaluationsUsingPostMutation = {
	accounts?: {
		runRevaluationsUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};
/*-------------runDefferals--------------------*/
export type Accounts_RunDefferalsUsingPostMutation = {
	accounts?: {
		runDeferralsUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
}; /*-------------runIncomeTax--------------------*/
export type Accounts_RunIncomeTaxUsingPostMutation = {
	accounts?: {
		runIncomeTaxUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};
/*-------------delete Actions----------------------------------*/
export type DeleteActionUsingDeleteVariables = {
  clientEid?: string;
  forDate?: number;
};
export type Accounts_DeleteAmortizationsUsingDeleteMutation = {
	accounts?: {
		deleteAmortizationsUsingDELETE?: AccountingPeriodActionResultRepresentation;
	};
};
export type Accounts_DeleteDefferalsUsingDeleteMutation = {
	accounts?: {
		deleteDeferralsUsingDELETE?: AccountingPeriodActionResultRepresentation;
	};
};
export type Accounts_DeleteRevaluationsUsingDeleteMutation = {
	accounts?: {
		deleteRevaluationsUsingDELETE?: AccountingPeriodActionResultRepresentation;
	};
};


/*-------------open/close/VAT/PNL variables--------------------*/
export type OpenActionUsingPostMutationVariables = {
	clientEid?: string;
	forDate?: number;
};
/*-------------openVAT--------------------*/
export type Accounts_OpenVATUsingPostMutation = {
	accounts?: {
		openVATUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};
/*-------------closeVAT--------------------*/
export type Accounts_CloseVATUsingPostMutation = {
	accounts?: {
		closeVATUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};
/*-------------openPNL--------------------*/
export type Accounts_OpenPNLUsingPostMutation = {
	accounts?: {
		openPNLUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};

/*-------------closePNL--------------------*/
export type Accounts_ClosePNLUsingPostMutation = {
	accounts?: {
		closePNLUsingPOST?: AccountingPeriodActionResultRepresentation;
	};
};

/*--------------------------Update VAT-----------------------------------*/
/*-----------------Comments-------------------------*/
export class AccountingPeriodUpdateRepresentationInput {
	vatComment: string;
}
/*---------------------Variables-------------------------*/
export class UpdateAccountingPeriodVatCommentUsingPutMutationVariables {
	clientEid: string;
	forDate: number;
	request: AccountingPeriodUpdateRepresentationInput;
}
/*-----------------Ignore--------------------------*/
export type IgnoreVatUsingPutMutationVariables = {
	clientEid?: string;
	forDate?: number;
	ignore?: boolean;
};
export type Accounts_IgnoreVatUsingPutMutation = {
	accounts?: {
		ignoreVATUsingPUT?: AccountingPeriodActionsStatusRepresentation;
	};
};
