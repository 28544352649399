import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@saliente/common-auth';
import { EXPERT_ROUTES } from './expert.routes.constants';

const routes: Routes = [
  {
    path: EXPERT_ROUTES.ROOT_1,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: EXPERT_ROUTES.DASHBOARD,
        loadChildren: () => import('./dashboard/expert-dashboard.module').then(m => m.ExpertDashboardModule)
      },
      {
        path: EXPERT_ROUTES.STATUTORY_REPORTS_DASHBOARD,
        data:{
          role:'EXPERT'
        },
        loadChildren: () => import('./statutory-reports-dashboard/statutory-reports-dashboard.module').then(m => m.StatutoryReportsDashboardModule)
      },
      {
        path: EXPERT_ROUTES.ANAF_MESSAGES,
        loadChildren: () => import('./anaf/anaf-messages.module').then(m => m.AnafMessagesModule)
      },
      {
        path: EXPERT_ROUTES.BALANCE_SHEET_DASHBOARD,
        loadChildren: () => import('./balance-sheet-dashboard/balance-sheet-dashboard.module').then(m => m.BalanceSheetDashboardModule)
      },
      {
        path: EXPERT_ROUTES.FISA_ROL_SUMAR,
        loadChildren: () => import('./fisa-rol-sumar/fisa-rol-sumar.module').then(m => m.FisaRolSumarModule)
      },
      {
        path: EXPERT_ROUTES.TAKEOVER_DASHBOARD,
				data: { accessRole: 'TAKEOVER_VIEW' },
        loadChildren: () => import('../takeover/takeover-dashboard/takeover-dashboard.module').then(m => m.TakeoverDashboardModule)
      },
      {
        path: EXPERT_ROUTES.DOCUMENTS,
        loadChildren: () => import('./documents/expert-documents.module').then(m => m.ExpertDocumentsModule)
      },
      {
        path: EXPERT_ROUTES.DOCUMENTS_ACCURACY,
        loadChildren: () => import('./documents-summary/documents-accuracy/expert-documents-accuracy.module').then(m => m.ExpertDocumentsAccuracyModule)
      },
      {
        path: EXPERT_ROUTES.DOCUMENTS_CHECK,
        loadChildren: () => import('./documents-summary/documents-check/expert-documents-check.module').then(m => m.ExpertDocumentsCheckModule)
      },
      {
        path: EXPERT_ROUTES.DOCUMENTS_TASK,
        loadChildren: () => import('./documents-summary/documents-task/expert-documents-task.module').then(m => m.ExpertDocumentsTaskModule )
      },
      {
        path: EXPERT_ROUTES.DOCUMENTS_SUMMARY,
        loadChildren: () => import('./documents-summary/documents-summary/expert-documents-summary.module').then(m => m.ExpertDocumentsSummaryModule)
      },
      {
        path: EXPERT_ROUTES.ACC_NOTES,
        loadChildren: () => import('./accnotes/accnotes.module').then(m => m.AccNotesModule)
      },
      {
        path: EXPERT_ROUTES.CLOSING_AUDIT,
        loadChildren: () => import('./closing-audit/closing-audit.module').then(m => m.ExpertClosingAuditModule)
      },
      {
        path: EXPERT_ROUTES.STATUTORY_REPORTS,
        loadChildren: () => import('./documents-summary/statutory-reports-processors/expert-statutory-reports-processors.module').then(m => m.ExpertStatutoryReportsProcessorsModule)
      },
      {
        path: EXPERT_ROUTES.CHART_OF_ACCOUNTS,
        loadChildren: () => import('./chart-of-accounts/chart-of-accounts.module').then(m => m.ChartOfAccountsModule)
      },
      {
				path: EXPERT_ROUTES.PAYMENT_ALLOCATION,
        loadChildren: () => import('./payment-allocation/payment-allocation.module').then(m => m.ClientPaymentAllocationModule)
			},
      {
				path: EXPERT_ROUTES.OPERATIONAL_DASHBOARD,
				data: { accessRole: 'ZEUS_OPS', reportName: "Headers", section: 'operationalDashboard', screenName: 'Dashboard Operational', tabName: 'Dashboard Operational'},
				loadChildren: () => import('../clients/reports/financial/financial-reports.module').then(m => m.ClientsFinancialReportsModule)
			},
    ]
  }
];

// @dynamic
@NgModule({
  imports: [RouterModule.forRoot(routes, { /*relativeLinkResolution: 'legacy'*/ })],
  exports: [RouterModule]
})
export class ExpertRouterModule {

}
