import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'blur-document',
	templateUrl: 'blur-document.component.html',
	styleUrls: ["blur-document.component.scss"],
})
export class BlurDocumentComponent implements OnInit {
	@Input() public model: any;

	@Output() public save = new EventEmitter();
	@Output() public close = new EventEmitter();

	public get reasonCode(): string {
		return this.model?.reasonCode;
	}
	public set reasonCode(value: string) {
		if (this.model && this.model.reasonCode != value) {
			this.model.reasonCode = value;
			switch (this.model.reasonCode) {
				case 'neclar':
					this.model.comment = 'Documentul este neclar și nu poate fi citit. Te rog să refaci poza.'
					break;
				case 'irelevant':
					this.model.comment = 'Documentul este irelevant. Avizele, contractele, etc., nu se înregistrează în contabilitate.'
					break;
				case 'non-eFactura':
					this.model.comment = 'Acest document este o factură de achiziție non e-Factura cu data mai mare de 01 Iulie. Aceste documente nu mai sunt considerate documente contabile și nu se mai întegistrează. Vă rugăm să ștergeți acest document și să intrați în meniul Facturare/e-Factura/Inbox și să trimiteți în contabilitate facturile. Mai multe detalii aici: https://app.keez.ro/help/client/web_app/facturare/eFactura_2024_restul.html'
					break;
				default:
					this.model.comment = '';
			}
		}
	}

	constructor() { }

	public ngOnInit() {
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

}
