import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';

import { PredefinedUserZone, } from '@saliente/common-auth';
import { AppMenuService, AppMenuModel, } from '@saliente/common-appmenu';

import { CLIENTS_ROUTES } from '@saliente/clients';
import { HR_EXPERT_ROUTES } from '../hrexpert.routes.constants';
import { PAYROLL_ROUTES } from '@saliente/payroll';
import { REVISAL_ROUTES } from 'src/app/revisal/revisal.routes.constants';
import { EXPERT_ROUTES } from 'src/app/expert/expert.routes.constants';


@Injectable()
export class HrExpertMenuService {

	constructor(private appMenuService: AppMenuService) {
		/** MAIN MENUS */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			accessRole: 'CONNECT_PAYROLL_ACCESS',
			zone: PredefinedUserZone.HrExpert,
			text: 'Connect',
			isChat: true,
			icon: 'mdi-communication-chat',
			link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CHAT],
		}));

		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			home: true,
			//accessRole: 'PREVIEW',
			zone: PredefinedUserZone.HrExpert,
			text: 'Declarațiile mele',
			icon: 'mdi-notification-event-available',
			link: [HR_EXPERT_ROUTES.ROOT, HR_EXPERT_ROUTES.STATUTORY_REPORTS_DASHBOARD]
		}));
		
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.HrExpert,
			accessRole: 'TAKEOVER_VIEW',
			text: 'Preluările mele',
			icon: 'mdi-communication-call-merge mdi-rotate-180',
			link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.TAKEOVER_DASHBOARD]
		}));


    	/** PAYROLL */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.HrExpert,
			text: 'Salarizare',
			//class: 'grey lighten-2',
			icon: 'mdi-communication-vpn-key',
			canExpand: true,
			alwaysExpanded: false,
			isAtFirstExpanded: true,
			isPayrollMainMenu: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_DOCUMENTS_VIEW',
					home: false,
					zone: PredefinedUserZone.HrExpert,
					text: 'Documente',
					icon: 'mdi-content-content-copy',
					class: 'child',
					link: [HR_EXPERT_ROUTES.ROOT, HR_EXPERT_ROUTES.DOCUMENTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'EMPLOYEE_VIEW',
					zone: PredefinedUserZone.HrExpert,
					text: 'Salariați',
					isSalariatiMenu: true,
					icon: 'mdi-social-people',
					class: 'child',
					//link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.EMPLOYEES]
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.EMPLOYEES],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_TIMESHEET_VIEW',
					zone: PredefinedUserZone.HrExpert,
					text: 'Pontaj & Stat',
					icon: 'mdi-av-timer',
					class: 'child',
					leafLink: true,
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.CALCULATION_ROOT, 'vacation'],
				}),
				// plainToClass(AppMenuModel, {
				// 	zone: PredefinedUserZone.Administrator,
				// 	accessRole: 'PAYROLL_TIMESHEET_VIEW',
				// 	text: 'Pontaj',
				// 	icon: 'mdi-av-timer',
				// 	class: 'child',
				// 	link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TIMESHEET],
				// }),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.HrExpert,
					text: 'Concedii medicale',
					icon: 'mdi-maps-local-hospital',
					class: 'child',
					link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.SICK_LEAVE],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.HrExpert,
					text: 'Declarații',
					icon: 'mdi-av-new-releases',
					class: 'child',
					link: [HR_EXPERT_ROUTES.ROOT, HR_EXPERT_ROUTES.STATUTORY_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'PAYROLL_REPORTS_VIEW',
					zone: PredefinedUserZone.HrExpert,
					text: 'Rapoarte',
					icon: 'mdi-file-folder-shared',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.PAYROLL_HOME]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.HrExpert,
					text: 'Revisal',
					icon: 'mdi-action-book',
					class: 'child',
					link: [REVISAL_ROUTES.ROOT, REVISAL_ROUTES.CLIENTS],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.HrExpert,
					text: 'Cereri Revisal',
					icon: 'mdi-av-playlist-add',
					class: 'child',
					link: [REVISAL_ROUTES.ROOT, REVISAL_ROUTES.REVISAL_REQUESTS],
				}),
			]
		}));
		

		/** CONFIG */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.HrExpert,
			text: 'Configurări',
			//class: 'grey lighten-2',
			icon: 'mdi-action-settings',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					accessRole: 'CONFIG_COMPANY_VIEW',
					zone: PredefinedUserZone.HrExpert,
					text: 'Date firmă',
					icon: 'mdi-social-location-city',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.COMPANY],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.HrExpert,
					text: 'Formulare',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISC, CLIENTS_ROUTES.TEMPLATES],
				}),
				plainToClass(AppMenuModel, {
					text: 'Parametri globali',
					accessRole:'ZEUS_MARKER',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [HR_EXPERT_ROUTES.ROOT, HR_EXPERT_ROUTES.GLOBAL_PARAMETERS],
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'TAKEOVER_VIEW',
					zone: PredefinedUserZone.HrExpert,
					text: 'Preluare',
					icon: 'mdi-communication-call-merge mdi-rotate-180',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TAKEOVER],
				})
			]
		}));
	}
}
