import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppMenuModel } from './appmenu.models';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AppMenuService {
	private menus: AppMenuModel[] = [];
	private zonesMenus: any = {};
	private homePages: any = {};

	constructor(private authService: AuthService, private router: Router) {
		this.authService.getSelectedCompanyId().subscribe((clientEid) => {
			this.menus.forEach((menu) => {
				menu.disabled = !!menu.accessRole && !this.authService.isInRole(menu.accessRole);
				if (menu.accessRole === 'DASHBOARD_VIEW') {
					console.log('getSelectedCompanyId.subscribe:' + menu.accessRole + '/' + clientEid + '/' + menu.disabled);
				}
				menu.isVisible = !(!!menu.visibleRole && !this.authService.isInRole(menu.visibleRole));

				if (menu.childrenMenu != null && menu.childrenMenu.length > 0) {
					menu.childrenMenu.forEach((childMenu) => {
						childMenu.disabled = !!childMenu.accessRole && !this.authService.isInRole(childMenu.accessRole);
						childMenu.isVisible = !(!!childMenu.visibleRole && !this.authService.isInRole(childMenu.visibleRole));
					});
				}
			});
		});
	}

	public addMenu(appMenu: AppMenuModel): void {
		const menu = appMenu.clone();

		if (menu.home) {
			this.homePages[menu.zone] = menu.link;
		}

		!menu.disabled && (menu.disabled = !!menu.accessRole && !this.authService.isInRole(menu.accessRole));
		menu.isVisible && (menu.isVisible = !(!!menu.visibleRole && !this.authService.isInRole(menu.visibleRole)));
		if (menu.childrenMenu != null && menu.childrenMenu.length > 0) {
			menu.childrenMenu.forEach((childMenu) => {
				!childMenu.disabled && (childMenu.disabled = !!childMenu.accessRole && !this.authService.isInRole(childMenu.accessRole));
				childMenu.isVisible && !(childMenu.isVisible = !!childMenu.visibleRole && !this.authService.isInRole(childMenu.visibleRole));

				if (childMenu.home) {
					this.homePages[childMenu.zone] = childMenu.link;
				}
			});
		}

		this.menus.push(menu);

		if (!this.zonesMenus[menu.zone]) {
			this.zonesMenus[menu.zone] = [];
		}
		this.zonesMenus[menu.zone].push(menu);
	}

	public getMenus(): any[] {
		const selectedZoneCode = this.authService.selectedZoneCode;
		if (selectedZoneCode) {
			return this.zonesMenus[selectedZoneCode] || [];
		}
		return [];
	}

	public getHomePage(): any[] {
		const selectedZoneCode = this.authService.selectedZoneCode;
		return this.homePages[selectedZoneCode];
	}

	public setMenuAndChildrenDisabled(menuName: string, isVisible: boolean) {
		const menus = this.getMenus();
		const mainMenu = menus.find((m: any) => m.text === menuName);
		if (mainMenu != undefined) {
			if (mainMenu.childrenMenu != undefined) {
				mainMenu.childrenMenu.forEach((menu: any) => {
					menu.disabled = !isVisible;
				});
			} else {
				mainMenu.disabled = !isVisible;
			}
		}
	}

	public setMenuVisibility(menuName: string, isVisible: boolean) {
		const menus = this.getMenus();
		if (menus != undefined) {
			menus.forEach((menu) => {
				if (menu.text == menuName) {
					menu.isVisible = isVisible;
				}

				if (menu.childrenMenu != undefined) {
					menu.childrenMenu.forEach((childMenu: any) => {
						if (childMenu.text == menuName) {
							childMenu.isVisible = isVisible;
						}
					});
				}
			});
		}
	}

	public setMenuAndChildrenVisibility(menuName: string, isVisible: boolean) {
		const menus = this.getMenus();
		if (menus != undefined) {
			menus.forEach((menu) => {
				if (menu.text == menuName) {
					menu.isVisible = isVisible;

					if (menu.childrenMenu != undefined) {
						menu.childrenMenu.forEach((childMenu: any) => {
							childMenu.isVisible = isVisible;
						});
					}
				}
			});
		}
	}
}
