<kendo-dialog *ngIf="showFileCommentForm" i18n-title="@@ClientDocuments$AddNewComment" title="Adăugare adnotare"
	(close)="closeDocumentFileComment(false)" [minWidth]="250" [width]="450">
	<document-file-comment [model]="documentFileCommentModel" isModal=true (close)="closeDocumentFileComment(false)"
		(save)="closeDocumentFileComment(true, $event)">
	</document-file-comment>
</kendo-dialog>

<kendo-dialog *ngIf="showDeleteDocumentFileForm" i18n-title="@@ClientDocuments$DeleteDocument" title="Ștergere document"
	(close)="closeDeleteDocumentFile(false)" [minWidth]="250" [width]="450">
	<client-delete-document-file [model]="deleteDocumentFileModel" note="NOTĂ! Adnotarea nu va fi transmisă, va fi utilizată pentru păstrarea de informații suplimentare despre această
	tranzactie." isModal=true (close)="closeDeleteDocumentFile(false)" (save)="closeDeleteDocumentFile(true, $event)">
	</client-delete-document-file>
</kendo-dialog>

<kendo-dialog *ngIf="showUnblurDocumentFileForm" i18n-title="@@ClientDocuments$UnblurDocument" title="Clarifică document"
	(close)="closeUnblurDocumentFile(false)" [minWidth]="250" [width]="450">
	<client-unblur-document-file [model]="unblurDocumentFileModel" isModal=true (close)="closeUnblurDocumentFile(false)"
		(save)="closeUnblurDocumentFile(true, $event)">
	</client-unblur-document-file>
</kendo-dialog>

<kendo-dialog title="Document Info" *ngIf="showInfoDialog" (close)="closeInfoDialog()" [minWidth]="250" [width]="450">
	<document-item-info-dialog [model]="infoDialogModel">
	</document-item-info-dialog>
</kendo-dialog>

<kendo-dialog title="Schimbă angajat" *ngIf="showChangeEmployeeDialog" (close)="closeChangeEmploeeDialog(false)" [minWidth]="250" [width]="450">
	<document-change-employee (close)="closeChangeEmploeeDialog(false)" (save)="closeChangeEmploeeDialog(true, $event)">
	</document-change-employee>
</kendo-dialog>

<div class="d-flex client-document overflow-y-hidden">
	<input type="file" accept="application/pdf,image/*" class="d-none" (change)="onReplaceDocumentFileChange($event)" #replaceDocumentFile />
	<div class="col-24 cdie card align-self-center client-document-item">
		<!-- Colored content by document status -->
		<div class="col-24 d-flex flex-wrap cdie-content {{extendedOverlayClass()}}">
			<!-- Gallery  -->
			<div class="col-14 d-flex cdi-content-gallery">
				<div class="cdi-sizer">
					<div *ngIf="showDocument" class="cdi-container">
						<div class="cdie-multipage-container">
							<div class="cdie-multipage white-text">
								<i *ngIf="model.totalPages < 10" class="multipage-icon mdi-image-filter-{{model.totalPages}}"></i>
								<i *ngIf="model.totalPages > 9" class="multipage-icon mdi-image-filter-9-plus"></i>
							</div>
						</div>

						<kee-gallery class="cdi-gallery {{model.totalPages < 2 ? 'singlepage': 'multipage'}}" [gallery-options]="galleryOptions"
							[gallery-images]="galleryImages" [lazy]="true">
						</kee-gallery>
					</div>
				</div>
			</div>

			<!-- Actions -->
			<div *ngIf="showDocument" class="col p-0 cdi-content-actions">
				<div class="cdi-actions">
					<a *ngIf="allowAdnotate && model.status.statusCode !== DocumentFileStatus.NotUsable" class="cdi-button black-text"
						i18n-title="@@ClientDocuments$Comments" title="Adaugă adnotare" (click)="addComment(model)">
						<i class="mdi-content-gesture"></i>
					</a>
					<a *ngIf="allowMarkUsabe && model.status.statusCode === DocumentFileStatus.NotUsable" class="cdi-button black-text"
						i18n-title="@@ClientDocuments$Unblur" title="Clarifică document" (click)="unblur(model)">
						<i class="mdi-image-healing"></i>
					</a>
					<a class="cdi-button black-text"
						i18n-title="@@ClientDocuments$OriginalImage" title="Vizualizare imagine" (click)="downloadFullDocument(model)">
						<i class="mdi-image-crop-original"></i>
					</a>
					<a *ngIf="model.isInvoice" class="cdi-button black-text" i18n-title="@@ClientDocuments$GotToInvoice" title="Du-te la factură"
						(click)="goToInvoice(model)">
						<i class="mdi-action-launch"></i>
					</a>
					<a *ngIf="!model.isInvoice || model.isEFacturaSigned" href="{{ getOriginalDocumentSrc() }}" target="_blank" class="cdi-button black-text" 
						i18n-title="@@ClientDocuments$Download" title="Descarcă">
						<i class="mdi-file-file-download"></i>
					</a>
					<a *ngIf="isDeleteVisible" class="cdi-button black-text"
						i18n-title="@@ClientDocuments$Delete" title="Șterge" (click)="delete(model)">
						<i class="mdi-action-delete"></i>
					</a>
					<a *ngIf="allowMarkUsabe && model.status.statusCode === DocumentFileStatus.NotUsable" class="cdi-button black-text"
						i18n-title="@@ClientDocuments$Replace" title="Inlocuiește" (click)="replace(model)">
						<i class="mdi-navigation-refresh"></i>
					</a>
				</div>
			</div>

			<!-- Document info -->
			<div *ngIf="showDocument" class="col-10 d-flex pr-0 cdi-content-document">
				<div class="d-flex flex-column pl-3 col-24 px-0">
					<!-- Title & Navigation & Actions -->
					<div class="d-flex align-items-center">
                        <h2 class="col mb-0 text-capitalize" i18n="@@ClientDocuments$DocumentDetailsHeader" (click)="showInfo($event, model)">{{ model.documentDateEx | date: 'MMMM yyyy' }}</h2>
                        <div *ngIf="hasNavigation()" class="prev-button mt-1 mb-1 {{hasPreviousRecord() ? 'k-overlay-secondary cursor-pointer' : 'disabled'}}">
                            <i class="font-size-1-5 align-self-center mdi-hardware-keyboard-arrow-left white-text" (click)="getPreviousDocument()"></i>
                        </div>
                        <div *ngIf="hasNavigation()" class="next-button mt-1 mb-1 {{hasNextRecord() ? 'k-overlay-secondary cursor-pointer' : 'disabled'}}">
                            <i class="font-size-1-5 align-self-center mdi-hardware-keyboard-arrow-right white-text" (click)="getNextDocument()"></i>
                        </div>
                        <i class="col-auto mdi-content-link cursor-pointer font-size-1-5 mt-1 mb-1 mr-1" title="Copiază link document pentru Connect" (click)="copyDocumentUrl()"></i>
                        <i class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                    </div>
					<div class="row divider black mx-1 my-2"></div>

					<div class="d-flex">
						<div class="cdi-details col-24">
							<div>
								<label i18n="@@ClientDocuments$DocumentState">Stare:</label>
								<span> {{documentStatusName}}</span>
							</div>
							<div>
								<label i18n="@@ClientDocuments$FileName">Fișier:</label>
								<span> {{model.metadataEx.fileName}}</span>
							</div>
							<div *ngIf="(!displayAccMetadata || !displayAccMetadata.documentDate) && model.status.statusCode === DocumentFileStatus.Booked">
								<label i18n="@@ClientDocuments$DocumentDateLabel">Data document:</label>
								<span> {{model.documentDateEx | date: 'dd.MM.yyyy'}}</span>
							</div>
							<div>
								<label i18n="@@ClientDocuments$DocumentScanDate">Data încărcării:</label>
								<span> {{model.created.dateTime | date: 'dd.MM.yyyy HH:mm'}}</span>
							</div>
							<div>
								<label i18n="@@ClientDocuments$DocumentStateUser">Utilizator:</label>
								<span> {{model.created.userName || ''}}</span>
							</div>
							<div *ngIf="model.status.statusCode === DocumentFileStatus.NotUsable">
								<label i18n="@@ClientDocuments$DocumentStateDateLabel">Dată marcare neclar:</label>
								<span> {{model.status.dateTime | date: 'dd.MM.yyyy'}}</span>
							</div>
							<div *ngIf="allowViewCostCenter" class="d-flex align-items-center">
								<label class="col-auto px-0 mb-0" i18n="@@ClientDocuments$CostCenter">Centru de cost:</label>
								<inputfield *ngIf="allowEditCostCenter" name="costCenter" class="col no-materialize-margin max-width-150 input"
									[(ngModel)]="costCenter">
								</inputfield>
								<i *ngIf="allowEditCostCenter" class="mdi-content-save mdi-xl cursor-pointer pink-text text-accent-2"
									(click)="setCostCenter()"></i>
								<span *ngIf="!allowEditCostCenter"> {{costCenter}}</span>
							</div>
							<div *ngIf="model.isEFacturaSigned" class="e-factura-signed d-flex flex-wrap align-items-center">
								<div class="divider black col-24 my-2"></div>
								<i class="e-factura-signed-icon mdi-action-verified-user mr-1 green-text text-darken-2"></i> 
								<span>e-Factura validă</span>
							</div>
							<button *ngIf="allowChangeEmployee" class="col-auto btn btn-standard secondary-text px-0 pb-0 mt-1" (click)="openChangeEmployeeDialog()">
								<span i18n="@@ClientDocuments$ChangeEmployee">Schimbă angajat</span>
							</button>
							<div *ngIf="displayAccMetadata" class="d-flex flex-wrap">
								<div class="divider black col-24 my-2"></div>
								<div *ngIf="displayAccMetadata.entryTypeName" class="col-24 px-0">
									<span>{{displayAccMetadata.entryTypeName}}</span>
								</div>
								<div class="d-flex flex-wrap col-24 px-0 d-flex align-items-baseline">
									<div *ngIf="displayAccMetadata.documentNumber" class="col-auto px-0 d-flex align-items-baseline">
										<label i18n="@@ClientDocuments$DocumentLabel">Număr</label>
										&nbsp;
										<span>{{displayAccMetadata.documentNumber}}</span>
										&nbsp;
										<label i18n="@@ClientDocuments$DocumentDateLabel">data</label>
										&nbsp;
										<span *ngIf="displayAccMetadata.documentDate">{{displayAccMetadata.documentDate | date: 'dd.MM.yyyy'}}</span>
										&nbsp;
									</div>
									<div *ngIf="!displayAccMetadata.documentNumber && displayAccMetadata.documentDate"
										class="col-auto px-0 d-flex align-items-baseline">
										<label i18n="@@ClientDocuments$DocumentDateLabel">Data documentului</label>
										&nbsp;
										<span>{{displayAccMetadata.documentDate | date: 'dd.MM.yyyy'}}</span>
										&nbsp;
									</div>
									<div *ngIf="displayAccMetadata.dueDate || displayAccMetadata.dueDateVisible"
										class="col-auto px-0 d-flex align-items-baseline">
										<label i18n="@@ClientDocuments$DocumentDueDateLabel">scadența la</label>
										&nbsp;
										<kee-datepicker name="dueDate" class="col-auto max-width-125 px-1 inline-input"
											[(ngModel)]="displayAccMetadata.dueDate">
										</kee-datepicker>
										&nbsp;
										<i class="mdi-content-save mdi-xl cursor-pointer pink-text text-accent-2" (click)="updateDueDate()"></i>
									</div>
								</div>
								<div *ngIf="displayAccMetadata.partnerName" class="col-24 px-0">
									<label i18n="@@ClientDocuments$PatrnerLabel">Partener</label>
									&nbsp;
									<span>{{displayAccMetadata.partnerName}}</span>
								</div>
								<div *ngIf="displayAccMetadata.currencyAmount" class="col-24 px-0">
									<label i18n="@@ClientDocuments$AmountLabel">Valoare </label>
									&nbsp;
									<span>{{displayAccMetadata.currencyAmount | number}}
										{{displayAccMetadata.currency}}</span>
								</div>
							</div>
							<div *ngIf="!displayAccMetadata" class="divider black col-24 my-2"></div>
						</div>
					</div>

					<perfect-scrollbar class="d-flex">
						<div class="d-flex flex-wrap">
							<div class="cdi-details col-24">
								<div *ngIf="comments && comments.length">
									<label i18n="@@ClientDocuments$DocumentComments">Adnotări:</label>
									<div *ngFor="let comment of comments; let commentIndex = index" class="bubble {{commentIndex % 2 ? 'right' : ''}}"
										[innerHTML]="parseComment(comment)">
									</div>
								</div>
							</div>
							<div *ngIf="accMetadata && hasDetailedAccMetadata" class="col-24 d-flex flex-wrap">
								<div class="col-24 divider black mx-1 my-4"></div>
								<i class="mdi-file-cloud-download mdi-xl cursor-pointer " style="position: absolute; right: 1rem; top: 2rem; z-index: 100;"
									(click)="downloadAccMetadata()"></i>
								<ng-template ngFor let-finData [ngForOf]="accMetadata">
									<div *ngIf="finData.postings && finData.postings.length" class="col-24 px-0 d-flex flex-wrap">
										<div class="col-24 px-0"> {{finData.entryTypeName}} </div>
										<kendo-grid class="col-24 px-0 transparent" style="font-size:.8rem" [data]="finData.postings"
											scrollable="none">
											<kendo-grid-column field="debitAccount" title="Debit" headerClass="px-1" class="p-1">
											</kendo-grid-column>
											<kendo-grid-column field="creditAccount" title="Credit" headerClass="px-1" class="p-1">
											</kendo-grid-column>
											<kendo-grid-column field="amount" title="Valoare" headerClass="px-1" class="p-1 text-right">
												<ng-template kendoGridCellTemplate let-dataItem>
													<span class="">
														{{ dataItem.amount| kendoNumber:'n2'}} RON
													</span>
												</ng-template>
											</kendo-grid-column>
											<kendo-grid-column field="partnerName" title="Partener" headerClass="px-1" class="p-1">
											</kendo-grid-column>
											<kendo-grid-column field="employeeName" title="Angajat" headerClass="px-1" class="p-1">
											</kendo-grid-column>
										</kendo-grid>
									</div>
								</ng-template>
							</div>
						</div>
					</perfect-scrollbar>
				</div>
		</div>
	</div>
</div>