<div
	class="overlay {{showBookDocument || showDocumentStatusChange || showBlurDocument || showInfoDialog || showDocumentHistory || showBookDocumentWithZero || showMoveToBalanceSheetDialog ? 'show d-flex': ''}}">

	<div class="overlay-content">
		<kendo-window *ngIf="showBookDocument" (close)="closeBookDocument(false)" [minWidth]="250" [width]="450">
			<kendo-window-titlebar>
				<div class='k-window-title' i18n="@@BookDocument$EditTitle">Proprietăți document</div>
				<button kendoWindowCloseAction></button>
			</kendo-window-titlebar>
			<expert-book-document [model]="bookDocumentModel" isModal=true (close)="closeBookDocument(false)"
				(save)="closeBookDocument(true, $event)">
			</expert-book-document>
		</kendo-window>
		<kendo-window *ngIf="showBookDocumentWithZero" (close)="closeBookDocumentWithZero(false)" [minWidth]="250" [width]="450">
			<kendo-window-titlebar>
				<div class='k-window-title' i18n="@@BookDocument$EditTitle">Proprietăți document</div>
				<button kendoWindowCloseAction></button>
			</kendo-window-titlebar>
			<expert-book-document-with-zero [model]="bookDocumentModel" isModal=true (close)="closeBookDocumentWithZero(false)"
				(save)="closeBookDocumentWithZero(true, $event)">
			</expert-book-document-with-zero>
		</kendo-window>
		<kendo-window *ngIf="showDocumentStatusChange" (close)="closeDocumentStatusChange(false)" [minWidth]="250" [width]="450">
			<kendo-window-titlebar>
				<div class='k-window-title' i18n="@@DocumentStatusChange$EditTitle">{{documentStatusChangeTitle}}</div>
				<button kendoWindowCloseAction></button>
			</kendo-window-titlebar>
			<document-status-change [model]="documentStatusChangeModel" [commentIsRequired]="documentStatusChangeCommentIsRequired" isModal=true
				(close)="closeDocumentStatusChange(false)" (save)="closeDocumentStatusChange(true, $event)">
			</document-status-change>
		</kendo-window>
		<kendo-window *ngIf="showBlurDocument" (close)="closeBlurDocument(false)" [minWidth]="250" [width]="450">
			<kendo-window-titlebar>
				<div class='k-window-title' i18n="@@DocumentUnclear$EditTitle">Marchează neclar/incomplet</div>
				<button kendoWindowCloseAction></button>
			</kendo-window-titlebar>
			<blur-document [model]="blurDocumentStatusChangeModel" isModal=true (close)="closeBlurDocument(false)"
				(save)="closeBlurDocument(true, $event)">
			</blur-document>
		</kendo-window>
		<kendo-dialog title="Document Info" *ngIf="showInfoDialog" (close)="closeInfoDialog()" [minWidth]="250" [width]="450">
			<document-item-info-dialog [model]="infoDialogModel">
			</document-item-info-dialog>
		</kendo-dialog>

		<kendo-dialog title="Istoric document" *ngIf="showDocumentHistory" (close)="closeHistoryDialog()" [minWidth]="450" [width]="850">
			<div class="d-flex flex-wrap">
				<kendo-grid class="col-24 px-0 mb-3 transparent" [data]="model.history" scrollable="none">
					<kendo-grid-column field="dateTime" title="Data și ora">
						<ng-template kendoGridCellTemplate let-dataItem>
							<span>{{dataItem.dateTime | date: 'dd.MM.yyyy HH:mm'}}</span>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="userName" title="Utilizator">
					</kendo-grid-column>
					<kendo-grid-column field="statusCode" title="Stare" [style]="{'text-align': 'left'}">
						<ng-template kendoGridCellTemplate let-dataItem>
							<span>{{documentsService.documentStatusName(dataItem.statusCode)}}</span>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="comment" title="Comentariu">
					</kendo-grid-column>
				</kendo-grid>
				<div class="col-24 d-flex">
					<checkbox label="are text" class="col-auto" [(ngModel)]="model.metadataEx.hasText" [disabled]=true>
					</checkbox>
					<checkbox label="recunoscut" class="col-auto" [(ngModel)]="model.metadataEx.documentRecognized" [disabled]=true>
					</checkbox>
					<checkbox label="parsat" class="col-auto" [(ngModel)]="model.metadataEx.documentParsed" [disabled]=true>
					</checkbox>
					<checkbox label="eroare reguli" class="col-auto" [(ngModel)]="model.metadataEx.documentProcessedRulesFailed" [disabled]=true>
					</checkbox>
				</div>
			</div>
		</kendo-dialog>

		<kendo-dialog *ngIf="showMoveToBalanceSheetDialog" title="Bilanțuri" [minWidth]="450" [width]="850" (close)="closeMoveToBalanceSheetDialog()">
			<wip-balance-sheets-dialog [model]="model" (onBalanceSheetSelected)="moveToBalanceSheet($event)"></wip-balance-sheets-dialog>
		</kendo-dialog>
	</div>
</div>

<kendo-dialog *ngIf="showFileCommentForm" i18n-title="@@ExpertDocuments$AddNewComment" title="Adăugare adnotare"
	(close)="closeDocumentFileComment(false)" [minWidth]="250" [width]="450">
	<document-file-comment [model]="documentFileCommentModel" isModal=true (close)="closeDocumentFileComment(false)"
		(save)="closeDocumentFileComment(true, $event)">
	</document-file-comment>
</kendo-dialog>

<kendo-dialog title="Schimbă angajat" *ngIf="showChangeEmployeeDialog" (close)="closeChangeEmploeeDialog(false)" [minWidth]="250" [width]="450">
	<document-change-employee (close)="closeChangeEmploeeDialog(false)" (save)="closeChangeEmploeeDialog(true, $event)">
	</document-change-employee>
</kendo-dialog>

<div class="d-flex expert-document overflow-y-hidden">
	<div class="col-24 ed-card card align-self-center expert-document-item">
		<!-- Colored content by document status -->
		<div class="col-24 d-flex flex-wrap ed-content {{extendedOverlayClass()}}">
			<!-- Gallery  -->
			<div class="col-14 d-flex ed-content-gallery">
				<div class="ed-sizer">
					<div *ngIf="showDocument" class="ed-container">
						<div class="ed-multipage-container">
							<div class="ed-multipage white-text">
								<i *ngIf="model.totalPages < 10" class="multipage-icon mdi-image-filter-{{model.totalPages}}"></i>
								<i *ngIf="model.totalPages > 9" class="multipage-icon mdi-image-filter-9-plus"></i>
							</div>
						</div>
						<kee-gallery class="ed-gallery {{model.totalPages < 2 ? 'singlepage': 'multipage'}}" [gallery-options]="galleryOptions"
							[gallery-images]="galleryImages" [lazy]="true">
						</kee-gallery> 
					</div>
				</div>
			</div>

			<!-- Actions -->
			<div *ngIf="showDocument" class="col p-0 ed-content-actions">
                <div class="ed-actions">
                    <a *ngIf="allowSync()" class="ed-button black-text" i18n-title="@@ExpertDocuments$Sync" title="Sincronizează" (click)="sync()">
                        <i class="mdi-action-autorenew"></i>
                    </a>
                    <a *ngIf="allowConfirmDigitizationByExpert()" class="ed-button black-text" i18n-title="@@ExpertDocuments$ConfirmDigitization"
                        title="Confirmă digitizarea" (click)="confirmDigitizationByExpert()">
                        <i class="mdi-action-thumb-up"></i>
                    </a>
                    <a *ngIf="allowEditAccNote()" class="ed-button black-text" i18n-title="@@ExpertDocuments$EditAccNotes" title="Nota contabilă"
                        (click)="editAccNote()">
                        <i class="mdi-editor-border-color"></i>
                    </a>
                    <a *ngIf="allowMarkBookedWithZero()" class="ed-button black-text" i18n-title="@@ExpertDocuments$MarkBooked" title="Contează cu 0/Arhivare"
                        (click)="markBookedWithZero()">
                        <i class="mdi-content-archive"></i>
                    </a>
                    <a *ngIf="allowReprocessDocument()" class="ed-button black-text" i18n-title="@@ExpertDocuments$ReprocessDocument"
                        title="Reprocesare document" (click)="reprocessDocument()">
                        <i class="mdi-navigation-refresh"></i>
                    </a>
                    <a *ngIf="allowAssignToMe()" class="ed-button black-text" i18n-title="@@ExpertDocuments$AssignToMe" title="Preia documentul"
                        (click)="assignToMe()">
                        <i class="mdi-social-school"></i>
                    </a>
                    <a *ngIf="allowMarkBlurred()" class="ed-button black-text" i18n-title="@@ExpertDocuments$MarkBlurred" title="Marchează neclar"
                        (click)="markBlurred()">
                        <i class="mdi-image-center-focus-weak"></i>
                    </a>
                    <a *ngIf="allowMoveToPayroll()" class="ed-button mr-3 black-text" i18n-title="@@ExpertDocuments$MoveToPayroll" title="Document de salarizare"
                        (click)="moveToPayroll()">
                        <i class="mdi-social-people"></i>
                    </a>
                    <a *ngIf="allowMarkBooked()" class="ed-button black-text" i18n-title="@@ExpertDocuments$MarkBooked" title="Marchează contat"
                        (click)="markBooked()">
                        <i class="mdi-action-done"></i>
                    </a>
                    <a *ngIf="allowConfirmDeletion()" class="ed-button black-text" i18n-title="@@ExpertDocuments$UndoDelete"
                        title="Anulează ștergerea" (click)="rejectDeletion()">
                        <i class="mdi-action-delete" style="transform: rotate(120deg);"></i>
                    </a>
                    <a *ngIf="allowConfirmDeletion()" class="ed-button black-text" i18n-title="@@ExpertDocuments$ConfirmDelete"
                        title="Confirmă ștergerea" (click)="confirmDeletion()">
                        <i class="mdi-action-delete"></i>
                    </a>
                    <a *ngIf="allowConfirmCancellation()" class="ed-button black-text" i18n-title="@@ExpertDocuments$UndoCancel"
                        title="Revocă anularea" (click)="rejectCancellation()">
                        <i class="mdi-action-delete" style="transform: rotate(120deg);"></i>
                    </a>
                    <a *ngIf="allowConfirmCancellation()" class="ed-button black-text" i18n-title="@@ExpertDocuments$ConfirmCancel"
                        title="Confirmă anularea" (click)="confirmCancellation()">
                        <i class="mdi-action-delete"></i>
                    </a>
					<a *ngIf="model.isImport" class="ed-button black-text" i18n-title="@@ClientDocuments$GotToInvoice" title="Du-te la import"
						(click)="goToImport(model)">
						<i class="mdi-action-launch"></i>
					</a>
                    <a class="ed-button black-text" i18n-title="@@ExpertDocuments$OriginalImage" title="Vizualizare imagine" (click)="download()">
                        <i class="mdi-image-crop-original"></i>
                    </a>
                    <a href="{{ getOriginalDocumentSrc() }}" target="_blank" class="ed-button black-text" i18n-title="@@ExpertDocuments$Download" title="Descarcă">
                        <i class="mdi-file-file-download"></i>
                    </a>
                    <a *ngIf="allowAdnotate()" class="ed-button black-text" i18n-title="@@ClientDocuments$Comments" title="Adaugă adnotare"
                        (click)="addComment()">
                        <i class="mdi-communication-message"></i>
                    </a>
					<a *ngIf="allowMoveToBalanceSheet()" class="ed-button black-text" i18n-title="@@ClientDocuments$MoveToBalanceSheet" title="Copiază în bilanțuri"
                        (click)="openMoveToBalanceSheetDialog()">
                        <i class="mdi-hardware-keyboard-return mdi-rotate-180"></i>
                    </a>
                </div>
            </div>

			<!-- Document info -->
			<div *ngIf="showDocument" class="col-10 d-flex pr-0 ed-content-document">
				<div class="d-flex flex-column pl-3 col-24 px-0">
					<!-- Title & Navigation & Actions -->
					<div class="d-flex align-items-center">
                        <h2 class="col mb-0 text-capitalize" i18n="@@ClientDocuments$DocumentDetailsHeader" (click)="showInfo($event, model)">{{ model.documentDateEx | date: 'MMMM yyyy' }}</h2>
                        <div *ngIf="hasNavigation()" class="prev-button mt-1 mb-1 {{hasPreviousRecord() ? 'k-overlay-secondary cursor-pointer' : 'disabled'}}">
                            <i class="font-size-1-5 align-self-center mdi-hardware-keyboard-arrow-left white-text" (click)="getPreviousDocument()"></i>
                        </div>
                        <div *ngIf="hasNavigation()" class="next-button mt-1 mb-1 {{hasNextRecord() ? 'k-overlay-secondary cursor-pointer' : 'disabled'}}">
                            <i class="font-size-1-5 align-self-center mdi-hardware-keyboard-arrow-right white-text" (click)="getNextDocument()"></i>
                        </div>
                        <i class="col-auto mdi mdi-action-info-outline cursor-pointer mt-1 mb-1 mr-1" [style]="{'font-size': '1.15rem !important'}" title="Istoric" (click)="viewHistoryDialog()"></i>
                        <i class="col-auto mdi-content-link cursor-pointer font-size-1-5 mt-1 mb-1 mr-1" title="Copiază link document pentru Connect" (click)="copyDocumentUrl()"></i>
                        <i class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                    </div>
					<div class="row divider black mx-1 my-2"></div>

					<div class="d-flex">
						<div class="ed-details col-24">
							<div>
                                <label i18n="@@ExpertDocuments$DocumentState">Stare:</label>
                                <span> {{documentsService.documentStatusName(model.status.statusCode)}}</span>
                            </div>
							<div>
                                <label i18n="@@ExpertDocuments$FileName">Fișier:</label>
                                <span> {{model.metadataEx.fileName}}</span>
                            </div>
                            <div>
                                <label i18n="@@ExpertDocuments$DocumentScanDate">Data scanării:</label>
                                <span> {{model.created?.dateTime | date: 'dd.MM.yyyy HH:mm'}}</span>
                            </div>
                            <div>
                                <label i18n="@@ExpertDocuments$DocumentStateUser">Utilizator:</label>
                                <span> {{model.created?.userName || ''}}</span>
                            </div>
                            <div *ngIf="model.status.statusCode === DocumentFileStatus.NotUsable">
                                <label i18n="@@ExpertDocuments$DocumentStateDateLabel">Dată marcare neclar:</label>
                                <span> {{model.status.dateTime | date: 'dd.MM.yyyy'}}</span>
                            </div>
                            <button *ngIf="allowChangeEmployee" class="col-auto btn btn-standard secondary-text px-0 pb-0 mt-1" (click)="openChangeEmployeeDialog()">
                                <span i18n="@@ClientDocuments$ChangeEmployee">Schimbă angajat</span>
                            </button>
						</div>
					</div>
					
					<perfect-scrollbar class="d-flex">
						<div class="d-flex flex-wrap">
							<div class="ed-details col-24">
								<div *ngIf="model.isEFacturaSigned" class="e-factura-signed d-flex flex-wrap align-items-center">
									<div class="divider black col-24 my-2"></div>
									<i class="e-factura-signed-icon mdi-action-verified-user mr-1 green-text text-darken-2"></i> 
									<span>e-Factura validă</span>
								</div>
								<div *ngIf="comments && comments.length">
									<label i18n="@@ExpertDocuments$DocumentComments">Adnotări:</label>
									<div *ngFor="let comment of comments; let commentIndex = index" class="bubble {{commentIndex % 2 ? 'right' : ''}}"
										[innerHTML]="parseComment(comment)">
									</div>
								</div>
							</div>

							<div *ngIf="model.financialData != undefined && model.financialData.length > 0" class="col-24 d-flex flex-wrap">
								<div class="col-24 divider black mx-1 my-4"></div>
								<i class="mdi-file-cloud-download mdi-xl cursor-pointer" style="position: absolute; right: 1rem; top: 2rem; z-index: 100;"
									(click)="downloadAccMetadata()"></i>
								<ng-template ngFor let-finData let-finDataIndex="index" [ngForOf]="model.financialData">
									<div class="col-24 px-0"> {{entryTypeDisplayName(finData.entryTypeName)}} </div>
									<div class="d-flex flex-wrap col-24 px-0 pb-2 d-flex align-items-baseline">
										<div class="col-auto px-0 d-flex align-items-baseline">
											<label>Număr</label>
											&nbsp;
											<span>{{finData.documentNumber}}</span>
											&nbsp;
											<label>data</label>
											&nbsp;
											<span>{{finData.documentDateEx | date: 'dd.MM.yyyy'}}</span>
											&nbsp;
										</div>
										<div *ngIf="finDataIndex == 0 && finData.dueDateEx" class="col-auto px-0 d-flex align-items-baseline">
											<label>scadența la </label>
											&nbsp;
											<kee-datepicker name="dueDate" class="col-auto max-width-125 px-1 inline-input"
												[(ngModel)]="finData.dueDateEx">
											</kee-datepicker>
											&nbsp;
											<i class="mdi-content-save mdi-xl cursor-pointer pink-text text-accent-2"
												(click)="updateDueDate(finData.dueDateEx)"></i>
										</div>
									</div>
									<kendo-grid class="col-24 px-0 mb-3 transparent" style="font-size:.7rem" [data]="finData.postings"
										scrollable="none">
										<kendo-grid-column field="debitAccount" title="Debit" headerClass="px-1" class="p-1">
										</kendo-grid-column>
										<kendo-grid-column field="creditAccount" title="Credit" headerClass="px-1" class="p-1">
										</kendo-grid-column>
										<kendo-grid-column field="amount" title="Valoare" headerClass="px-1" class="p-1"
											[style]="{'text-align': 'right'}">
											<ng-template kendoGridCellTemplate let-dataItem>
												<span>{{(dataItem.savedAmount || dataItem.amount) | kendoNumber:'n2'}} RON</span>
											</ng-template>
										</kendo-grid-column>
										<kendo-grid-column field="vatOption" title="TVA" headerClass="px-1" class="p-1"></kendo-grid-column>
										<kendo-grid-column field="partnerName" title="Partener" headerClass="px-1" class="p-1"></kendo-grid-column>
										<kendo-grid-column field="employeeName" title="Angajat" headerClass="px-1" class="p-1"></kendo-grid-column>
										<ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showPostingComments">
											<span>{{dataItem.comment}}</span>
										</ng-template>
									</kendo-grid>
								</ng-template>
							</div>
						</div>
					</perfect-scrollbar>
				</div>
			</div>
		</div>
	</div>
</div>
