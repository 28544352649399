import { Component, ElementRef, AfterViewInit, Input, Output, Inject, forwardRef, ViewChild, OnInit, OnDestroy, Renderer2, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Observable, of, } from 'rxjs';
import { first, take, } from 'rxjs/operators';

import { $, coerceBooleanProperty, MessageBoxService, MessageBoxButton, GalleryImage } from '@saliente/library';
import { AuthService, } from '@saliente/common-auth';
import {
	FinancialDocumentFileModel, DocumentFileStatus,
	DocumentStatusChangeModel, FinancialDocumentStatusChangeToBookedModel, DocumentStatusChangeToNotUsableModel, FinancialDocumentEntryType, PersonForAccountingModel
} from '@saliente/common-documents';

import { EXPERT_ROUTES } from '../expert.routes.constants';
import { ExpertDocumentsService } from './expert-documents.service';
import { IExpertDocumentItemParent } from 'src/app/common/document-display/expert-document-item-parent';
import { CLIENTS_ROUTES } from '@saliente/clients';
import { NavigableDataSource } from 'src/app/common/general/navigable_datasource';
import { DocumentIndentificationModel } from 'src/app/common/document-display/document-display.models';
import { DocumentDisplayService } from 'src/app/common/document-display';
import { BalanceSheetInstanceListModel } from 'src/app/clients/balance-sheet/expert/balance-sheet.models';

const linkify = require('linkify-it')();

@Component({
	selector: 'expert-document-item',
	templateUrl: 'expert-document-item.component.html',
	styleUrls: ['expert-document-item.component.scss'],
})
export class ExpertDocumentItemComponent implements OnInit, OnDestroy, AfterViewInit {
	private currentPageIndex: number = 0;
	public DocumentFileStatus = DocumentFileStatus;

	public documentsService: ExpertDocumentsService;

	public galleryOptions: any = {};
	public galleryImages: GalleryImage[] = [];

	public comments: any[] = [];

	private _model: FinancialDocumentFileModel;
	@Input() get model(): FinancialDocumentFileModel {
		return this._model;
	}
	set model(value) {
		const self = this;
		self._model = value;
		self.updateComments();
	}

	private __extended__: boolean = true;
	@Input() get extended(): boolean {
		return this.__extended__;
	}
	set extended(value) {
		this.__extended__ = coerceBooleanProperty(value);
	}

	private _isDocumentDisplay: boolean = false;
	@Input() get isDocumentDisplay(): boolean {
		return this._isDocumentDisplay;
	}
	set isDocumentDisplay(value) {
		this._isDocumentDisplay = coerceBooleanProperty(value);
	}

	@Input() public documentType: string;
	@Input() public documentsNavigator: NavigableDataSource<string, FinancialDocumentFileModel>;
	@Output() public close = new EventEmitter();

	private _showDocument = false;
	get showDocument(): boolean {
		return this._showDocument;
	}
	set showDocument(value: boolean) {
		const self = this;
		self._showDocument = value;
		if (self._showDocument && self.extended) {
			if (!self._model.fullyLoaded) {
				self.documentsService
					.documentQueryStream(self._model, { spinner: false })
					.pipe(first())
					.subscribe(() => {
						self._showDocument && self.updateGalleryImages();
					});
			} else {
				setTimeout(() => {
					self._showDocument && self.updateGalleryImages();
				}, 200);
			}
		}
	}

	public get documentStatusName() {
		return this.documentsService.documentStatusName(this.model.status.statusCode, false);
	}

	public readonly isSuperExpert: boolean = false;
	public readonly hasAdnotateRight: boolean = false;
	public readonly hasDeleteConfirmationRight: boolean = false;
	public readonly hasMarkNotUsableRight: boolean = false;
	public readonly hasExpertOpsRight: boolean = false;
	public showChangeEmployeeDialog: boolean = false;
	public showMoveToBalanceSheetDialog: boolean = false;

	constructor(private authService: AuthService, private router: Router, private renderer: Renderer2, private toastr: ToastrService,
		//@Inject(forwardRef(() => ExpertDocumentsComponent)) private parentView: ExpertDocumentsComponent,
		private parentView: IExpertDocumentItemParent, private messageBoxService: MessageBoxService, private el: ElementRef,
		private documentDisplayService: DocumentDisplayService) {
			this.documentsService = this.parentView.documentsService;
			this.isSuperExpert = this.parentView.isSuperExpert;
			this.hasAdnotateRight = this.parentView.hasAdnotateRight;
			this.hasDeleteConfirmationRight = this.parentView.hasDeleteConfirmationRight;
			this.hasMarkNotUsableRight = this.parentView.hasMarkNotUsableRight;
			this.hasExpertOpsRight = this.parentView.hasExpertOpsRight;
	}

	public ngOnInit() {
		var self = this;
		let thumbnailExtSrc: any;

		if (self.extended) {
			if (this.model && this.model.metadataEx && this.model.metadataEx.fileName) {
				const ext = this.model.metadataEx.fileName.split('.').pop().toLowerCase();
				thumbnailExtSrc = self.documentsService.getDocumentThumbnailExtSrc(ext);
			}
	
			self.galleryOptions = {
				inline: true, navbar: false, loop: true,
				view: function (e: any) {
					self.setCurrentPage(e.detail.index)
				},
				thumbnailExtSrc: thumbnailExtSrc, //AM. 23.11.2022. task 5107
				thumbnailExtDefaultRatio: 0.6
			};
	
			self.updateGalleryImages();
		}
	}

	public ngOnDestroy() {}

	public ngAfterViewInit() {
		// const self = this;
		// if (self.parentView.documentId == self.model?.id) {
		// 	$('html, body').scrollTop(0);
		// 	setTimeout(() => {
		// 		$('html, body').scrollTop(self.positionTop());
		// 		self.parentView.documentId = null;
		// 		self.parentView.onScrollUp();
		// 	}, 10);
		// }
	}

	private _positionTop: number;
	public positionTop() {
		if (!this._positionTop) {
			this._positionTop = $(this.el.nativeElement).offset().top - $('.ed-bar').position()?.top - $('.ed-bar')?.height();
		}
		return this._positionTop;
	}

	private _positionBottom: number;
	public positionBottom() {
		if (!this._positionBottom) {
			const positionTop = this.positionTop();
			this._positionBottom = positionTop + $(this.el.nativeElement).parent().height();
		}
		return this._positionBottom;
	}

	public resetPosition() {
		this._positionTop = null;
		this._positionBottom = null;
	}

	public isInView() {
		const $window = $(window);
		const windowHeight = $(window).height();
		const scrollTop = $window.scrollTop();
		const positionTop = this.positionTop();
		const positionBottom = this.positionBottom();
		return (positionTop >= scrollTop && positionTop <= scrollTop + windowHeight) || (positionBottom >= scrollTop && positionBottom <= scrollTop + windowHeight);
	}

	private updateGalleryImages() {
		const self = this;
		this.galleryImages = [];

		let g: GalleryImage[] = [];
		// self.galleryImages.splice(0);
		if (self.model && self.model.pages) {
			//console.log('Pages for ' + self.model.id);
			self.model.pages.forEach((page) => {
				g.push({
					//src: self.documentsService.getDocumentSrc(self.model, page),
					dataSrc: self.documentsService.getDocumentSrc(self.model, page),
					class: "lazy"
				});
			});
			self.galleryImages = g;
		} else {
			//console.log('No pages for ' + self.model.id);
		}
	}

	private updateComments() {
		this.comments = [];
		if (this.model) {
			if (this.model.comments) {
				this.model.comments.sort((a, b) => {
					return b.moment?.dateTime.getTime() - a.moment?.dateTime.getTime();
				});
				//AM. 10.05.2024. task 7992, comment #17
				this.model.comments.forEach((comment) => {
					//if (this.comments.indexOf(comment.comment) === -1) {
						this.comments.push(comment.comment);
					//}
				})
			}
		}
	}

	public parseComment(comment: string) {
		const matches = linkify.match(comment);
		if (matches && matches.length) {
			let commentHtml = comment;
			matches.reverse().forEach((match: any) => {
				commentHtml = commentHtml.substring(0, match.index) +
					`<a href="${match.url}" target="_blank">${match.text}</a>` +
					commentHtml.substring(match.lastIndex);
			});
			return commentHtml;
		}
		return comment;
	}

	public getCurrentPage() {
		try {
			return this.model.pages[this.currentPageIndex];
		} catch {
			return null;
		}
	}

	private setCurrentPage(index: number) {
		if (index < 0) {
			index = 0;
		}
		if (index >= this.model.pages.length) {
			index = this.model.pages.length - 1;
		}
		this.currentPageIndex = index;
	}

	public extendedOverlayClass(): string {
		return this.documentsService.extendedOverlayClass(this.model?.status?.statusCode);
	}

	public getOriginalDocumentSrc() {
		return this.documentsService.getOriginalDocumentSrc(this.model, this.getCurrentPage());
	}

	public entryTypeDisplayName(entryType: FinancialDocumentEntryType) {
		return this.documentsService.entryTypeDisplayName(entryType);
	}

	public get allowChangeEmployee() {
		let hasEmployeeEid = -1;
		let isStatementOfAccount = true;

		if (this.model.financialData != null && this.model.financialData.length > 0) {
			isStatementOfAccount = this.model.financialData[0].entryTypeName == FinancialDocumentEntryType.StatementOfAccount;

			if (this.model.financialData[0].postings != undefined) {
				hasEmployeeEid = this.model.financialData[0].postings.findIndex((p) => p.employeePIN != null);
			}
		}
		
		return this.model.status.statusCode === DocumentFileStatus.Booked && !isStatementOfAccount && hasEmployeeEid != -1;
	}

	public sync() {
		try {
			this.documentsService.syncAccNote(this.model).pipe(first()).subscribe((result) => {
				if (result) {
					this.toastr.success('Nota contabilă a fost sincronizată');
				}
			});
		} catch { }
	}

	public allowSync() {
		return this.hasExpertOpsRight && this.model.status.statusCode === DocumentFileStatus.Booked;
	}

	public download() {
		try {
			this.documentsService.downloadFullDocument(this.model).subscribe();
		} catch { }
	}

	public showBookDocument: boolean = false;
	public bookDocumentModel: FinancialDocumentStatusChangeToBookedModel = null;
	public markBooked() {
		this.bookDocumentModel = new FinancialDocumentStatusChangeToBookedModel();
		this.bookDocumentModel.documentEid = this.model.id;
		this.showBookDocument = true;
		setTimeout(() => {
			$('#txtReference input').focus();
		}, 100);
	}

	public allowMarkBooked() {
		//return this.model.status.statusCode === DocumentFileStatus.Received ||
		//	this.model.status.statusCode === DocumentFileStatus.Digitized ||
		//	this.model.status.statusCode === DocumentFileStatus.Verified ||
		//	this.model.status.statusCode === DocumentFileStatus.SentToExpert;
		return this.hasExpertOpsRight && this.model.status.statusCode === DocumentFileStatus.SentToExpert;
	}

	public showBookDocumentWithZero: boolean = false;
	public markBookedWithZero() {
		this.bookDocumentModel = new FinancialDocumentStatusChangeToBookedModel();
		this.bookDocumentModel.documentEid = this.model.id;
		this.bookDocumentModel.accountingDateEx = new Date();
		this.bookDocumentModel.reference = "0";
		this.showBookDocumentWithZero = true;
		setTimeout(() => {
			$('#txtReference input').focus();
		}, 100);

		// const self = this;
		// self.messageBoxService.show({
        //     title: 'Marcare contat',
        //     message: 'Marchează contat cu referință 0. Ești sigur?',
        //     defaultButton: 'no',
        //     buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
        //     callback: function (result: string) {
		// 		if (result === 'yes') {
		// 			self.digitizedNBook(true);
		// 		}
		// 	}
        // });
	}

	public allowMarkBookedWithZero() {
		return this.hasExpertOpsRight && this.model.status.statusCode === DocumentFileStatus.SentToExpert;
	}

	// AM, 10.08.2021, 4789 (comments): add "force" parameter to bypass ciel validation; only for documents marked with zero
	private digitizedNBook(force: boolean = false) {
		this.documentsService
			.digitizedNBook(this.model, this.bookDocumentModel, force)
			.subscribe((model: FinancialDocumentFileModel) => {
				if (model) {
					this.model = model;
					this.showBookDocument = false;
					this.showBookDocumentWithZero = false;
					this.bookDocumentModel = null;
				}
			});
	}

	public closeBookDocument(save: boolean, bookDocumentForm: NgForm = null) {
		const self = this;
		if (save && bookDocumentForm != null) {
			if (bookDocumentForm.form != null && bookDocumentForm.form.valid) {
				if (self.bookDocumentModel.reference == "0") {
					this.toastr.error("Referinta nu poate fi 0. Pentru contarea cu referinta 0 foloseste Arhivare.");
					return;
				}

				// AM. 03.11.2021. task 5364. comment. datePicker validation removed
				/*if (self.bookDocumentModel.documentDateEx > new Date()) {
					self.messageBoxService.show({
						title: 'Marcare contat',
						message: 'Data aleasă este mai mare decât data curentă. Vrei să continui?',
						defaultButton: 'no',
						buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
						callback: function (result: string) {
							if (result === 'yes') {
								self.digitizedNBook();
							}
						}
					});
				} else {}*/
				
				self.digitizedNBook();
			}
		} else {
			self.showBookDocument = false;
			self.bookDocumentModel = null;
		}
	}

	public closeBookDocumentWithZero(save: boolean, bookDocumentForm: NgForm = null) {
		const self = this;
		if (save && bookDocumentForm != null) {
			if (bookDocumentForm.form != null && bookDocumentForm.form.valid) {
				if (self.bookDocumentModel.accountingDateEx > new Date()) {
					self.messageBoxService.show({
						title: 'Marcare contat',
						message: 'Data aleasă este mai mare decât data curentă. Vrei să continui?',
						defaultButton: 'no',
						buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
						callback: function (result: string) {
							if (result === 'yes') {
								self.digitizedNBook(true);
							}
						}
					});
				} else {
					self.digitizedNBook(true);
				}
			}
		} else {
			self.showBookDocumentWithZero = false;
			self.bookDocumentModel = null;
		}
	}

	public showBlurDocument: boolean = false;
	public blurDocumentStatusChangeModel: DocumentStatusChangeToNotUsableModel = null;
	public markBlurred() {
		this.blurDocumentStatusChangeModel = new DocumentStatusChangeToNotUsableModel();
		this.blurDocumentStatusChangeModel.documentEid = this.model.id;
		this.blurDocumentStatusChangeModel.reasonCode = 'alt';
		this.showBlurDocument = true;
	}

	public allowMarkBlurred() {
		return this.hasMarkNotUsableRight && this.model.status.statusCode === DocumentFileStatus.SentToExpert;
	}

	public closeBlurDocument(save: boolean, documentStatusForm: NgForm = null) {
		if (save && documentStatusForm != null) {
			if (documentStatusForm.form != null && documentStatusForm.form.valid) {
				this.documentsService
					.blur(this.model, this.blurDocumentStatusChangeModel)
					.subscribe((model: FinancialDocumentFileModel) => {
						if (model) {
							this.model = model;

							if (this.blurDocumentStatusChangeModel.alsoSendInConnect) {
								let messageContent = this.blurDocumentStatusChangeModel.comment + '\n\n' + this.getDocumentUrl();
								this.addMessageInConnect(this.model, messageContent);
							}

							this.showBlurDocument = false;
							this.blurDocumentStatusChangeModel = null;
						}
					});
			}
		} else {
			this.showBlurDocument = false;
			this.blurDocumentStatusChangeModel = null;
		}
	}

	public allowAssignToMe() {
		const statusCode = this.model.status.statusCode;
		return this.hasExpertOpsRight && (statusCode == DocumentFileStatus.Received ||
			statusCode == DocumentFileStatus.QueuedForDigitization ||
			statusCode == DocumentFileStatus.Digitized ||
			statusCode == DocumentFileStatus.QueuedForVerification ||
			statusCode == DocumentFileStatus.NotUsable ||
			statusCode == DocumentFileStatus.Booked);
	}

	public assignToMe() {
		const self = this;
		self.messageBoxService.show({
			title: 'Preluare document',
			message: 'Dorești să preiei acest document?',
			defaultButton: 'no',
			buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
			callback: function (result: string) {
				if (result === 'yes') {
					let subscription = self.documentsService.assignToMeStream(self.model)
						.subscribe((res) => {
							subscription.unsubscribe();
						});
				}
			}
		});
	}

	public allowReprocessDocument() {
		const statusCode = this.model.status.statusCode;
		return /*this.isSuperExpert &&*/ this.hasExpertOpsRight && (
			statusCode == DocumentFileStatus.Received ||
			statusCode == DocumentFileStatus.SentToExpert ||
			statusCode == DocumentFileStatus.Booked);
	}

	public reprocessDocument() {
		const self = this;
		self.messageBoxService.show({
			title: 'Reprocesare document',
			message: 'Dorești ca acest document să fie reprocesat?',
			defaultButton: 'no',
			buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
			callback: function (result: string) {
				if (result === 'yes') {
					let subscription = self.documentsService.reprocessDocumentStream(self.model)
						.subscribe((res) => {
							subscription.unsubscribe();
						});
				}
			}
		});
	}

	public rejectDeletion() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'rejectDeletion';
		this.documentStatusChangeTitle = "Anulează ștergerea documentului";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public confirmDeletion() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'confirmDeletion';
		this.documentStatusChangeTitle = "Confirmă ștergerea documentului";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = false;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public allowConfirmDeletion() {
		return this.hasDeleteConfirmationRight && this.model.status.statusCode === DocumentFileStatus.MarkedForDeletion;
	}

	public delete() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'delete';
		this.documentStatusChangeTitle = "Șterge document";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public allowDelete() {
		return this.hasDeleteConfirmationRight && this.model.status.statusCode !== DocumentFileStatus.Deleted && !this.allowConfirmDeletion() && !this.allowConfirmCancellation();
	}

	public confirmDigitizationByExpert() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'confirmDigitizationByExpert';
		this.documentStatusChangeTitle = "Confirmă digitizarea";
		// AM. 30.08.2021. task: 4836 + comment: remove confirmation popup
		// this.showDocumentStatusChange = true;
		// this.documentStatusChangeCommentIsRequired = false;
		// setTimeout(() => {
		// 	$('#txtComment textarea').focus();
		// }, 100);
		this.getStatusChangeObservable()
			.subscribe((model: FinancialDocumentFileModel) => {
				if (model) {
					this.model = model;
					this.documentStatusChangeModel = null;
					this.documentStatusChangeMethod = null;
				}
			});
		
	}

	public allowConfirmDigitizationByExpert() {
		return this.hasExpertOpsRight && (
			this.model.status.statusCode === DocumentFileStatus.QueuedForVerification ||
			this.model.status.statusCode === DocumentFileStatus.Digitized);
	}


	public rejectCancellation() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'rejectCancellation';
		this.documentStatusChangeTitle = "Revocă anularea documentului";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public confirmCancellation() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'confirmCancellation';
		this.documentStatusChangeTitle = "Confirmă anularea documentului";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = false;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public allowConfirmCancellation() {
		return this.hasDeleteConfirmationRight && this.model.status.statusCode === DocumentFileStatus.MarkedForCancellation;
	}

	public cancel() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'cancel';
		this.documentStatusChangeTitle = "Anulează document";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public allowRequeue() {
		return this.hasExpertOpsRight && this.model.status.statusCode === DocumentFileStatus.SentToExpert;
	}

	public requeue() {
		this.documentStatusChangeModel = new DocumentStatusChangeModel();
		this.documentStatusChangeModel.documentEid = this.model.id;
		this.documentStatusChangeMethod = 'requeue';
		this.documentStatusChangeTitle = "Trimite înapoi la contare";
		this.showDocumentStatusChange = true;
		this.documentStatusChangeCommentIsRequired = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}


	public showDocumentStatusChange: boolean = false;
	public documentStatusChangeModel: DocumentStatusChangeModel = null;
	private documentStatusChangeMethod: string = null;
	public documentStatusChangeTitle: string = null;
	public documentStatusChangeCommentIsRequired: boolean = true;

	private getStatusChangeObservable() {
		switch (this.documentStatusChangeMethod) {
			case 'rejectCancellation':
				return this.documentsService.rejectCancellation(this.model, this.documentStatusChangeModel);
			case 'confirmCancellation':
				return this.documentsService.confirmCancellation(this.model, this.documentStatusChangeModel);
			case 'rejectDeletion':
				return this.documentsService.rejectDeletion(this.model, this.documentStatusChangeModel);
			case 'confirmDeletion':
				return this.documentsService.confirmDeletion(this.model, this.documentStatusChangeModel);
			case 'delete':
				return this.documentsService.deleteDocumentStream(this.model, this.documentStatusChangeModel);
			case 'requeue':
				return this.documentsService.requeueDocumentStream(this.model, this.documentStatusChangeModel);
			case 'confirmDigitizationByExpert':
				return this.documentsService.confirmDigitizationByExpertStream(this.model, this.documentStatusChangeModel);
		}
		return of(null);
	}

	public closeDocumentStatusChange(save: boolean, documentStatusForm: NgForm = null) {
		if (save && documentStatusForm != null) {
			if (documentStatusForm.form != null && documentStatusForm.form.valid) {
				this.getStatusChangeObservable()
					.subscribe((model: FinancialDocumentFileModel) => {
						if (model) {
							this.model = model;
							this.showDocumentStatusChange = false;
							this.documentStatusChangeModel = null;
							this.documentStatusChangeMethod = null;
						}
					});
			}
		} else {
			this.showDocumentStatusChange = false;
			this.documentStatusChangeModel = null;
			this.documentStatusChangeMethod = null;
		}
	}

	public allowAdnotate() {
		return this.hasAdnotateRight && (this.model.status.statusCode === DocumentFileStatus.Received ||
			this.model.status.statusCode === DocumentFileStatus.Digitized ||
			this.model.status.statusCode === DocumentFileStatus.Booked ||
			this.model.status.statusCode === DocumentFileStatus.QueuedForDigitization ||
			this.model.status.statusCode === DocumentFileStatus.QueuedForVerification || 
			this.model.status.statusCode === DocumentFileStatus.SentToExpert ||
			this.model.status.statusCode === DocumentFileStatus.NotUsable);
	}

	public documentFileCommentModel: any = null;
	public showFileCommentForm: boolean = false;
	public addComment() {
		if (this.hasAdnotateRight) {
			this.documentFileCommentModel = { documentEid: this.model.id, comment: '', alsoSendInConnect: false };
			this.showFileCommentForm = true;
			setTimeout(() => {
				$('#txtComment textarea').focus();
			}, 100);
		}
	}

	public closeDocumentFileComment(save: boolean, documentFileCommentForm: NgForm = null) {
		if (save && documentFileCommentForm != null) {
			if (documentFileCommentForm.form != null && documentFileCommentForm.form.valid) {
				this.documentsService
					.addDocumentCommentStream(this.model, this.documentFileCommentModel.comment)
					.subscribe((saved) => {
						if (saved) {
							this.comments.splice(0, 0, this.documentFileCommentModel.comment);

							if (this.documentFileCommentModel.alsoSendInConnect) {
								let messageContent = this.documentFileCommentModel.comment + '\n\n' + this.getDocumentUrl();
								this.addMessageInConnect(this.model, messageContent);
							}

							this.showFileCommentForm = false;
							this.documentFileCommentModel = null;
						}
					});
			}
		} else {
			this.showFileCommentForm = false;
			this.documentFileCommentModel = null;
		}
	}
	
	public addMessageInConnect(model: FinancialDocumentFileModel, messageContent: string) {
		this.documentsService.addChatMessage(model, 'FIN', messageContent).pipe(take(1)).subscribe((result) => {
			if (result) {
				this.toastr.success('Mesajul a fost trimis în Connect.');
			}
			else {
				this.toastr.error('Mesajul nu a putut fi trimis în Connect.');
			}
		});
	}

	public showPostingComments(dataItem: any, index: number): boolean {
		return !!dataItem.comment;
	}

	public showInfoDialogRequested: boolean = false;
	public showInfoDialog: boolean = false;
	public infoDialogModel: FinancialDocumentFileModel;

	public showInfo(e: any, file: FinancialDocumentFileModel) {
		if (e.detail > 3) {
			this.infoDialogModel = file;
			this.showInfoDialog = true;
			this.showInfoDialogRequested = false;
		}
	}

	public closeInfoDialog() {
		this.showInfoDialog = false;
	}

	public allowMoveToPayroll() {
		return this.model.status.statusCode === DocumentFileStatus.SentToExpert;;
	}

	public moveToPayroll() {
		const self = this;
		self.messageBoxService.show({
			title: 'Document de salarizare',
			message: 'Ești sigur că acest document este de salarizare și nu de contabilitate?',
			defaultButton: 'no',
			buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
			callback: function (result: string) {
				if (result === 'yes') {
					const subscription = self.documentsService
						.moveToPayroll(self.model)
						.subscribe((done: any) => {
							subscription.unsubscribe();

						});
				}
			}
		});
	}

	public updateDueDate(dueDate: Date) {
		const documentsService = this.documentsService,
			model = this.model;
		documentsService.updateDueDate(model, dueDate).subscribe();
	}

	public allowEditAccNote() {
		const statusCode = this.model.status.statusCode;
		return this.hasExpertOpsRight && (statusCode == DocumentFileStatus.Received ||
			statusCode == DocumentFileStatus.QueuedForDigitization ||
			statusCode == DocumentFileStatus.Digitized ||
			statusCode == DocumentFileStatus.QueuedForVerification ||
			statusCode == DocumentFileStatus.NotUsable ||
			statusCode == DocumentFileStatus.Booked ||
			statusCode == DocumentFileStatus.SentToExpert);
	}

	public editAccNote() {
		this.router.navigate([EXPERT_ROUTES.ROOT, EXPERT_ROUTES.ACC_NOTES, 'document', this.model.id]);
	}

	public downloadAccMetadata() {
		this.documentsService.downloadAccMetadata(this.model).pipe(take(1)).subscribe();
	}

	public showDocumentHistory = false;
	public viewHistoryDialog() {
		this.showDocumentHistory = true;
	}

	public closeHistoryDialog() {
		this.showDocumentHistory = false;
	}

	public openChangeEmployeeDialog() {
		this.showChangeEmployeeDialog = true;
	}

	public closeChangeEmploeeDialog(save: boolean, formData: any = null) {
		if (save && formData != null) {
			if (formData.changeEmployeeForm.form.valid && formData.employee != undefined) {
				this.changeEmployee(formData.employee);
			}
		}

		this.showChangeEmployeeDialog = false;
	}

	public changeEmployee(employee: PersonForAccountingModel) {
		this.documentsService.setEmployee(this.model, employee).subscribe((result: boolean) => {
			if (result) {
				this.toastr.success("Angajatul a fost schimbat cu succes!");		}
		});
	}

	public goToImport(file: FinancialDocumentFileModel) {
		if (file.isImport) {
			this.router.navigate([CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.IMPORT, file.metadataEx.importEid]);
		}
	}
	
	public getDocumentUrl(): string {
		let routeMain = this.documentType == "accounting" ? EXPERT_ROUTES.ROOT : this.documentType == "payroll" ? CLIENTS_ROUTES.ROOT : EXPERT_ROUTES.ROOT;
		let routeComponent = this.documentType == "accounting" ? EXPERT_ROUTES.DOCUMENTS : this.documentType == "payroll" ? CLIENTS_ROUTES.PAYROLL_DOCUMENTS : EXPERT_ROUTES.DOCUMENTS;

		let documentUrl = `${window.location.origin}${routeMain}/${routeComponent}/${this.model.id}`;//this.router.url;
		let documentFileName = (this.model != undefined && this.model.metadataEx != undefined && this.model.metadataEx.fileName != undefined)
			? `${this.model.metadataEx.fileName}` : "Document";

		let completeText = `[${documentFileName}](${documentUrl})`;

		return completeText;
	}

	public copyDocumentUrl() {
		let completeText = this.getDocumentUrl();
		
		let textToCopy = (e : ClipboardEvent) => {
			e.clipboardData.setData('text/plain', completeText);
			e.preventDefault();
		};

		document.addEventListener('copy', textToCopy);
		document.execCommand('copy');
		document.removeEventListener('copy', textToCopy);
		this.toastr.success('Linkul documentului pentru Connect a fost copiat în clipboard.', "", { timeOut: 2000 });
	}

	public hasNextRecord() : boolean {
		if (this.documentsNavigator == null)
			return false;
		else {
			return this.documentsNavigator.hasNext(this.model.id);
		}
	}

	public hasNavigation(): boolean {
		return this.documentsNavigator != null;
	}

	public hasPreviousRecord() : boolean {
		if (this.documentsNavigator == null)
			return false;
		else {
			return this.documentsNavigator.hasPrevious(this.model.id);
		}
	}

	public async getPreviousDocument() {
		if (this.documentsNavigator.hasPrevious(this.model.id) == null) 
			return;

		let prevDocumentId = await this.documentsNavigator.getPreviousKey(this.model.id);
		if (prevDocumentId != undefined) {
			this.loadDocumentDetails(prevDocumentId);
		}
	}

	public async getNextDocument() {
		if (this.documentsNavigator.hasNext(this.model.id) == null) 
			return;

		let nextDocumentId = await this.documentsNavigator.getNextKey(this.model.id);
		if (nextDocumentId != undefined) {
			this.loadDocumentDetails(nextDocumentId);
		}
	}

	public displayDocument(document: FinancialDocumentFileModel) {
		this.documentsService
			.documentQueryStream(document, { spinner: false })
			.pipe(first())
			.subscribe(() => {
				this.model = document;
				this.updateGalleryImages();
			});
	}

	public async loadDocumentDetails(documentId: string) {
		let documentIndentification = new DocumentIndentificationModel();
		documentIndentification.externalId = documentId;
		documentIndentification.documentType = this.documentType;

		let documentDetails = await this.documentDisplayService.getDocument(documentIndentification);
		if (documentDetails) {
			this.displayDocument(documentDetails);
		}
	}

	public allowMoveToBalanceSheet() {
		return this.model.status.statusCode === DocumentFileStatus.SentToExpert;;
	}

	public openMoveToBalanceSheetDialog() {
		this.showMoveToBalanceSheetDialog = true;
	}

	public closeMoveToBalanceSheetDialog() {
		this.showMoveToBalanceSheetDialog = false;
	}

	public moveToBalanceSheet(balanceSheet: BalanceSheetInstanceListModel) {
		const subscription = this.documentsService.moveToBalanceSheet(this.model, balanceSheet)
			.subscribe((result) => {
				subscription.unsubscribe();
				if (result) {
					// book document with zero and current date'
					this.bookDocumentModel = new FinancialDocumentStatusChangeToBookedModel();
					this.bookDocumentModel.documentEid = this.model.id;
					this.bookDocumentModel.accountingDateEx = new Date();
					this.bookDocumentModel.reference = "0";
					this.digitizedNBook(true);
					this.closeMoveToBalanceSheetDialog();
				}
			})
	}
}
