import { Type, Expose, Exclude, classToClass } from 'class-transformer';
import { EventEmitter } from '@angular/core';


import { UID, processCurrencyFormula } from '@saliente/library';

import { DocumentFileBaseModel, DocumentStatusChangeModel, } from './documents-base.models';
import { FinancialDocumentEntryType, DocumentDigitizationOutcome } from './documents.enums';


export class FinancialDocumentsTypesProvider {
	static date() {
		return Date;
	}
}


export class FinancialDocumentStatusChangeToDigitizedModel extends DocumentStatusChangeModel {

	public reasonCode: string;
	public force: boolean = false;

	@Expose({ name: 'documentDate' })
	public documentDateInt: number;
	@Exclude()
	private _documentDateEx: Date;
	@Exclude()
	get documentDateEx() {
		if (!this._documentDateEx && this.documentDateInt) {
			const day = this.documentDateInt % 100;
			let monthYear = (this.documentDateInt - day) / 100;
			const month = monthYear % 100 - 1;
			const year = (monthYear - month) / 100;
			this._documentDateEx = new Date(year, month, day);
		}
		return this._documentDateEx;
	}
	set documentDateEx(value: Date) {
		if (this._documentDateEx != value) {
			this._documentDateEx = value;
			this.documentDateInt = this._documentDateEx.getFullYear() * 10000 + (this._documentDateEx.getMonth() + 1) * 100 + this._documentDateEx.getDate();
		}
	}

	constructor(documentEid?: string, outcome?: DocumentDigitizationOutcome) {
		super(documentEid);
		this.digitizationOutcome = outcome;
	}

	public digitizationOutcome: string;

	static createFrom(status: DocumentStatusChangeModel, outcome: DocumentDigitizationOutcome = DocumentDigitizationOutcome.SendToExpert) {
		const result = new FinancialDocumentStatusChangeToDigitizedModel();
		result.documentEid = status.documentEid;
		result.digitizationOutcome = outcome;
		result.comment = status.comment;
		return result;
	}
}

export class FinancialDocumentStatusChangeToBookedModel extends DocumentStatusChangeModel {
	// AM. 03.11.2021. task 5364. documentDate was replaced by accountingDate
	public reference: string;
	@Expose({ name: 'accountingDate' })
	public accountingDateInt: number;
	@Exclude()
	private _accountingDateEx: Date;
	@Exclude()
	get accountingDateEx() {
		if (!this._accountingDateEx && this.accountingDateInt) {
			const day = this.accountingDateInt % 100;
			let monthYear = (this.accountingDateInt - day) / 100;
			const month = monthYear % 100 - 1;
			const year = (monthYear - month) / 100;
			this._accountingDateEx = new Date(year, month, day);
		}
		return this._accountingDateEx;
	}
	set accountingDateEx(value: Date) {
		if (this._accountingDateEx != value) {
			this._accountingDateEx = value;
			this.accountingDateInt = this._accountingDateEx.getFullYear() * 10000 + (this._accountingDateEx.getMonth() + 1) * 100 + this._accountingDateEx.getDate();
		}
	}
}

/**************************************************************************************************************************************************
 * 
 * 
 * 
 * PostingModel
****************************************************************************************************************************************************/
export class PostingModel {

	constructor() {
	}

	@Expose({ name: 'postingEid' })
	public id: string;

	@Exclude()
	public $$journalCodeChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _journalCode: string;
	@Exclude()
	@Expose({ name: 'journalCode' })
	get journalCode(): string {
		return this._journalCode;
	}
	set journalCode(value: string) {
		if (this._journalCode !== value) {
			this._journalCode = value;
			this.$$journalCodeChanged.emit(value);
		}
	}

	@Exclude()
	public defaultJournal: string;

	@Exclude()
	public $$creditAccountChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _creditAccount: string;
	@Expose({ name: 'creditAccount' })
	get creditAccount(): string {
		return this._creditAccount;
	}
	set creditAccount(value: string) {
		if (this._creditAccount !== value) {
			this._creditAccount = value;
			this.$$creditAccountChanged.emit(value);
		}
		if (this._creditAccount === '4426.neded') {
			this.journalCode = 'OD';
		}
		else {
			if (!!this.defaultJournal) {
				this.journalCode = this.defaultJournal;
			}
		}
	}

	public creditAccountName: string;

	@Exclude()
	public $$debitAccountChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	private _debitAccount: string;
	@Expose({ name: 'debitAccount' })
	get debitAccount(): string {
		return this._debitAccount;
	}
	set debitAccount(value: string) {
		if (this._debitAccount !== value) {
			this._debitAccount = value;
			this.$$debitAccountChanged.emit(value);
		}
	}

	public debitAccountName: string;

	@Exclude()
	public $$commentChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	private _comment: string;
	@Expose({ name: 'comment' })
	get comment(): string {
		return this._comment;
	}
	set comment(value: string) {
		if (this._comment !== value) {
			this._comment = value;
			this.$$commentChanged.emit(value);
		}
	}

	public employeeEid: string;
	public employeeName: string;
	public employeePIN: string;

	public cardNumber: string;

	public partnerEid: string;
	public partnerName: string;
	public partnerCIF: string;
	public partnerCountry: string;

	@Exclude()
	public $$vatOptionChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _vatOption: string = 'Taxabile';
	@Expose({ name: 'vatOption' })
	get vatOption(): string {
		return this._vatOption;
	}
	set vatOption(value: string) {
		if (this._vatOption !== value) {
			this._vatOption = value;
			this.$$vatOptionChanged.emit(value);
		}
	}

	@Exclude()
	public $$vatQuotaChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	private _vatQuota: number = 19;
	@Expose({ name: 'vatQuota' })
	get vatQuota(): number {
		return this._vatQuota;
	}
	set vatQuota(value: number) {
		if (this._vatQuota !== value) {
			this._vatQuota = value;
			this.$$vatQuotaChanged.emit(value);
		}
	}

	@Exclude()
	public $$vatOnPaymentBooleanChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Exclude()
	@Expose({ name: 'vatOnPaymentBoolean' })
	get vatOnPaymentBoolean(): boolean {
		return this.vatOnPayment == 1;
	}
	set vatOnPaymentBoolean(value: boolean) {
		this.vatOnPayment = value ? 1 : 0;
		this.$$vatOnPaymentBooleanChanged.emit(value);
		
	}

	@Exclude()
	public $$vatOnPaymentChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	private _vatOnPayment: number;
	@Expose({ name: 'vatOnPayment' })
	get vatOnPayment(): number {
		return this._vatOnPayment;
	}
	set vatOnPayment(value: number) {
		if (this._vatOnPayment !== value) {
			this._vatOnPayment = value;
			this.$$vatOnPaymentChanged.emit(value);
		}
	}

	@Expose({ name: 'savedAmount' })
	@Exclude({ toPlainOnly: true })
	public savedAmount: number;


	@Expose({ name: 'amount' })
	get amount(): number {
		return (this.currencyAmount || 0) * (this.exchangeRate || 1);
	}
	set amount(value: number) {
	}

	@Exclude()
	public $$currencyAmountChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	private _currencyAmount: number;
	@Expose({ name: 'currencyAmount' })
	get currencyAmount(): number {
		return this._currencyAmount;
	}
	set currencyAmount(value: number) {
		if (this._currencyAmount !== value) {
			this._currencyAmount = value;
			this.$$currencyAmountChanged.emit(value);
		}
	}


	@Exclude()
	get currencyAmountEx(): string {
		if (this._currencyAmount || this._currencyAmount === 0) {
			return String(this._currencyAmount);
		}
		return null;
	}
	set currencyAmountEx(value: string) {
		setTimeout(() => {
			this.currencyAmount = processCurrencyFormula(value);
		});
	}

	@Exclude()
	public exchangeRate: number;


	@Expose({ name: 'extraInfo' })
	public extraInfoString: string;

	@Exclude()
	public extraInfoEx: any = {};

	@Exclude()
	public isEmpty: boolean = false;

	static typeProvider() {
		return PostingModel;
	}
}

/**************************************************************************************************************************************************
 * 
 * 
 * 
 * InvoiceDataModel
****************************************************************************************************************************************************/
export class InvoiceDataModel {

	@Exclude()
	public $$paymentTypeChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _paymentType: string;
	@Expose({ name: 'paymentType' })
	get paymentType(): string {
		return this._paymentType;
	}
	set paymentType(value: string) {
		if (this._paymentType !== value) {
			this._paymentType = value;
			this.$$paymentTypeChanged.emit(value);
		}
	}

	@Exclude()
	public $$vatOnCollectionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();;
	@Exclude()
	private _vatOnCollection: boolean = false;
	@Expose({ name: 'vatOnCollection' })
	get vatOnCollection(): boolean {
		return this._vatOnCollection;
	}
	set vatOnCollection(value: boolean) {
		if (this._vatOnCollection !== value) {
			this._vatOnCollection = value;
			this.$$vatOnCollectionChanged.emit(value);
		}
	}

	public simplifiedInvoice: boolean = false;
	public receiptEid: string;

	static typeProvider() {
		return InvoiceDataModel;
	}
}

/**************************************************************************************************************************************************
 * 
 * 
 * 
 * ReceiptDataModel
****************************************************************************************************************************************************/
export class ReceiptDataModel {
	@Exclude()
	public $$paymentTypeChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _paymentType: string;
	@Expose({ name: 'paymentType' })
	get paymentType(): string {
		return this._paymentType;
	}
	set paymentType(value: string) {
		if (this._paymentType !== value) {
			this._paymentType = value;
			this.$$paymentTypeChanged.emit(value);
		}
	}

	public invoiceEid: string;

	static typeProvider() {
		return ReceiptDataModel;
	}
}

/**************************************************************************************************************************************************
 * 
 * 
 * 
 * SoaDataModel
****************************************************************************************************************************************************/
export class SoaDataModel {
	public iban: string;
	public bankName: string;

	@Exclude()
	public $$accountingPlanChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	private _accountingPlan: string;
	@Exclude()
	get accountingPlan(): string {
		return this._accountingPlan;
	}
	set accountingPlan(value: string) {
		if (this._accountingPlan !== value) {
			this._accountingPlan = value;
			this.$$accountingPlanChanged.emit(value);
		}
	}

	static typeProvider() {
		return SoaDataModel;
	}
}

/**************************************************************************************************************************************************
 * 
 * 
 * 
 * FinancialDocumentDataModel
****************************************************************************************************************************************************/
export class FinancialDocumentDataModel {

	constructor() {
		this.id = UID();
		this.postings = [];
	}

	@Expose({ name: 'externalId' })
	public id: string;

	@Exclude()
	public $$entryTypeNameChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	private _entryTypeName: string;
	@Expose({ name: 'entryTypeName' })
	get entryTypeName(): string {
		return this._entryTypeName;
	}
	set entryTypeName(value: string) {
		if (this._entryTypeName !== value) {
			this._entryTypeName = value;
			this.$$entryTypeNameChanged.emit(value);
		}
	}

	public financialDocumentType: string;
	public reference: string;
	public isInputDocument: boolean;

	@Exclude()
	public $$documentNumberChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	private _documentNumber: string;
	@Expose({ name: 'documentNumber' })
	get documentNumber(): string {
		return this._documentNumber;
	}
	set documentNumber(value: string) {
		if (this._documentNumber !== value) {
			this._documentNumber = value;
			this.$$documentNumberChanged.emit(value);
		}
	}


	@Exclude()
	public $$documentDateExChanged: EventEmitter<Date> = new EventEmitter<Date>();
	@Expose({ name: 'documentDate' })
	public documentDateInt: number;
	@Exclude()
	private _documentDateEx: Date;
	@Exclude()
	get documentDateEx(): Date {
		if (!this._documentDateEx && this.documentDateInt) {
			const day = this.documentDateInt % 100;
			let monthYear = (this.documentDateInt - day) / 100;
			const month = monthYear % 100 - 1;
			const year = (monthYear - month) / 100;
			this._documentDateEx = new Date(year, month, day);
		}
		return this._documentDateEx;
	}
	set documentDateEx(value: Date) {
		if (this._documentDateEx != value) {
			this._documentDateEx = value;
			if (this._documentDateEx) {
				this.documentDateInt = this._documentDateEx.getFullYear() * 10000 + (this._documentDateEx.getMonth() + 1) * 100 + this._documentDateEx.getDate();
				this.accountingPeriod = this._documentDateEx.getFullYear() * 100 + (this._documentDateEx.getMonth() + 1);
			} else {
				this.documentDateInt = null;
				this.accountingPeriod = null;
			}
			this.$$documentDateExChanged.emit(value);
		}
	}

	@Exclude()
	public $$dueDateExChanged: EventEmitter<Date> = new EventEmitter<Date>();
	@Expose({ name: 'dueDate' })
	public dueDateInt: number;
	@Exclude()
	private _dueDateEx: Date;
	@Exclude()
	get dueDateEx(): Date {
		if (!this._dueDateEx && this.dueDateInt) {
			const day = this.dueDateInt % 100;
			let monthYear = (this.dueDateInt - day) / 100;
			const month = monthYear % 100 - 1;
			const year = (monthYear - month) / 100;
			this._dueDateEx = new Date(year, month, day);
		}
		return this._dueDateEx;
	}
	set dueDateEx(value: Date) {
		if (value != null && this._dueDateEx != value) {
			this._dueDateEx = value;
			if (this._dueDateEx) {
				this.dueDateInt = this._dueDateEx.getFullYear() * 10000 + (this._dueDateEx.getMonth() + 1) * 100 + this._dueDateEx.getDate();
			} else {
				this.dueDateInt = null;
			}
			this.$$dueDateExChanged.emit(value);
		}
	}

	public accountingPeriod: number;
	public comment: string;

	@Expose({ name: 'extraInfo' })
	public extraInfoString: string;

	@Exclude()
	public extraInfoEx: any = {};

	@Exclude()
	public $$currencyChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	private _currency: string;
	@Expose({ name: 'currency' })
	get currency(): string {
		return this._currency;
	}
	set currency(value: string) {
		if (this._currency !== value) {
			this._currency = value;
			this.$$currencyChanged.emit(value);
		}
	}

	@Exclude()
	public $$exchangeRateChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	private _exchangeRate: number = 1;
	@Expose({ name: 'exchangeRate' })
	get exchangeRate(): number {
		return this._exchangeRate;
	}
	set exchangeRate(value: number) {
		if (this._exchangeRate !== value) {
			this._exchangeRate = value;
			this.$$exchangeRateChanged.emit(value);
		}
	}

	@Exclude()
	public totalAmount: number;
	@Exclude()
	public currencyTotalAmount: number;

	@Exclude()
	public $$currencyBaseAmountChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	get currencyBaseAmount(): number {
		this.extraInfoEx.currencyBaseAmount = this.extraInfoEx.currencyBaseAmount || 0;
		return this.extraInfoEx.currencyBaseAmount;
	}
	set currencyBaseAmount(value: number) {
		if (this.extraInfoEx.currencyBaseAmount !== value) {
			this.extraInfoEx.currencyBaseAmount = value;
			this.$$currencyBaseAmountChanged.emit(value);
		}
	}

	@Exclude()
	get currencyBaseAmountEx(): string {
		if (this.currencyBaseAmount) {
			return String(this.currencyBaseAmount);
		}
		return null;
	}
	set currencyBaseAmountEx(value: string) {
		setTimeout(() => {
			this.currencyBaseAmount = processCurrencyFormula(value);
		});
	}

	@Exclude()
	public $$currencyTotalVatAmountChanged: EventEmitter<number> = new EventEmitter<number>();;
	@Exclude()
	get currencyTotalVatAmount(): number {
		this.extraInfoEx.currencyTotalVatAmount = this.extraInfoEx.currencyTotalVatAmount || 0;
		return this.extraInfoEx.currencyTotalVatAmount;
	}
	set currencyTotalVatAmount(value: number) {
		if (this.extraInfoEx.currencyTotalVatAmount !== value) {
			this.extraInfoEx.currencyTotalVatAmount = value;
			this.$$currencyTotalVatAmountChanged.emit(value);
		}
	}

	@Exclude()
	get currencyTotalVatAmountEx(): string {
		if (this.currencyTotalVatAmount) {
			return String(this.currencyTotalVatAmount);
		}
		return null;
	}
	set currencyTotalVatAmountEx(value: string) {
		setTimeout(() => {
			this.currencyTotalVatAmount = processCurrencyFormula(value);
		});
	}

	@Exclude()
	public $$vehicleDeductibilityChanged: EventEmitter<number> = new EventEmitter<number>();
	@Exclude()
	get vehicleDeductibility(): number {
		if (!this.extraInfoEx.vehicleDeductibility && this.extraInfoEx.vehicleDeductibility != 0) {
			this.extraInfoEx.vehicleDeductibility = 50;
		}
		return this.extraInfoEx.vehicleDeductibility;
	}
	set vehicleDeductibility(value: number) {
		if (this.extraInfoEx.vehicleDeductibility !== value) {
			this.extraInfoEx.vehicleDeductibility = value;
			this.$$vehicleDeductibilityChanged.emit(value);
		}
	}

	get dueDateSynced(): boolean {
		return !!this.extraInfoEx.dueDateSynced;
	}

	set dueDateSynced(value: boolean) {
		this.extraInfoEx.dueDateSynced = !!value;
	}



	@Exclude()
	public $$employeeEidChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	get employeeEid(): string {
		return this.extraInfoEx.employeeEid;
	}
	set employeeEid(value: string) {
		if (this.extraInfoEx.employeeEid !== value) {
			this.extraInfoEx.employeeEid = value;
			this.$$employeeEidChanged.emit(value);
		}
	}
	@Exclude()
	get employeeName(): string {
		return this.extraInfoEx.employeeName;
	}
	set employeeName(value: string) {
		this.extraInfoEx.employeeName = value;
	}
	@Exclude()
	get employeePIN(): string {
		return this.extraInfoEx.employeePIN;
	}
	set employeePIN(value: string) {
		this.extraInfoEx.employeePIN = value;
	}

	@Exclude()
	public $$cardNumberChanged: EventEmitter<string> = new EventEmitter<string>();
	@Exclude()
	get cardNumber(): string {
		return this.extraInfoEx.cardNumber;
	}
	set cardNumber(value: string) {
		if (this.extraInfoEx.cardNumber !== value) {
			this.extraInfoEx.cardNumber = value;
			
			if (this.extraInfoEx.cardNumber == '') {
				this.extraInfoEx.cardNumber = undefined;
			}

			this.$$cardNumberChanged.emit(value);
		}
	}

	@Exclude()
	public $$partnerEidChanged: EventEmitter<string> = new EventEmitter<string>();;
	@Exclude()
	get partnerEid(): string {
		return this.extraInfoEx.partnerEid;
	}
	set partnerEid(value: string) {
		if (this.extraInfoEx.partnerEid !== value) {
			this.extraInfoEx.partnerEid = value;
			this.$$partnerEidChanged.emit(value);
		}
	}
	@Exclude()
	get partnerName(): string {
		return this.extraInfoEx.partnerName;
	}
	set partnerName(value: string) {
		this.extraInfoEx.partnerName = value;
	}
	@Exclude()
	get partnerCIF(): string {
		return this.extraInfoEx.partnerCIF;
	}
	set partnerCIF(value: string) {
		this.extraInfoEx.partnerCIF = value;
	}
	@Exclude()
	get partnerCountry(): string {
		return this.extraInfoEx.partnerCountry;
	}
	set partnerCountry(value: string) {
		this.extraInfoEx.partnerCountry = value;
	}

	@Type(InvoiceDataModel.typeProvider)
	public invoiceData: InvoiceDataModel;

	@Type(ReceiptDataModel.typeProvider)
	public receiptData: ReceiptDataModel;

	@Type(SoaDataModel.typeProvider)
	public soaData: SoaDataModel;

	@Exclude()
	get particularData(): InvoiceDataModel | ReceiptDataModel | SoaDataModel {
		return this.invoiceData || this.receiptData || this.soaData;
	}

	@Exclude()
	get paymentType(): string {
		return this.invoiceData?.paymentType || this.receiptData?.paymentType;
	}

	set paymentType(val: string) {
		if (this.invoiceData)
			this.invoiceData.paymentType = val;
		else if (this.receiptData)
			this.receiptData.paymentType = val;
	}

	@Exclude()
	get iban() : string {
		return this.soaData?.iban;
	}

	set iban(val: string) {
		if (this.soaData) {
			this.soaData.iban = val;
		}
	}

	@Exclude()
	get vatOnCollection(): boolean {
		return this.invoiceData?.vatOnCollection;
	}

	set vatOnCollection(val: boolean) {
		if (this.invoiceData) {
			this.invoiceData.vatOnCollection = val;
		}
	}

	@Exclude()
	public $$postingsRefresh: EventEmitter<any> = new EventEmitter<any>();
	@Type(PostingModel.typeProvider)
	public postings: PostingModel[];

	public prepareForSave() {
		var self = this;
		self.id = self.id || UID();
		if (self.postings) {
			for (var len = this.postings.length, idx = len - 1; idx >= 0; idx--) {
				var posting = this.postings[idx];
				if (posting.isEmpty || !posting.currencyAmount) {
					this.postings.splice(idx, 1);
				} else {
					posting.id = posting.id || UID();

					posting.partnerEid = self.partnerEid;
					posting.partnerName = self.partnerName;
					posting.partnerCIF = self.partnerCIF;
					posting.partnerCountry = self.partnerCountry;

					posting.cardNumber = self.cardNumber;
					posting.employeeEid = self.employeeEid;
					posting.employeeName = self.employeeName;
					posting.employeePIN = self.employeePIN;

					if (posting.extraInfoEx) {
						posting.extraInfoString = JSON.stringify(posting.extraInfoEx);
					}
				}
			}
		}
		if (self.extraInfoEx) {
			self.extraInfoString = JSON.stringify(self.extraInfoEx);
		}
	}

	static typeProvider() {
		return FinancialDocumentDataModel;
	}
}

export class FinancialDocumentDataReferenceModel {
	public role: string;
	public sourceEid: string;
	public targetEid: string;

	static typeProvider() {
		return FinancialDocumentDataReferenceModel;
	}
}

export class FinancialDocumentFileCommentMomentModel {

	@Type(FinancialDocumentsTypesProvider.date)
	public dateTime: Date;

	public userName: string;

	static typeProvider() {
		return FinancialDocumentFileCommentMomentModel;
	}
}

export class FinancialDocumentFileCommentModel {
	public comment: string;

	@Type(FinancialDocumentFileCommentMomentModel.typeProvider)
	public moment: FinancialDocumentFileCommentMomentModel;

	static typeProvider() {
		return FinancialDocumentFileCommentModel;
	}
}

export class FinancialDocumentFileMetadata {
	public fileName: string;
	public documentType?: string;
	public documentPersonName?: string;
	public documentNo?: string;

	public sourceModule?: string;

	@Type(FinancialDocumentsTypesProvider.date)
	public documentDate?: Date;

	@Type(FinancialDocumentsTypesProvider.date)
	public documentDueDate?: Date;

	static typeProvider() {
		return FinancialDocumentFileMetadata;
	}
}

export class FinancialDocumentFileModel extends DocumentFileBaseModel {
	@Expose({ name: 'documentEid' })
	public id: string;

	@Expose({ name: 'documentDateOutcome' })
	public documentDateOutcomeInt: number;
	@Exclude()
	protected _documentDateOutcomeEx: Date;
	@Exclude()
	get documentDateOutcomeEx() {
		if (!this._documentDateOutcomeEx && this.documentDateOutcomeInt) {
			const day = this.documentDateOutcomeInt % 100;
			let monthYear = (this.documentDateOutcomeInt - day) / 100;
			const month = monthYear % 100 - 1;
			const year = (monthYear - month) / 100;
			this._documentDateOutcomeEx = new Date(year, month, day);
		}
		return this._documentDateOutcomeEx;
	}


	@Exclude()
	public fullyLoaded: boolean = false;

	@Exclude()
	get readOnly(): boolean {
		return !this.status.statusIsReceived();
	}

	@Exclude()
	get isImport(): boolean {
		return this.metadataEx && this.metadataEx.sourceModule == 'ImportsModule';
	}

	@Exclude()
	get isEFacturaSigned(): boolean {
		return this.metadataEx && this.metadataEx.eFacturaSigned == "true";
	}

	@Exclude() 
	get isEFacturaUnsigned(): boolean {
		return this.metadataEx && this.metadataEx.eFacturaUnsigned;
	}

	@Exclude() 
	get isEFacturaModule(): boolean {
		return this.metadataEx && this.metadataEx.source == 'EFacturaModule'
	}

	public clientEid: string;
	public clientName: string;

	public metadata: string;
	@Exclude()
	private _metatdataEx: any;
	@Exclude()
	public get metatdataEx() {
		if (!this._metatdataEx) {
			try {
				this._metatdataEx = JSON.parse(this.metadata);
			}
			catch { 
				this._metatdataEx = {};
			}
		}
		return this.metadataEx;
	}

	@Exclude()
	get vehicleDeductibility(): number {
		if (this.financialData && this.financialData.length) {
			if (this.financialData[0].entryTypeName === FinancialDocumentEntryType.GasReceipt ||
				this.financialData[0].entryTypeName === FinancialDocumentEntryType.AutoReceipt) {
				return this.financialData[0].vehicleDeductibility;
			}
		}
		return null;
	}

	@Type(FinancialDocumentFileCommentModel.typeProvider)
	public comments: FinancialDocumentFileCommentModel[];

	@Type(FinancialDocumentDataModel.typeProvider)
	public financialData: FinancialDocumentDataModel[];

	@Type(FinancialDocumentDataReferenceModel.typeProvider)
	public references: FinancialDocumentDataReferenceModel[];

	@Exclude()
	public history: any[] = [];

	public prepareForSave() {
	}

	public transformComplete() {
	}

	public clone(): FinancialDocumentFileModel {
		return classToClass(this, { ignoreDecorators: true, excludePrefixes: ['$$'] });
	}

}