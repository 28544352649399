<!-- accounting documents -->
<client-document-host *ngIf="showDocument && isClient && !isPayrollDoc" [model]="documentFile" [documentType]="documentIndentification.documentType" [documentsNavigator]="documentsNavigator"
    (close)="this.close.emit()">
</client-document-host>
<!-- payroll documents (for client & expert)-->
<client-payroll-document-host *ngIf="showDocument && isPayrollDoc" [model]="documentFile" [documentType]="documentIndentification.documentType" [documentsNavigator]="documentsNavigator"
    (close)="this.close.emit()">
</client-payroll-document-host>
<!-- if expert and payroll doc -->
<!-- <client-document-host *ngIf="showDocument && isExpert && isPayrollDoc" [model]="documentFile" [documentType]="documentIndentification.documentType" [documentsNavigator]="documentsNavigator"
    (close)="this.close.emit()">
</client-document-host> -->
<!-- accounting expert documents -->
<expert-document-host *ngIf="showDocument && isExpert && !isPayrollDoc" [model]="documentFile" [documentType]="documentIndentification.documentType" [documentsNavigator]="expertDocumentsNavigator"
    (close)="this.close.emit()">
</expert-document-host>

<div *ngIf="showDocumentTextNotExist" class="col-24 d-flex px-3 py-3 align-items-center">
    <h5 class="col mb-0">Nu există document asociat.</h5>
    <i class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mb-2" title="Închide" (click)="this.close.emit()"></i>
</div>