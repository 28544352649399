import { OnInit, OnDestroy, Directive } from '@angular/core';

import { $ } from '@saliente/library';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { PredefinedUserZone } from '../auth/auth.models';

//const pageTitle = 'keez.ro';

@Directive()
export class PageContentComponent implements OnInit, OnDestroy {
	private mainPageTitle = '';
	public rootCssClass: string = '';
	public pageTitle: string = '';

	private currentCompEid = '';
	private compChangeSubscription: Subscription;
	private authServ: AuthService;

	constructor(authService: AuthService) { 
		this.authServ = authService;
		if (this.authServ.selectedZoneCode == PredefinedUserZone.Subcontractor || this.authServ.selectedZoneCode == PredefinedUserZone.None) {
			this.mainPageTitle = "keez.ro";
			this.changeTitle(this.pageTitle);
		}
	}

	ngOnInit() {
		this.compChangeSubscription = this.authServ.getSelectedCompanyId().subscribe((companyEid) => {
			if (this.authServ.selectedZoneCode == PredefinedUserZone.Subcontractor || this.authServ.selectedZoneCode == PredefinedUserZone.None) {
				this.mainPageTitle = "keez.ro";
			}
			else {
				if (companyEid !== this.currentCompEid) {
					this.currentCompEid = companyEid;
					
					this.mainPageTitle = this.authServ.user.selectedCompany.companyName;
					this.changeTitle(this.pageTitle);
				}
			}
		});
		
		this.changeTitle(this.pageTitle);
		if (this.rootCssClass) {
			$('html').addClass(this.rootCssClass);
		}
	}

	ngOnDestroy() {
		document.title = this.mainPageTitle;
		this.pageTitle = '';
		if (this.rootCssClass) {
			$('html').removeClass(this.rootCssClass);
		}
	}

	public changeTitle(title: string) {
		this.pageTitle = title;
		if (this.pageTitle) {
			// AM.18.06.2024, task 9255
			document.title = (this.mainPageTitle.length > 10 ? `${this.mainPageTitle.substring(0, 10)}...` : this.mainPageTitle) + ' - ' + this.pageTitle;
		} else {
			document.title = this.mainPageTitle;
		}
	}
}
