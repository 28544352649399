import { NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { ToastrModule } from 'ngx-toastr';


import { AppRouterModule } from './app.routes'
import { SalienteLibraryModule } from '@saliente/library';
import { MasterViewModule } from '@saliente/common-masterview';
import { AuthModule } from '@saliente/auth';
import { CommonModule } from '@saliente/common';
import { ClientsModule } from '@saliente/clients';
import { SubcontractorModule } from '@saliente/subcontractor';
import { ExpertModule } from '@saliente/expert';
import { HrExpertModule } from '@saliente/hrexpert';
import { PayrollModule } from '@saliente/payroll';

import { AppHomeComponent } from './common/app-home/app-home.component';
import { AppComponent } from './app.component';
import { StockModule } from 'src/app/stocks';
import { RevisalModule } from './revisal';
import { ClientFormsModule } from './client-forms';
//import { MobxAngularModule } from 'mobx-angular';
import * as UIToolkit from '@zoom/videosdk-ui-toolkit';

import "reflect-metadata";
import { EshopModule } from './eshop';

@NgModule({
	imports: [
		AngularCommonModule, BrowserModule, BrowserAnimationsModule, FormsModule, HttpClientModule, RouterModule,
		ToastrModule.forRoot({ maxOpened: 1, positionClass: 'toast-top-center', preventDuplicates: true }),
//		MobxAngularModule,

		SalienteLibraryModule, MasterViewModule, CommonModule, AuthModule, ClientsModule,
		SubcontractorModule, ExpertModule, HrExpertModule, PayrollModule, AppRouterModule, StockModule, RevisalModule, ClientFormsModule,
		EshopModule
		// UIToolkit
	],
	declarations: [AppComponent, AppHomeComponent, ],
	bootstrap: [AppComponent]
})
export class AppModule {
}
