import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, SecurityContext, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { $, WindowRef } from '@saliente/library';

import { ToastrService } from 'ngx-toastr';
import { ClientFinancialReportsService } from 'src/app/clients/reports/financial/financial-reports.service';
import { AppMenuModel } from '../appmenu/appmenu.models';
import { AppMenuService } from '../appmenu/appmenu.service';
import { PredefinedUserZone } from '../auth/auth.models';
import { AuthService } from '../auth/auth.service';
import { COMMON_ROUTES } from '../common.routes.constants';
import { CommonService } from '../general/common.service';
import { ConfigService } from '../general/config.service';
import { DocumentStatisticsCommon } from '../general/documentStatistics.service';
import { ImportServiceCommon } from '../general/import.service';
import { PayrollServiceCommon } from '../general/payroll.service';
import { ChatService } from '../notifications/chat.service';
import { RatingService } from '../rating/rating.service';
import {
	AnafMessagesService,
	Subs_GetAnafMessageNotFinalizedCountUsingGetQuery,
	Subs_GetAnafMessageNotFinalizedCountUsingGetQueryVariables,
} from '../services/anaf-messages.service';
import {
	BalanceSheetService,
	Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQuery,
	Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQueryVariables,
	Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQuery,
	Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQueryVariables,
} from '../services/balance-sheet.service';
import { EFacturaServiceCommon } from '../general/e-factura.service';

function coerceBooleanProperty(value: any): boolean {
	return value != null && `${value}` !== 'false';
}

@Component({
	selector: 'masterview',
	templateUrl: 'masterview.component.html',
	styleUrls: ['masterview.component.scss', 'masterview.sidebar.component.scss'],
})
export class MasterviewComponent implements OnInit, OnDestroy, AfterViewInit {
	public sideBarVisible = false;
	public notifications: any[] = [];

	public PredefinedUserZone = PredefinedUserZone;
	public helpConfig: any = {};
	public helpId: string;
	public helpVideos: any[] = [];

	private helpSubscription1: Subscription;
	private helpSubscription2: Subscription;

	@ViewChildren('parentMenu') parentMenu: QueryList<ElementRef>;

	@Input() public logoSrc: string;
	@Input() public logoAlt: string;

	private _showLanguages = false;
	@Input()
	get showLanguages(): boolean {
		return this._showLanguages;
	}

	set showLanguages(value) {
		this._showLanguages = coerceBooleanProperty(value);
	}

	private __showSideBar__ = true;
	get showSideBar(): boolean {
		return this.__showSideBar__ && this.authService.isEnrolled;
	}
	set showSideBar(value: boolean) {
		this.__showSideBar__ = value;
	}

	private __showHeaderBar__ = true;
	get showHeaderBar(): boolean {
		return this.__showHeaderBar__ && this.authService.isEnrolled;
	}
	set showHeaderBar(value: boolean) {
		this.__showHeaderBar__ = value;
	}

	get unseenMessages(): number {
		if (this.authService.selectedZoneCode === PredefinedUserZone.Administrator) {
			return this.chatService.unseenMessages;
		} else {
			return this.chatService.unansweredMessages;
		}
	}

	get waitingImportList(): number {
		return this.importServiceCommon.waitingImportsCount;
	}

	get missingDocumentsCount(): number {
		return this.financialReports.missingDocumentsCount;
	}

	get clarificationCount(): number {
		return this.financialReports.clarificationCount;
	}

	get cardClarificationCount(): number {
		return this.financialReports.cardClarificationCount;
	}

	get reconciliationCount(): number {
		return this.financialReports.reconciliationCount;
	}

	get documentsCount(): number {
		if (this.isClient) {
			return this.documentStatisticsCommon.notUsableDocumentsCount;
		} else if (this.isAccExpert) {
			return this.documentStatisticsCommon.sentToExpertDocumentsCount;
		}
	}

	get accountingTotalCount(): number {
		var totalCount = 0;
		this.appMenuService.getMenus().forEach((menu) => {
			if (menu.text == 'Contabilitate') {
				menu.childrenMenu.forEach((childMenu: any) => {
					totalCount =
						totalCount +
						(childMenu.isFinancialDocuments ? this.documentsCount : 0) +
						(childMenu.isMissingDocsMenu ? this.missingDocumentsCount : 0) +
						(childMenu.isClarificationMenu ? this.clarificationCount : 0) +
						(childMenu.isReconciliationMenu ? this.reconciliationCount : 0) +
						(childMenu.isBalanceSheet ? (this.isAccExpert ? this.balanceSheetExpertWorkInProgressCount : this.balanceSheetClientWorkInProgressCount) : 0) +
						(childMenu.isAnafComm ? this.anafMessageNotFinalizedCount : 0);
				});
			}
		});
		return totalCount;
	}

	get balanceSheetWorkInProgressCount(): number {
		if (this.isClient) {
			return this.balanceSheetClientWorkInProgressCount;
		} else if (this.isAccExpert) {
			return this.balanceSheetExpertWorkInProgressCount;
		}

		return 0;
	}

	get eFacturaCount(): number {
		return this.eFacturaServiceCommon.inboxNotVerifiedCount + this.eFacturaServiceCommon.outboxWithErrorCount;
	}

	get showHelpVideos(): boolean {
		return !!(this.authService.user && this.authService.user.showHelp && this.helpVideos && this.helpVideos.length);
	}

	get minimumWageEmployeesCount(): number {
		return this.payrollServiceCommon.waitingMinimumWageEmployeeCount;
	}

	get payrollTotalCount(): number {
		var totalCount = 0;
		this.appMenuService.getMenus().forEach((menu) => {
			if (menu.text == 'Salarizare') {
				menu.childrenMenu.forEach((childMenu: any) => {
					totalCount = totalCount + (childMenu.isSalariatiMenu ? this.minimumWageEmployeesCount : 0);
				});
			}
		});
		return totalCount;
	}

	get invoiceTotalCount(): number {
		return this.eFacturaCount;
	}

	public currentHelpVideo: any;
	public showModalBox = false;
	public showHelpConfirmationDialog = false;
	public showRatingDialog = false;
	public showRatingDialogCloseButton = false;
	public showFeedback = false;
	public ratingSource: string;
	currentCompanyEid = '';
	private companyChangeSubscription: Subscription;
	private anafCommSomethingChanged: Subscription;
	anafMessageNotFinalizedCount = 0;
	private balanceSheetSomethingChanged: Subscription;
	balanceSheetExpertWorkInProgressCount = 0;
	private balanceSheetClientSomethingChanged: Subscription;
	balanceSheetClientWorkInProgressCount = 0;

	public isClient: boolean;
	public isAccExpert: boolean;
	public isHRExpert: boolean;
	public isSubcontractor: boolean;
	public hasPayrollAccess: boolean;

	public wootricIsForTest: boolean = false;

	constructor(
		protected winRef: WindowRef,
		public authService: AuthService,
		private configService: ConfigService,
		public commonService: CommonService,
		public appMenuService: AppMenuService,
		public router: Router,
		private chatService: ChatService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private importServiceCommon: ImportServiceCommon,
		private rating: RatingService,
		private toastr: ToastrService,
		private balanceSheetService: BalanceSheetService,
		private anafMessagesService: AnafMessagesService,
		private financialReports: ClientFinancialReportsService,
		private documentStatisticsCommon: DocumentStatisticsCommon,
		private elementRef: ElementRef,
		private payrollServiceCommon: PayrollServiceCommon,
		private eFacturaServiceCommon: EFacturaServiceCommon
	) {
		/*this.notificationsService.unread().subscribe((notifications: any) => {
			this.notifications = notifications;
		});*/
	}

	public ngOnInit(): void {
		this.companyChangeSubscription = this.authService.getSelectedCompanyId().subscribe((companyEid) => {
			this.initializeZoneBooleans();

			if (companyEid !== this.currentCompanyEid) {
				this.currentCompanyEid = companyEid;
			}

			this.financialReports.computeDocsCount();
			this.documentStatisticsCommon.computeDocsStatisticsCount();
			this.eFacturaServiceCommon.getEFacturaStatistics();

			if (this.hasPayrollAccess) {
				this.payrollServiceCommon.startMinimumWageEmployeeCount();
			}

			if (companyEid != null) {
				this.commonService
					.getInventoryEnabled()
					.pipe(take(1))
					.subscribe((isInventoryEnabled) => {
						if (this.authService.isInRole('INVENTORY_EVENT_VIEW')) {
							this.appMenuService.setMenuAndChildrenDisabled('Stocuri', isInventoryEnabled);
						}
					});

				this.commonService
					.getIncorporationType()
					.pipe(take(1))
					.subscribe((code) => {
						if (code == 'PFA' || code == 'PFI' || code == 'IF' || code == 'II') {
							this.appMenuService.setMenuVisibility('Declarații unice', true);
							this.appMenuService.setMenuVisibility('Bilanțuri', false);
						} else {
							this.appMenuService.setMenuVisibility('Declarații unice', false);
							this.appMenuService.setMenuVisibility('Bilanțuri', true);
						}
					});

				this.configService.accountingPartner =
					this.authService.user.selectedCompany.accountingPartnerCode !== 'keez' ? this.authService.user.selectedCompany.accountingPartnerCode : null;
				// some menus should be not be visible to accounting partners other than Keez
				this.setMenuVisibilityForAccountingPartner();

				this.commonService
					.getIsClientInCancellationOrDissolutionProcess()
					.pipe(take(1))
					.subscribe((isCancellationOrDissolution) => {
						if (!isCancellationOrDissolution) {
							this.openWootric();
						}
					});

				this.startImportCount();
			}

			if (this.isClient && this.isAccountSuspended()) {
				this.goToContractPage();
			}

			/*- not all users have BALANCE_SHEET_EDIT roles, especially during the period the Client is suspended*/
			if (this.isClient && this.authService.isInRole('BALANCE_SHEET_EDIT')) {
				const vbc: Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQueryVariables = {
					clientEid: companyEid,
				};
				const bc = this.balanceSheetService.getBalanceSheetClientWorkInProgressCountForClient(vbc);

				bc.subscribe((rez) => {
					this.balanceSheetClientWorkInProgressCount = rez.subs.getBalanceSheetClientWorkInProgressCountForClientUsingGET.count;
				});
			}

			if (!this.authService.isInRole('EXPERT')) {
				return;
			}

			const vb: Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQueryVariables = {
				clientEid: companyEid,
			};
			const b = this.balanceSheetService.getBalanceSheetExpertWorkInProgressCountForClient(vb);

			const va: Subs_GetAnafMessageNotFinalizedCountUsingGetQueryVariables = {
				clientEid: companyEid,
			};
			const a = this.anafMessagesService.getAnafMessageNotFinalizedCount(va);

			forkJoin({ b, a }).subscribe((rez) => {
				this.balanceSheetExpertWorkInProgressCount = rez.b.subs.getBalanceSheetExpertWorkInProgressCountForClientUsingGET.count;
				this.anafMessageNotFinalizedCount = rez.a.subs.getAnafMessageNotFinalizedCountUsingGET.count;
			});
		});

		this.anafCommSomethingChanged = this.anafMessagesService.somethingChanged
			.pipe(
				filter((rez) => this.authService.isInRole('EXPERT')),
				switchMap((rez): Observable<Subs_GetAnafMessageNotFinalizedCountUsingGetQuery> => {
					if (rez === true) {
						const va: Subs_GetAnafMessageNotFinalizedCountUsingGetQueryVariables = {
							clientEid: this.currentCompanyEid,
						};
						return this.anafMessagesService.getAnafMessageNotFinalizedCount(va);
					} else {
						return of(null);
					}
				}),
				map((rez) => {
					if (rez != null) {
						this.anafMessageNotFinalizedCount = rez.subs.getAnafMessageNotFinalizedCountUsingGET.count;
					}
				})
			)
			.subscribe();

		this.balanceSheetSomethingChanged = this.balanceSheetService.somethingChanged
			.pipe(
				switchMap((rez): Observable<Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQuery> => {
					if (rez === true) {
						const va: Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQueryVariables = {
							clientEid: this.currentCompanyEid,
						};
						return this.balanceSheetService.getBalanceSheetExpertWorkInProgressCountForClient(va);
					} else {
						return of(null);
					}
				}),
				map((rez) => {
					if (rez != null) {
						this.balanceSheetExpertWorkInProgressCount = rez.subs.getBalanceSheetExpertWorkInProgressCountForClientUsingGET.count;
					}
				})
			)
			.subscribe();

		this.balanceSheetClientSomethingChanged = this.balanceSheetService.somethingChangedClient
			.pipe(
				switchMap((rez): Observable<Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQuery> => {
					if (rez === true) {
						const va: Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQueryVariables = {
							clientEid: this.currentCompanyEid,
						};
						return this.balanceSheetService.getBalanceSheetClientWorkInProgressCountForClient(va);
					} else {
						return of(null);
					}
				}),
				map((rez) => {
					if (rez != null) {
						this.balanceSheetClientWorkInProgressCount = rez.subs.getBalanceSheetClientWorkInProgressCountForClientUsingGET.count;
					}
				})
			)
			.subscribe();

		const subscribe = (obs: Observable<any>): Subscription => {
			return obs
				.pipe(
					map(() => this.route),
					map((route: ActivatedRoute) => {
						while (route.firstChild) {
							route = route.firstChild;
						}
						return route;
					}),
					filter((route: ActivatedRoute) => route.outlet === 'primary'),
					mergeMap((route: ActivatedRoute) => route.data)
				)
				.subscribe((data: any) => {
					this.helpId = data?.helpMenu;
					this.processHelpVideos(true);
				});
		};

		this.helpSubscription1 = subscribe(this.route.data);
		this.helpSubscription2 = subscribe(this.router.events.pipe(filter((event) => event instanceof NavigationEnd)));

		this.commonService
			.dictionaryStream('HelpSuggestions')
			.pipe(take(1))
			.subscribe((data) => {
				this.helpConfig = data;
				this.processHelpVideos(true);
			});
	}

	public ngAfterViewInit() {
		this.parentMenu.changes.subscribe(() => {
			this.initLazy();
		});

		// wootric
		var script = document.createElement('script');

		script.type = 'text/javascript';
		script.src = 'https://cdn.wootric.com/wootric-sdk.js';
		script.async = true;

		this.elementRef.nativeElement.appendChild(script);
		// end wootric
	}

	public initLazy(): void {
		//console.log("initLazy");
		setTimeout(() => {
			$('.collapsible').collapsible();
		}, 1000);
	}

	public ngOnDestroy(): void {
		if (this.balanceSheetSomethingChanged != null) {
			this.balanceSheetSomethingChanged.unsubscribe();
			this.balanceSheetSomethingChanged = null;
		}
		if (this.anafCommSomethingChanged != null) {
			this.anafCommSomethingChanged.unsubscribe();
			this.anafCommSomethingChanged = null;
		}
		if (this.balanceSheetClientSomethingChanged != null) {
			this.balanceSheetClientSomethingChanged.unsubscribe();
			this.balanceSheetClientSomethingChanged = null;
		}
		if (this.companyChangeSubscription != null) {
			this.companyChangeSubscription.unsubscribe();
			this.companyChangeSubscription = null;
		}
		if (this.helpSubscription1) {
			this.helpSubscription1.unsubscribe();
			this.helpSubscription1 = null;
		}
		if (this.helpSubscription2) {
			this.helpSubscription2.unsubscribe();
			this.helpSubscription2 = null;
		}
	}

	processHelpVideos(autoplayVideo: boolean = false): void {
		const autoplayVideoString = autoplayVideo ? '?autoplay=1' : '';
		this.helpVideos = [
			// {
			// 	url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/8b9laJusq6g'),
			// 	title: 'Titlu',
			// 	full_content_link: '<b>Video</b> postat de Stelian <b>Video</b> postat de Stelian <b>Video</b> postat de Stelian <b>Video</b> postat de Stelian<b>Video</b> postat de Stelian'
			// }
		];

		if (this.helpConfig && this.helpId && this.helpConfig.videos && this.helpConfig.videos[this.helpId]) {
			const videos = this.helpConfig.videos[this.helpId];
			if (videos && videos.length) {
				this.helpVideos = videos.map((v: any) => {
					const { url, ...props } = v;
					return {
						url: this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, `${url}${autoplayVideoString}`)),
						...props,
					};
				});
			}
		}
	}

	public closeHideHelpConfirmationDialog(): void {
		this.showHelpConfirmationDialog = false;
	}

	public hideHelpVideos(): void {
		this.showHelpConfirmationDialog = true;
		this.authService.user.showHelp = false;
		this.authService.updateMyData().pipe(take(1)).subscribe();
	}

	public toggleFullScreen(): void {
		this.winRef.toggleFullScreen();
	}

	public toggleSidebar(): void {
		if (this.sideBarVisible) {
			this.winRef.nativeWindow.document.body.overflow = 'hidden';
			this.sideBarVisible = false;
		} else {
			delete this.winRef.nativeWindow.document.body.overflow;
			this.sideBarVisible = true;
		}
	}

	public toggleMenu(menu: AppMenuModel): void {
		if (menu.childrenMenu && menu.childrenMenu.length) {
			menu.canExpand = !menu.canExpand;
		}
	}

	public async selectCompanyId(companyId: string): Promise<void> {
		await this.authService.user.setSelectedCompanyId(companyId).toPromise();
		const company = this.authService.user.selectedCompany;
		if (company) {
			this.configService.accountingPartner = company.accountingPartnerCode !== 'keez' ? company.accountingPartnerCode : null;
		}

		if (this.authService.selectedZoneCode === PredefinedUserZone.Administrator) {
			this.chatService.clearChats();
		}
    return of<void>().toPromise();
	}

	// some menus should be not be visible to accounting partners other than Keez
	public setMenuVisibilityForAccountingPartner() {
		if (this.configService.accountingPartner == null) {
			this.appMenuService.setMenuAndChildrenVisibility('Magazin online', true);
			this.appMenuService.setMenuVisibility('Contract Keez', true);
		} else {
			this.appMenuService.setMenuAndChildrenVisibility('Magazin online', false);
			this.appMenuService.setMenuVisibility('Contract Keez', false);
		}
	}

	public switchZone(zoneCode: PredefinedUserZone): void {
		this.authService.selectedZoneCode = zoneCode;
		this.initializeZoneBooleans();

		const homePage = this.appMenuService.getHomePage();
		if (homePage) {
			this.router.navigate(homePage);
			this.chatService.clearChats();
		}
	}

	public initializeZoneBooleans() {
		this.isClient = this.authService.selectedZoneCode === this.PredefinedUserZone.Administrator;
		this.isAccExpert = this.authService.selectedZoneCode === this.PredefinedUserZone.Expert;
		this.isHRExpert = this.authService.selectedZoneCode === this.PredefinedUserZone.HrExpert;
		this.isSubcontractor = this.authService.selectedZoneCode === this.PredefinedUserZone.Subcontractor;
		this.hasPayrollAccess = this.authService.isInRole('EMPLOYEE_VIEW');
	}

	public gotoNotification(notificationId: string): void {
		const commands = ['/clients', 'notifications'];
		if (notificationId) {
			commands.push(notificationId);
		}
		this.router.navigate(commands);
	}

	public gotoProfile(): void {
		this.router.navigate(['/profile']);
	}

	public gotoContact(): void {
		this.router.navigate([COMMON_ROUTES.CONTACT_PAGE]);
	}

	public openModalWithVideo(helpVideo: any): void {
		this.currentHelpVideo = helpVideo;
		this.showModalBox = true;
	}

	public closeModal(): void {
		this.showModalBox = false;
	}

	@HostListener('document:click', ['$event'])
	public documentClick(e: any): void {
		if (e.target && (e.target.className as any).includes('k-overlay')) {
			this.showModalBox = false;
		}
		//this.makeAlwaysExpandedElementsActive(e.target);
	}

	public makeSpecificMenuActive() {
		this.appMenuService.getMenus().forEach((menu) => {
			let menuName = menu.text.toString();
			let menuElement = document.getElementById(menuName);
			if (menuElement != undefined) {
				let firstChild = menuElement.querySelector('a');

				//remove 'active' from all menu
				if (menuElement.classList.contains('active')) {
					menuElement.classList.remove('active');
				}

				if (firstChild != null && firstChild.classList.contains('active')) {
					firstChild.classList.remove('active');
				}

				//add 'active' to menu.isAtFirstExpanded
				if (menu.isAtFirstExpanded) {
					menuElement.classList.add('active');
					if (firstChild != null) firstChild.classList.add('active');
				}
			}
		});
	}

	public makeAlwaysExpandedElementsActive(target: any) {
		this.appMenuService.getMenus().forEach((menu) => {
			if (menu.alwaysExpanded) {
				let menuName = menu.text.toString();
				var parentElement = document.getElementById(menuName);

				if (target) {
					// traverses the Element and its parents (heading toward the document root) until it finds a node that matches the provided selector string
					var parentTarget = target.closest(`#${menuName}`) as Element;
					// parentTarget found
					if (parentTarget != null) {
						// get all active elements and remove active class
						var activeElements = document.getElementsByClassName('active');
						if (activeElements != null && activeElements.length > 0) {
							activeElements[0].classList.remove('active');
						}

						// add active class to parent element
						parentTarget.classList.add('active');
					} else {
						parentElement.classList.remove('active');
					}
				}
			}
		});
	}

	public openWootric(): void {
		if (!this.wootricIsForTest && this.configService.environment != 'prod') {
			return;
		}

		if (
			this.authService.isInRole('EXPERT_MARKER') ||
			this.authService.isInRole('HR_EXPERT_MARKER') ||
			this.authService.isInRole('SUB_DIGITIZER_AGENT') ||
			this.authService.isInRole('SUB_VALIDATOR_AGENT')
		) {
			return;
		}

		this.authService.getUser().subscribe((user) => {
			if (user) {
				if (this.wootricIsForTest) {
					(window as any)['wootric_no_surveyed_cookie'] = true; // Shows survey immediately for testing purposes.  TODO: Comment out for production.
					(window as any)['wootric_survey_immediately'] = true; // Bypass cookie based throttle for testing purposes.  TODO: Comment out for production.
				}

				(window as any)['wootricSettings'] = {
					email: user.email, // TODO: Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
					created_at: Math.floor(new Date(user.created).getTime() / 1000), // TODO: The current logged in user's sign-up date as a 10 digit Unix timestamp.
					account_token: 'NPS-EU-fbcd2bb9', // This is your unique account token.
					language: 'RO',
					scale_color: 'gradient',
				};

				(window as any)['WootricSurvey'].run();
			}
		});
	}

	public openRatingFirstTime(): void {
		this.authService.getUser().subscribe((user) => {
			if (user) {
				this.rating.checkNeedForRating().subscribe((val: boolean) => {
					if (val) {
						this.showRatingDialogCloseButton = true;
						this.openRatingDialog('web-start');
					}
				});
			}
		});
	}

	public openRatingDialog(source: string): void {
		this.showRatingDialog = true;
		this.ratingSource = source;
	}

	public closeRatingDialog(event?: boolean): void {
		if (event) {
			return;
		}

		this.showRatingDialogCloseButton = false;
		this.showRatingDialog = false;
	}

	public onRatingSave(closeDialog: boolean): void {
		if (closeDialog) {
			this.closeRatingDialog();
			this.toastr.success('Vă mulțumim pentru rating!');
		}
	}

	public resetBadges() {}

	public hasGlobalMessage() {
		return this.hasGlobalWarningMessage || this.hasGlobalImportantMessage();
	}

	// Modificari fiscale din 01.01.2023
	public hasGlobalImportantMessage() {
		return false;
	}

	public hasGlobalWarningMessage() {
		return this.authService.globalWarningMessage;
	}

	public openLink() {
		window.open('https://bit.ly/alerta_modificari_fiscale', '_blank', 'noopener noreferrer');
	}

	public isAccountSuspended() {
		return this.authService.user && this.authService.user.selectedCompany.suspended;
	}

	public goToContractPage() {
		const commands = ['/clients', 'clientcontract', 'contract'];
		this.router.navigate(commands);
	}

	public startImportCount() {
		const menus = this.appMenuService.getMenus();
		const parentMenu = menus.find((m: any) => m.text === 'Import'); // search in parent menus
		if (parentMenu != undefined) {
			if (!parentMenu.disabled) {
				this.importServiceCommon.startImportCount();
			}
		} else {
			// search in children menus
			for (let parentMenu of menus) {
				if (parentMenu.childrenMenu != undefined) {
					const childMenu = parentMenu.childrenMenu.find((m: any) => m.text === 'Import');
					if (childMenu != null && !childMenu.disabled) {
						this.importServiceCommon.startImportCount();
						break;
					}
				}
			}
		}
	}
}
