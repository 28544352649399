import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';
import gql from 'graphql-tag';

import { ConfigService, DownloadService, RestService, UploadService } from '@saliente/common';
import { AuthService } from '@saliente/common-auth';
import { SpinnerService } from '@saliente/library';

import { Maybe, Scalars, Exact, ApolloResult } from '../general/gql-codegen.types';
import { ApolloQueryResult } from '@apollo/client/core';
import {
	BalanceSheetAdditionalDocumentModel,
	BalanceSheetDefinitionModel,
	BalanceSheetInstanceListModel,
	BalanceSheetInstanceModel,
  SuccessResponse,
} from '../../clients/balance-sheet/expert/balance-sheet.models';

/*-------------------------------------------client--------------------------------*/
export type Subs_BalanceSheetTemplateRepresentation = {
	documentEid?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
};
export type Subs_BalanceSheetDefinitionRepresentation = {
	clientType?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	pdfanafFileName?: Maybe<Scalars['String']>;
	pdfanafTemplateEid?: Maybe<Scalars['String']>;
	forMonth?: Maybe<Scalars['Int']>;
	periodicity?: Maybe<Scalars['Int']>;
	isActive?: Maybe<Scalars['Boolean']>;
	templates?: Maybe<Array<Subs_BalanceSheetTemplateRepresentation>>;
};

export type Subs_BalanceSheetInstanceClientViewRepresentation = {
	clientFullDocumentEid?: Maybe<Scalars['String']>;
	clientFullDocumentFileName?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	forMonth?: Maybe<Scalars['Int']>;
	clientViewStatus?: Maybe<Scalars['String']>;
	clientViewStatusDescription?: Maybe<Scalars['String']>;
	downloadUrl?: Maybe<Scalars['String']>;
	balanceSheetDefinition?: Maybe<Subs_BalanceSheetDefinitionRepresentation>;
};

export type Subs_BalanceSheetInstancesClientViewRepresentation = {
	instances?: Maybe<Array<Subs_BalanceSheetInstanceClientViewRepresentation>>;
};

export type Subs_GetBalanceSheetsClientViewUsingGetQueryVariables = Exact<{
	clientEid?: Maybe<Scalars['String']>;
}>;
export type Subs_GetBalanceSheetsClientViewUsingGetQuery = {
	subs?: Maybe<{
		getBalanceSheetsClientViewUsingGET?: Maybe<Subs_BalanceSheetInstancesClientViewRepresentation>;
	}>;
};

const balanceSheetInstanceClientViewFields = `
clientFullDocumentEid
clientFullDocumentFileName
externalId
forMonth
clientViewStatus
clientViewStatusDescription
downloadUrl
balanceSheetDefinition {
    clientType
    description
    externalId
    pdfanafFileName
    pdfanafTemplateEid
    forMonth
    periodicity
    isActive
    templates {
        documentEid
        type
        name
    }
}
`;
export const Subs_GetBalanceSheetsClientViewUsingGetDocument = gql`
    query subs_getBalanceSheetsClientViewUsingGET($clientEid: String) {
        subs {
            getBalanceSheetsClientViewUsingGET(clientEid: $clientEid) {
                instances {
                  ${balanceSheetInstanceClientViewFields}
                }
            }
        }
    }
`;
export type Subs_GetBalanceSheetInstanceClientViewUsingGetQuery = {
	subs?: Maybe<{
		getBalanceSheetInstanceClientViewUsingGET?: Maybe<Subs_BalanceSheetInstanceClientViewRepresentation>;
	}>;
};

export type Subs_GetBalanceSheetInstanceClientViewUsingGetQueryVariables = Exact<{
	instanceEid?: Maybe<Scalars['String']>;
}>;
export const Subs_GetBalanceSheetInstanceClientViewUsingGetDocument = gql`
    query subs_getBalanceSheetInstanceClientViewUsingGET($instanceEid: String) {
        subs {
            getBalanceSheetInstanceClientViewUsingGET(instanceEid: $instanceEid)
            {
              ${balanceSheetInstanceClientViewFields}
            }
        }
    }
`;
export const Subs_PrepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPutDocument = gql`
    mutation subs_prepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUT($instanceEid: String, $request: subs_BalanceSheetDocumentPrepareForUploadRepresentationInput) {
  subs {
    prepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUT(
      instanceEid: $instanceEid
      request: $request
    ) {
      ${balanceSheetInstanceClientViewFields}
    }
  }
}
`;
export type Subs_PrepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUTMutation = {
	subs?: Maybe<{
		prepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUT?: Subs_BalanceSheetInstanceClientViewRepresentation;
	}>;
};

/*--------------------------------expert-------------------------------*/
export type Subs_BalanceSheetInstanceRepresentation = {
	clientFullDocumentEid?: Maybe<Scalars['String']>;
	clientFullDocumentFileName?: Maybe<Scalars['String']>;
	pdfanafDocumentEid?: Maybe<Scalars['String']>;
	pdfanafFileName?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	clientEid?: Maybe<Scalars['String']>;
	forMonth?: Maybe<Scalars['Int']>;
	clientFullDocumentWaitingForUpload?: Maybe<Scalars['Boolean']>;
	pdfanafDocumentWaitingForUpload?: Maybe<Scalars['Boolean']>;
	momentClientToExpert?: Maybe<Scalars['String']>;
	momentExpertToClient?: Maybe<Scalars['String']>;
	momentVerified?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	statusDescription?: Maybe<Scalars['String']>;
	isWorkInProgress?: Maybe<Scalars['Boolean']>;
	paymentGenerated?: Maybe<Scalars['Boolean']>;
	momentPaymentInitiated?: Maybe<Scalars['String']>;
	paymentAmount?: Maybe<Scalars['Float']>;
	paymentStatus?: Maybe<Scalars['String']>;
	customPaymentRequestExternalId?: Maybe<Scalars['String']>;
	balanceSheetAdditionalDocuments?: Maybe<Array<Subs_BalanceSheetAdditionalDocumentRepresentation>>;
	balanceSheetDefinition?: Maybe<Subs_BalanceSheetDefinitionRepresentation>;
	momentExpertToClientAsString?: Maybe<Scalars['String']>;
	momentClientToExpertAsString?: Maybe<Scalars['String']>;
	momentVerifiedAsString?: Maybe<Scalars['String']>;
};

export type Subs_BalanceSheetAdditionalDocumentRepresentation = {
	documentEid?: Maybe<Scalars['String']>;
	fileName?: Maybe<Scalars['String']>;
	externalId?: Maybe<Scalars['String']>;
	waitingForUpload?: Maybe<Scalars['Boolean']>;
};
export type Subs_PrepareBalanceSheetInstanceForClientFullDocumentUploadUsingPutMutation = {
	subs?: Maybe<{
		balanceSheet?: Subs_BalanceSheetInstanceRepresentation;
	}>;
};
const balanceSheetInstancesListQueryText = gql`
	query balanceSheetInstances($clientEid: String!) {
		subs {
			balanceSheets: getBalanceSheetsForClientUsingGET(clientEid: $clientEid) {
				instances {
					balanceSheetDefinition {
						clientType
						description
						externalId
						forMonth
						isActive
						periodicity
					}
					clientEid
					externalId
					forMonth
					isWorkInProgress
					momentClientToExpert: momentClientToExpertAsString
					momentExpertToClient: momentExpertToClientAsString
					momentVerified: momentVerifiedAsString
					status
					statusDescription
				}
			}
		}
	}
`;

const balanceSheetInstanceFields = `
balanceSheetDefinition {
	clientType
	description
	externalId
	forMonth
	isActive
	periodicity
	templates{
		documentEid
		name
		type
	}
}
additionalDocuments:balanceSheetAdditionalDocuments{
	documentEid
	externalId
	fileName
}
clientEid
clientFullDocumentEid
clientFullDocumentProcessedEid
clientFullDocumentFileName
clientFullDocumentWaitingForUpload
externalId
forMonth
isWorkInProgress

momentClientToExpert: momentClientToExpertAsString
momentExpertToClient:momentExpertToClientAsString
momentVerified:momentVerifiedAsString
pdfanafDocumentEid
pdfanafFileName
pdfanafDocumentWaitingForUpload
paymentGenerated
status
statusDescription
`;

const getBalanceSheetInstanceQueryText = gql`
query balanceSheetInstance ($instanceEid: String!){
	subs{
		balanceSheet: getBalanceSheetInstanceUsingGET(instanceEid: $instanceEid){
			${balanceSheetInstanceFields}
		}
	}
}`;

const balanceSheetDefinitionsListQueryText = gql`
	query balanceSheetDefinitions {
		subs {
			balanceSheetDefinitions: getBalanceSheetDefinitionsUsingGET {
				clientType
				description
				externalId
				forMonth
				isActive
				periodicity
			}
		}
	}
`;

const createBalanceSheetInstanceMutationText = gql`
	mutation createBalanceSheetInstance($clientEid: String!, $definitionEid: String!) {
		subs {
			balanceSheetInstance: createBalanceSheetInstanceUsingPOST(clientEid: $clientEid, definitionEid: $definitionEid) {
				externalId
			}
		}
	}
`;

const prepareBalanceSheetInstanceForPdfanafUploadMutationText = gql`
mutation prepareBalanceSheetInstanceForPdfanafUpload($instanceEid: String!, $data: subs_BalanceSheetDocumentPrepareForUploadRepresentationInput!){
	subs{
		balanceSheet: prepareBalanceSheetInstanceForPdfanafUploadUsingPUT(instanceEid: $instanceEid, request: $data){
			${balanceSheetInstanceFields}
		}
	}
}`;

const prepareBalanceSheetInstanceForClientFullDocumentUploadMutationText = gql`
mutation prepareBalanceSheetInstanceForClientFullDocumentUpload($instanceEid: String!, $data: subs_BalanceSheetDocumentPrepareForUploadRepresentationInput!){
	subs{
		balanceSheet: prepareBalanceSheetInstanceForClientFullDocumentUploadUsingPUT(instanceEid: $instanceEid, request: $data){
			${balanceSheetInstanceFields}
		}
	}
}`;

const cancelBalanceSheetInstanceMutationText = gql`
	mutation cancelBalanceSheetInstance($instanceEid: String!) {
		subs {
			balanceSheetInstance: cancelBalanceSheetInstanceUsingPUT(instanceEid: $instanceEid) {
				externalId
			}
		}
	}
`;

const getAdditionalDocumentQueryText = gql`
	query getAdditionalDocument($instanceEid: String!, $documentEid: String!) {
		subs {
			additionalDocument: getAdditionalDocumentUsingGET(instanceEid: $instanceEid, externalId: $documentEid) {
				documentEid
				externalId
				fileName
				waitingForUpload
			}
		}
	}
`;

const createAdditionalDocumentMutationText = gql`
	mutation createAdditionalDocument($instanceEid: String!, $data: subs_BalanceSheetDocumentPrepareForUploadRepresentationInput!) {
		subs {
			additionalDocument: createAdditionalDocumentUsingPOST(instanceEid: $instanceEid, request: $data) {
				documentEid
				externalId
				fileName
				waitingForUpload
			}
		}
	}
`;

const prepareAdditionalDocumentForUploadMutationText = gql`
	mutation prepareAdditionalDocumentForUpload($instanceEid: String!, $documentEid: String!, $data: subs_BalanceSheetDocumentPrepareForUploadRepresentationInput!) {
		subs {
			additionalDocument: prepareAdditionalDocumentForUploadUsingPUT(instanceEid: $instanceEid, externalId: $documentEid, request: $data) {
				documentEid
				externalId
				fileName
				waitingForUpload
			}
		}
	}
`;

const deleteAdditionalDocumentMutationText = gql`
	mutation deleteAdditionalDocument($instanceEid: String!, $externalId: String!) {
		subs {
			deleteAdditionalDocumentUsingDELETE(instanceEid: $instanceEid, externalId: $externalId) {
				Message
			}
		}
	}
`;

const expertFinalizeBalanceMutationText = gql`
mutation expertFinalizeBalance($instanceEid: String!){
	subs{
		balanceSheet: markBalanceSheetInstanceExpertFinalizedUsingPUT(instanceEid: $instanceEid){
			${balanceSheetInstanceFields}
		}
	}
}`;
const expertChecksFinalizeBalanceMutationText = gql`
mutation expertChecksFinalizeBalance($instanceEid: String!){
	subs{
		balanceSheet: markBalanceSheetInstanceExpertChecksFinalizedUsingPUT(instanceEid: $instanceEid){
			${balanceSheetInstanceFields}
		}
	}
}`;

const anafFinalizeBalanceMutationText = gql`
mutation anafFinalizeBalance($instanceEid: String!){
	subs{
		balanceSheet: markBalanceSheetInstanceBOFinalizedForANAFUsingPUT(instanceEid: $instanceEid){
			${balanceSheetInstanceFields}
		}
	}
}`;

const nonAnafFinalizeBalanceMutationText = gql`
mutation nonAnafFinalizeBalance($instanceEid: String!){
	subs{
		balanceSheet: markBalanceSheetInstanceBOFinalizedNonANAFUsingPUT(instanceEid: $instanceEid){
			${balanceSheetInstanceFields}
		}
	}
}`;

export const Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetDocument = gql`
	query subs_getBalanceSheetExpertWorkInProgressCountForClientUsingGET($clientEid: String) {
		subs {
			getBalanceSheetExpertWorkInProgressCountForClientUsingGET(clientEid: $clientEid) {
				count
			}
		}
	}
`;

export const Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetDocument = gql`
	query subs_getBalanceSheetClientWorkInProgressCountForClientUsingGET($clientEid: String) {
		subs {
			getBalanceSheetClientWorkInProgressCountForClientUsingGET(clientEid: $clientEid) {
				count
			}
		}
	}
`;

export type Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQueryVariables = {
	clientEid: string;
};
export type Subs_BalanceSheetExpertWorkInProgressCountRepresentation = {
	count: number;
};
export type Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQuery = {
	subs: {
		getBalanceSheetExpertWorkInProgressCountForClientUsingGET: Subs_BalanceSheetExpertWorkInProgressCountRepresentation;
	};
};

export type Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQueryVariables = {
	clientEid: string;
};
export type Subs_BalanceSheetClientWorkInProgressCountRepresentation = {
	count: number;
};
export type Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQuery = {
	subs: {
		getBalanceSheetClientWorkInProgressCountForClientUsingGET: Subs_BalanceSheetClientWorkInProgressCountRepresentation;
	};
};


export const Subs_SubmitBalanceSheetToAnafUsingPostDocument = gql`
    mutation subs_submitBalanceSheetToANAFUsingPOST($instanceEid: String) {
  subs {
    submitBalanceSheetToANAFUsingPOST(instanceEid: $instanceEid) {
      ${balanceSheetInstanceFields}
    }
  }
}`;
export type Subs_SubmitBalanceSheetToAnafUsingPostMutation = {
	subs?: Maybe<{
		submitBalanceSheetToANAFUsingPOST?: Subs_BalanceSheetInstanceRepresentation;
	}>;
};
export const Subs_MarkBalanceSheetBackToClientWorkInProgressUsingPutDocument = gql`
    mutation subs_markBalanceSheetBackToClientWorkInProgressUsingPUT($instanceEid: String) {
  subs {
    markBalanceSheetBackToClientWorkInProgressUsingPUT(instanceEid: $instanceEid) {
      ${balanceSheetInstanceFields}
    }
  }
}`;
export const Subs_ReprocessBalanceSheetInstanceFlatteningUsingPutDocument = gql`
mutation subs_reprocessBalanceSheetInstanceFlatteningUsingPUT($instanceEid: String) {
subs {
reprocessBalanceSheetInstanceFlatteningUsingPUT(instanceEid: $instanceEid) {
  Message
}
}
}`;
export type Subs_MarkBalanceSheetBackToClientWorkInProgressUsingPuttMutation = {
	subs?: Maybe<{
		markBalanceSheetBackToClientWorkInProgressUsingPUT?: Subs_BalanceSheetInstanceRepresentation;
	}>;
};

/*-------------------dashboard--------------------------*/
export const Subs_GetBalanceSheetsDashboardUsingGetDocument = gql`
	query subs_getBalanceSheetsDashboardUsingGET {
		subs {
			getBalanceSheetsDashboardUsingGET {
				balanceSheets {
					balanceEid
					clientEid
					companyName
					companyNameNa
					fiscalNumber
					description
					forMonth
					status
					lastUserSentToClient
					momentExpertToClient
					paymentGenerated
					isActiveForStatutoryReports
					momentPaymentInitiated
					paymentAmount
					paymentStatus
					accountingPartnerCode
          submitDeadline
          assignedExpert
          incorporationType
				}
			}
		}
	}
`;

export type Subs_BalanceSheetDashboardRepresentation = {
	balanceEid?: Maybe<Scalars['String']>;
	clientEid?: Maybe<Scalars['String']>;
	companyName?: Maybe<Scalars['String']>;
	companyNameNa?: Maybe<Scalars['String']>;
	fiscalNumber?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	forMonth?: Maybe<Scalars['Int']>;
	status?: Maybe<Scalars['String']>;
	lastUserSentToClient?: Maybe<Scalars['String']>;
	momentExpertToClient?: Maybe<Scalars['String']>;
	paymentGenerated?: Maybe<Scalars['Boolean']>;
	isActiveForStatutoryReports?: Maybe<Scalars['Boolean']>;
	momentPaymentInitiated?: Maybe<Scalars['String']>;
	paymentAmount?: Maybe<Scalars['Float']>;
	paymentStatus?: Maybe<Scalars['String']>;
	accountingPartnerCode?: Maybe<Scalars['String']>;
  submitDeadline?: Maybe<Scalars['Int']>;
	incorporationType?: Maybe<Scalars['Int']>;
  assignedExpert?: Maybe<Scalars['String']>;
};
export type Subs_BalanceSheetsDashboardRepresentation = {
	balanceSheets?: Maybe<Array<Subs_BalanceSheetDashboardRepresentation>>;
};

export type Subs_GetBalanceSheetsDashboardUsingGetQuery = {
	subs?: Maybe<{
		getBalanceSheetsDashboardUsingGET?: Subs_BalanceSheetsDashboardRepresentation;
	}>;
};

@Injectable({
	providedIn: 'root',
})
export class BalanceSheetService {
	public somethingChangedBehavior = new BehaviorSubject<boolean>(false);
	public somethingChanged = this.somethingChangedBehavior.asObservable();

	public somethingChangedClientBehavior = new BehaviorSubject<boolean>(false);
	public somethingChangedClient = this.somethingChangedClientBehavior.asObservable();

	constructor(
		private authService: AuthService,
		private restService: RestService,
		private spinner: SpinnerService,
		private configService: ConfigService,
		private uploadService: UploadService,
		private downloadService: DownloadService
	) {}

	public listSourceStream(): Observable<BalanceSheetInstanceListModel[]> {
		return this.authService.getSelectedCompanyId().pipe(
			switchMap((companyId) => {
				if (companyId) {
					return this.restService
						.query({
							query: balanceSheetInstancesListQueryText,
							variables: {
								clientEid: companyId,
							},
							fetchPolicy: 'network-only',
						})
						.pipe(
							map((res: any) => {
								let result: BalanceSheetInstanceListModel[] = null;
								try {
									result = plainToClass<BalanceSheetInstanceListModel, Record<string, unknown>>(BalanceSheetInstanceListModel, res.data.subs.balanceSheets.instances || []);
									this.somethingChangedBehavior.next(true);
								} catch {
									result = null;
								}
								return result;
							})
						);
				}
				return of(null);
			})
		);
	}

	public fetchStream(balanceSheetEid: string): Observable<BalanceSheetInstanceModel> {
		return this.restService
			.query({
				query: getBalanceSheetInstanceQueryText,
				variables: {
					instanceEid: balanceSheetEid,
				},
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((res: any) => {
					if (res) {
						try {
							return plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public createStream(definitionEid: string): Observable<any> {
		return this.restService
			.mutate({
				mutation: createBalanceSheetInstanceMutationText,
				variables: {
					clientEid: this.authService.user.selectedCompanyId,
					definitionEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						this.somethingChangedBehavior.next(true);
						return res.data.subs.balanceSheetInstance.externalId;
					}
					return false;
				})
			);
	}

	public cancelStream(instanceEid: string): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: cancelBalanceSheetInstanceMutationText,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						this.somethingChangedBehavior.next(true);
						return !!res.data.subs.balanceSheetInstance.externalId;
					}
					return false;
				})
			);
	}

	public definitionsStream(): Observable<BalanceSheetDefinitionModel[]> {
		return this.restService
			.query({
				query: balanceSheetDefinitionsListQueryText,
				fetchPolicy: 'cache-first',
			})
			.pipe(
				map((res: any) => {
					let result: BalanceSheetDefinitionModel[] = null;
					try {
						result = plainToClass<BalanceSheetDefinitionModel, Record<string, unknown>>(BalanceSheetDefinitionModel, res.data.subs.balanceSheetDefinitions || []);
					} catch {
						result = [];
					}
					return result;
				})
			);
	}

	public getBalanceSheetExpertWorkInProgressCountForClient(
		variables: Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQueryVariables
	): Observable<Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQuery> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.query({
				query: Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			}, { spinner: false })
			.pipe(
				map((res: ApolloQueryResult<Subs_GetBalanceSheetExpertWorkInProgressCountForClientUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public getBalanceSheetClientWorkInProgressCountForClient(
		variables: Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQueryVariables
	): Observable<Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQuery> {
		const clientEid = this.authService.user.selectedCompanyId;
		return this.restService
			.query({
				query: Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			}, { spinner: false })
			.pipe(
				map((res: ApolloQueryResult<Subs_GetBalanceSheetClientWorkInProgressCountForClientUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public downloadModelStream(instanceEid: string): Observable<string> {
		return this.configService.getSubsDownloadUrl().pipe(
			switchMap((url) => {
				this.spinner.show();
				return this.downloadService.download(url + `/balance-sheets/instances/${instanceEid}/templates?attachment=true`).pipe(
					finalize(() => {
						this.spinner.hide();
					})
				);
			})
		);
	}

	public uploadPdfAnafFileStream(instanceEid: string, file: any): Observable<boolean | BalanceSheetInstanceModel> {
		const meta: any = {
			clientEid: this.authService.user.selectedCompanyId,
			contextType: 'BalanceSheetDocumentUpload',
			contextEid: instanceEid,
			category: 'Misc',
			documentType: 'BALANCESHEET_DOCUMENT_PDF_EXPERT',
		};

		this.spinner.show();
		return this.restService
			.mutate({
				mutation: prepareBalanceSheetInstanceForPdfanafUploadMutationText,
				variables: {
					instanceEid,
					data: {
						fileName: file.name,
					},
				},
			})
			.pipe(
				switchMap((res: any) => {
					if (res) {
						const balanceSheet = plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
						return this.configService.getDocumentsUploadUrl().pipe(
							switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
							filter((data) => !!data.done),
							map((data) => balanceSheet)
						);
					}
					return of(false);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public getAdditionalDocumentStream(instanceEid: string, documentEid: string): Observable<BalanceSheetAdditionalDocumentModel> {
		return this.restService
			.query({
				query: getAdditionalDocumentQueryText,
				variables: {
					instanceEid,
					documentEid,
				},
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((res: any) => {
					if (res) {
						try {
							return plainToClass(BalanceSheetAdditionalDocumentModel, res.data.subs.additionalDocument);
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	public uploadAdditionalDocumentFileStream(document: any, file: any, spinner = true): Observable<any> {
		const meta: any = {
			clientEid: this.authService.user.selectedCompanyId,
			contextType: 'BalanceSheetDocumentUpload',
			contextEid: document.externalId,
			category: 'Misc',
			documentType: 'BALANCESHEET_DOCUMENT_ADDITIONAL',
		};

		if (spinner === true) {
			this.spinner.show();
		}
		return this.configService.getDocumentsUploadUrl().pipe(
			switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
			filter((data) => !!data.done),
			map(() => {
				return document;
			}),
			finalize(() => {
				if (spinner === true) {
					this.spinner.hide();
				}
			})
			// catchError(err => {
			//   return of(null);
			// })
		);
	}

	public createAndUploadAdditionalDocumentFileStream(instanceEid: string, file: any): Observable<any> {
		this.spinner.show();
		return this.restService
			.mutate({
				mutation: createAdditionalDocumentMutationText,
				variables: {
					instanceEid,
					data: {
						fileName: file.name,
					},
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetAdditionalDocumentModel, res.data.subs.additionalDocument);
					}
					return false;
				}),
				switchMap((res: any) => {
					if (res) {
						return this.uploadAdditionalDocumentFileStream(res, file, false);
					}
					return of(res);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public prepareAndUploadAdditionalDocumentFileStream(instanceEid: string, document: any, file: any): Observable<any> {
		this.spinner.show();
		return this.restService
			.mutate({
				mutation: prepareAdditionalDocumentForUploadMutationText,
				variables: {
					instanceEid,
					documentEid: document.externalId,
					data: {
						fileName: file.name,
					},
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetAdditionalDocumentModel, res.data.subs.additionalDocument);
					}
					return false;
				}),
				switchMap((res: any) => {
					if (res) {
						return this.uploadAdditionalDocumentFileStream(res, file, false);
					}
					return of(res);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public downloadFileStream(eidFile: string): Observable<string> {
		return this.configService.getDocumentsDownloadUrl().pipe(
			switchMap((url) => {
				this.spinner.show();
				return this.downloadService.download(url + '/documents/' + eidFile + '/file?attachment=true').pipe(
					finalize(() => {
						this.spinner.hide();
					})
				);
			}),
			map(() => eidFile)
		);
	}

	public deleteAdditionalDocumentStream(instanceEid: string, externalId: string): Observable<boolean> {
		return this.restService
			.mutate({
				mutation: deleteAdditionalDocumentMutationText,
				variables: {
					instanceEid,
					externalId,
				},
			})
			.pipe(
				map((res: any) => {
					return !!res;
				})
			);
	}

	public expertFinalizeBalanceStream(instanceEid: string): Observable<false | BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: expertFinalizeBalanceMutationText,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						this.somethingChangedBehavior.next(true);
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
					}
					return false;
				})
			);
	}

	public expertChecksFinalizeBalanceStream(instanceEid: string): Observable<false | BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: expertChecksFinalizeBalanceMutationText,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
					}
					return false;
				})
			);
	}

	public downloadExpertBalanceFilesStream(instanceEid: string): Observable<string> {
		return this.configService.getSubsDownloadUrl().pipe(
			switchMap((url) => {
				this.spinner.show();
				return this.downloadService.download(url + `/balance-sheets/instances/${instanceEid}/client-download?attachment=true`).pipe(
					finalize(() => {
						this.spinner.hide();
					})
				);
			})
		);
	}

	public getBalanceSheetDocumentPdfClientMetadata(instanceEid: string): any {
		return {
			clientEid: this.authService.user.selectedCompanyId,
			contextType: 'BalanceSheetDocumentUpload',
			contextEid: instanceEid,
			category: 'Misc',
			documentType: 'BALANCESHEET_DOCUMENT_PDF_CLIENT',
		};
	}
	public prepareBalanceSheetInstanceClientViewFullDocumentUpload(instanceEid: string, file: any): Observable<Subs_BalanceSheetInstanceClientViewRepresentation> {
		return this.restService
			.mutate({
				mutation: Subs_PrepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPutDocument,
				variables: {
					instanceEid,
					request: {
						fileName: file.name,
					},
				},
			})
			.pipe(
				map((res: ApolloQueryResult<Subs_PrepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUTMutation>) => {
					if (res) {
						return res.data.subs.prepareBalanceSheetInstanceClientViewFullDocumentUploadUsingPUT;
					}
					return null;
				})
			);
	}

	public uploadClientFileStream(instanceEid: string, file: any): Observable<boolean | BalanceSheetInstanceModel> {
		const meta: any = this.getBalanceSheetDocumentPdfClientMetadata(instanceEid);
		this.spinner.show();
		return this.restService
			.mutate({
				mutation: prepareBalanceSheetInstanceForClientFullDocumentUploadMutationText,
				variables: {
					instanceEid,
					data: {
						fileName: file.name,
					},
				},
			})
			.pipe(
				switchMap((res: any) => {
					if (res) {
						const balanceSheet = plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
						return this.configService.getDocumentsUploadUrl().pipe(
							switchMap((url) => this.uploadService.upload(url + '/files', file, meta)),
							filter((data) => !!data.done),
							map((data) => balanceSheet)
						);
					}
					return of(false);
				}),
				finalize(() => {
					this.spinner.hide();
				})
			);
	}

	public anafFinalizeBalanceStream(instanceEid: string): Observable<false | BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: anafFinalizeBalanceMutationText,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
					}
					return false;
				})
			);
	}

	public nonAnafFinalizeBalanceStream(instanceEid: string): Observable<false | BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: nonAnafFinalizeBalanceMutationText,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.balanceSheet);
					}
					return false;
				})
			);
	}
	public downloadReadyForANAF(instanceEid: string): Observable<string> {
		return this.configService.getSubsDownloadUrl().pipe(
			switchMap((url) => {
				this.spinner.show();
				return this.downloadService.download(url + `/balance-sheets/instances/${instanceEid}/ready-for-anaf?attachment=true`).pipe(
					finalize(() => {
						this.spinner.hide();
					})
				);
			})
		);
	}
	public submitBalanceSheetToANAF(instanceEid: string): Observable<BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: Subs_SubmitBalanceSheetToAnafUsingPostDocument,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.submitBalanceSheetToANAFUsingPOST);
					}
					return null;
				})
			);
	}
	public reprocessBalanceSheetInstanceFlattening(instanceEid: string): Observable<SuccessResponse> {
		return this.restService
			.mutate({
				mutation: Subs_ReprocessBalanceSheetInstanceFlatteningUsingPutDocument,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					return res.data.subs;
				})
			);
	}
	public markBalanceSheetBackToClientWorkInProgress(instanceEid: string): Observable<BalanceSheetInstanceModel> {
		return this.restService
			.mutate({
				mutation: Subs_MarkBalanceSheetBackToClientWorkInProgressUsingPutDocument,
				variables: {
					instanceEid,
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return plainToClass(BalanceSheetInstanceModel, res.data.subs.markBalanceSheetBackToClientWorkInProgressUsingPUT);
					}
					return null;
				})
			);
	}

	/*-----------------------------client--------------------------------*/
	public getBalanceSheetsClientView(variables: Subs_GetBalanceSheetsClientViewUsingGetQueryVariables): Observable<Subs_GetBalanceSheetsClientViewUsingGetQuery> {
		return this.restService
			.query({
				query: Subs_GetBalanceSheetsClientViewUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloQueryResult<Subs_GetBalanceSheetsClientViewUsingGetQuery>) => {
					if (res) {
						return res.data;
					}
					return null;
				})
			);
	}
	public getBalanceSheetInstanceClientView(
		variables: Subs_GetBalanceSheetInstanceClientViewUsingGetQueryVariables
	): Observable<Subs_GetBalanceSheetInstanceClientViewUsingGetQuery> {
		return this.restService
			.query({
				query: Subs_GetBalanceSheetInstanceClientViewUsingGetDocument,
				variables: variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloQueryResult<Subs_GetBalanceSheetInstanceClientViewUsingGetQuery>) => {
					if (res) {
						return res.data;
					}
					return null;
				})
			);
	}
	public getBalanceSheetsDashboard(): Observable<Subs_BalanceSheetsDashboardRepresentation> {
		return this.restService
			.query({
				query: Subs_GetBalanceSheetsDashboardUsingGetDocument,
				fetchPolicy: 'no-cache',
			})
			.pipe(
				map((res: ApolloQueryResult<Subs_GetBalanceSheetsDashboardUsingGetQuery>) => {
					if (res) {
						return res.data.subs.getBalanceSheetsDashboardUsingGET;
					}
					return null;
				})
			);
	}
}
