import { Type, Exclude, Expose, classToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';

export class EnrollmentStatus {
	public pendingRegistrationEid?: string;
	public pendingRegistrationType?: string;

	@Expose({ name: 'status' })
	public name: string;

	public static typeProvider() {
		return EnrollmentStatus;
	}
}

export class ZoneCompany {
	@Expose({ name: 'clientEid' })
	public externalId: string;
	// CM 20210610: seems the field is not really used
	// @Expose({ name: 'code' })
	// public companyCode: string;
	@Expose({ name: 'name' })
	public companyName: string;
	@Expose({ name: 'nameNA' })
	public companyNameAI: string;
	public status: string;
	public inactive: boolean;
	public suspended: boolean;
	public fiscalNumber: string;
	public accountingPartnerCode: string;

	//public lastClosedAccountingPeriod: Date;
	public lastClosedAccountingPeriod: number;
	public firstDayAfterLastClosedAccountingPeriod: Date;
	public isVATPayer: boolean = true;

	public static typeProvider() {
		return ZoneCompany;
	}
}
export class UserZone {
	public show: boolean = false;
	public zoneCode: string;

	@Type(ZoneCompany.typeProvider)
	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'clients' })
	public companies: ZoneCompany[] = [];

	public static typeProvider() {
		return UserZone;
	}
}

export class UserCompany {
	public externalId: string;
	@Expose({ name: 'company_name' })
	public companyName: string;
	@Expose({ name: 'company_name_na' })
	public companyNameAI: string;
	public status: string;
	public accountingPartnerCode: string;

	public lastClosedAccountingPeriod: Date;
	public fiscalNumber: string;

	public static typeProvider() {
		return UserCompany;
	}
}
export class UserRolesExpanded {
	public expandedRoles: string[];
}
/*
export class UserRole {
	public code: string;
	public name: string;
	public isClientRole: boolean;

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'clients' })
	public companies: string[] = [];

	public static typeProvider() {
		return UserRole;
	}
}
*/
export enum PredefinedUserZone {
	Administrator = 'CLIENT',
	Expert = 'ACCOUNTING_EXPERT',
	HrExpert = 'PAYROLL_EXPERT',
	Subcontractor = 'OPERATOR',
	None = 'NONE',
}

export class User {
	private __cached_roles__: any = {};

	@Exclude({ toPlainOnly: true })
	public externalId: string;
	@Exclude({ toPlainOnly: true })
	public userLogin: string;

	public email: string;
	public phone: string;
	public firstName: string;
	public lastName: string;
	public showHelp = false;
	public created: Date;
	public isMfaEnabled: boolean = false;

	@Exclude()
	private _selectedCompanyId: string;

	@Exclude()
	authService: AuthService = null;
	@Exclude()
	public selectedCompanyIdObservable: BehaviorSubject<string>;
	@Exclude()
	get selectedCompanyIdOld(): string {
		if (!this._selectedCompanyId) {
			if (this.companies.length) {
				const selectedCompanyId = sessionStorage.getItem('selectedCompanyId') || localStorage.getItem('selectedCompanyId');
				const selectedCompany = this.companies.find((company) => company.externalId === selectedCompanyId);
				if (selectedCompany) {
					this._selectedCompany = selectedCompany;
					this.selectedCompanyIdOld = selectedCompanyId;
				} else {
					this._selectedCompany = this.companies[0];
					this.selectedCompanyIdOld = this._selectedCompany.externalId;
				}
			}
			sessionStorage.setItem('selectedCompanyId', this._selectedCompanyId);
			localStorage.setItem('selectedCompanyId', this._selectedCompanyId);
		}
		return this._selectedCompanyId;
	}
	set selectedCompanyIdOld(value: string) {
		if (this._selectedCompanyId !== value) {
			this._selectedCompany = null;
			this._selectedCompanyId = value;
			this.__cached_roles__ = {};
			sessionStorage.setItem('selectedCompanyId', value);
			localStorage.setItem('selectedCompanyId', value);
			if (this.authService !== null) {
				this.authService.getUserRolesExpandedForClientObservable(value).subscribe((res: any) => {
					this.userRolesExpanded = res.data.accounts.userRolesExpanded;
					if (this.selectedCompanyIdObservable) {
						this.selectedCompanyIdObservable.next(value);
					}
				});
			}
		}
	}

	@Exclude()
	get selectedCompanyId(): string {
		if (!this._selectedCompanyId) {
			if (this.companies.length) {
				const selectedCompanyId = sessionStorage.getItem('selectedCompanyId') || localStorage.getItem('selectedCompanyId');
				const selectedCompany = this.companies.find((company) => company.externalId === selectedCompanyId);
				if (selectedCompany) {
					this._selectedCompany = selectedCompany;
					this.setSelectedCompanyId(selectedCompanyId).subscribe();
				} else {
					this._selectedCompany = this.companies[0];
					this.setSelectedCompanyId(this._selectedCompany.externalId).subscribe();
				}
			}
			sessionStorage.setItem('selectedCompanyId', this._selectedCompanyId);
			localStorage.setItem('selectedCompanyId', this._selectedCompanyId);
		}
		return this._selectedCompanyId;
	}
  /*- just return some Observable; Observable<void> will not generate a stream so applied subscription function to it will not be called*/
	setSelectedCompanyId(value: string): Observable<string> {
		if (this._selectedCompanyId !== value) {
			this._selectedCompany = null;
			this._selectedCompanyId = value;
			this.__cached_roles__ = {};
			sessionStorage.setItem('selectedCompanyId', value);
			localStorage.setItem('selectedCompanyId', value);
			if (this.authService !== null) {
				return this.authService.getUserRolesExpandedForClientObservable(value).pipe(
					switchMap((res: any) => {
						this.userRolesExpanded = res.data.accounts.userRolesExpanded;
						if (this.selectedCompanyIdObservable) {
							console.log('selectedCompanyIdObservable.next:' + value);
							this.selectedCompanyIdObservable.next(value);
						}
						return of(null);
					})
				);
			} else {
				return of(null);
			}
		} else {
			return of(null);
		}
	}

	@Exclude()
	private _selectedCompany: ZoneCompany;
	@Exclude()
	get selectedCompany(): ZoneCompany {
		if (!this._selectedCompany) {
			this._selectedCompany = this.companies.find((item: ZoneCompany) => item.externalId === this.selectedCompanyId) || new ZoneCompany();
		}
		return this._selectedCompany;
	}

	@Exclude()
	public selectedZoneCodeObservable: BehaviorSubject<string>;
	@Exclude()
	private _selectedZoneCode: string;
	@Exclude()
	get selectedZoneCode(): string {
		if (!this._selectedZoneCode) {
			this._selectedZone = null;
			this._selectedZoneCode = sessionStorage.getItem('defaultUserZone') || localStorage.getItem('defaultUserZone');
			if (!this._selectedZoneCode || !this.isInZone(this._selectedZoneCode)) {
				if (this.isInZone(PredefinedUserZone.Administrator)) {
					this._selectedZoneCode = PredefinedUserZone.Administrator;
				} else {
					const zones = this.zones.filter((z) => this.isInZone(z.zoneCode));
					if (zones.length) {
						this._selectedZoneCode = zones[0].zoneCode;
					} else {
						this._selectedZoneCode = 'none';
					}
				}
			}
			sessionStorage.setItem('defaultUserZone', this._selectedZoneCode);
			localStorage.setItem('defaultUserZone', this._selectedZoneCode);
		}
		return this._selectedZoneCode;
	}
	set selectedZoneCode(value: string) {
		if (this._selectedZoneCode != value) {
			if (this.isInZone(value)) {
				this._selectedZone = null;
				this._selectedCompanyId = null;
				this._selectedCompany = null;
				this._companies = null;
				this._selectedZoneCode = value;
				sessionStorage.setItem('defaultUserZone', value);
				localStorage.setItem('defaultUserZone', value);
				if (this.selectedZoneCodeObservable) {
					this.selectedZoneCodeObservable.next(value);
				}
			}
		}
	}

	@Exclude()
	private _selectedZone: UserZone;
	@Exclude()
	get selectedZone(): UserZone {
		if (!this._selectedZone) {
			const selectedZoneCode = this.selectedZoneCode;
			this._selectedZone = this.zones.find((r) => r.zoneCode === selectedZoneCode);
		}
		return this._selectedZone;
	}

	@Exclude()
	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	@Exclude()
	private _companies: ZoneCompany[];
	@Exclude()
	get companies(): ZoneCompany[] {
		if (!this._companies) {
			const selectedZone = this.selectedZone;
			if (selectedZone) {
				this._companies = selectedZone.companies;
			} else {
				this._companies = [];
			}
		}
		return this._companies;
	}

	/*
	@Type(UserRole.typeProvider)
	@Exclude({ toPlainOnly: true })
	public roles: UserRole[];
 */

	public userRolesExpanded: UserRolesExpanded;

	@Type(UserZone.typeProvider)
	@Exclude({ toPlainOnly: true })
	public zones: UserZone[];

	@Exclude()
	private _displayZones: string[];
	@Exclude()
	get displayZones() {
		if (!this._displayZones || !this._displayZones.length) {
			this._displayZones = this.zones.filter((z) => z.show).map((z) => z.zoneCode);
		}
		return this._displayZones;
	}

	@Type(EnrollmentStatus.typeProvider)
	@Exclude({ toPlainOnly: true })
	public enrollmentStatus: EnrollmentStatus;

	public initialize(authService: AuthService): void {
		this.authService = authService;
		this.selectedCompanyIdObservable = new BehaviorSubject(this.selectedCompanyId);
		this.selectedZoneCodeObservable = new BehaviorSubject(this.selectedZoneCode);
		this.zones.forEach((zone) => {
			const memCompanies = [...zone.companies];
			/*- keep the same reference since the UI already is using it so the code below will not work till first change of the targeted zone (Client to Expert for instance)*/
			zone.companies.length = 0;
			memCompanies.forEach((company) => {
				if (company.companyNameAI.indexOf('(history)') < 0) {
					zone.companies.push(company);
				}
			});
			zone.companies = zone.companies.sort((a, b) => {
				return a.companyName.localeCompare(b.companyName);
			});
		});
	}

	public finalize() {
		this.selectedCompanyIdObservable.complete();
		this.selectedZoneCodeObservable.complete();
	}

	private __is_in_role__(roleCode: string): boolean {
		let found = this.userRolesExpanded.expandedRoles.includes(roleCode);
		if (!found) {
			found = this.userRolesExpanded.expandedRoles.includes(this.selectedCompanyId + ':' + roleCode);
			if (roleCode === 'DASHBOARD_VIEW') {
				console.log('__is_in_role__:' + roleCode + '/' + this.selectedCompanyId + '/' + found);
			}
		}

		return found;
		/*
		const role = this.roles.find((r) => r.code === roleCode);
		if (role) {
			return !role.isClientRole || role.companies.indexOf(this.selectedCompanyId) != -1;
		}
    return false;
    */
	}

	public isInRole(rolesCodes: string): boolean {
		let result = false;
		rolesCodes.split(';').forEach((roleCode) => {
      /*
			let isInRole = this.__cached_roles__[roleCode];
			if (!isInRole && isInRole !== false) {
				isInRole = this.__cached_roles__[roleCode] = this.__is_in_role__(roleCode);
			}
      */
      const isInRole = this.__is_in_role__(roleCode);
			result = result || isInRole;
		});
		return result;
	}

	public userHasRole(roleCode: string): boolean {
		return this.userRolesExpanded.expandedRoles.includes(roleCode);
		//return this.roles.some((r) => r.code === roleCode);
	}

	public isInZone(zoneCode: string) {
		const zone = this.zones.find((z) => z.zoneCode === zoneCode);
		if (zone && zone.show) {
			if (zoneCode != PredefinedUserZone.Subcontractor) {
				return zone.companies.length > 0;
			}
			return true;
		}
		return false;
	}

	public copyStorageVars(dest: any) {
		['selectedCompanyId', 'defaultUserZone'].forEach((key: string) => {
			dest.sessionStorage.setItem(key, sessionStorage.getItem(key));
			dest.localStorage.setItem(key, localStorage.getItem(key));
		});
	}

	public clone(): User {
		return classToClass(this);
	}
}
