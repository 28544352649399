<div *ngIf="model.data" class="px-0 col-24 d-flex flex-wrap">
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$FiscalNumber">CUI:</label>
    <span class="pl-2">{{model.data.fiscalNumber}}</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$IsVATPayer">CUI special:</label>
    <ng-container *ngIf="model.data.specialVAT">
      <span class="pl-2">{{model.data.specialVAT.code}}</span>
      <ng-container *ngIf="model.specialVATStartDate!==null">
        <span class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">/</span>
        <span class="pl-2">{{model.specialVATStartDate | date: 'dd.MM.yyyy'}}</span>
        <ng-container *ngIf="model.specialVATEndDate!==null">
          <label class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">-></label>
          <span class="pl-2">{{model.specialVATEndDate | date: 'dd.MM.yyyy'}}</span>
        </ng-container>
      </ng-container>
    </ng-container>
    <span *ngIf="!model.data.specialVAT" class="pl-2">nu</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$IsVATPayer">TVA:</label>
    <ng-container *ngIf="model.data.isVATPayer">
      <span class="pl-2">{{model.vatPayerPeriodicity}}</span>
      <ng-container *ngIf="model.vatPayerStartDate!==null">
        <span class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">/</span>
        <span class="pl-2">{{model.vatPayerStartDate | date: 'dd.MM.yyyy'}}</span>
        <ng-container *ngIf="model.vatPayerEndDate!==null">
          <label class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">-></label>
          <span class="pl-2">{{model.vatPayerEndDate | date: 'dd.MM.yyyy'}}</span>
        </ng-container>
      </ng-container>
    </ng-container>
    <span *ngIf="!model.data.isVATPayer" class="pl-2">nu</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$VatOnCollectionStatus">TVA la încasare:</label>
    <ng-container *ngIf="model.data.isVATPayerOnCollection">
      <span *ngIf="model.data.isVATPayerOnCollection" class="pl-2">da</span>
      <ng-container *ngIf="model.vatOnCollectionStartDate!==null">
        <span class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">/</span>
        <span class="pl-2">{{model.vatOnCollectionStartDate | date: 'dd.MM.yyyy'}}</span>
        <ng-container *ngIf="model.vatOnCollectionEndDate!==null">
          <label class="pl-2" i18n="@@DocumentsSummary$DateVATPayerFrom">-></label>
          <span class="pl-2">{{model.vatOnCollectionEndDate | date: 'dd.MM.yyyy'}}</span>
        </ng-container>
      </ng-container>
    </ng-container>
    <span *ngIf="!model.data.isVATPayerOnCollection" class="pl-2">nu</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$InactivationStatus">Activ:</label>
    <span *ngIf="model.data.isInactive" class="pl-2">nu</span>
    <span *ngIf="!model.data.isInactive" class="pl-2">da</span>
  </div>
  <div *ngIf="(model.data.caenCode || model.data.caenDescription)" class="col-auto">
    <label i18n="@@DocumentsSummary$Caen">Caen:</label>
    <span class="pl-2">{{model.data.caenCode || ''}}{{model.data.caenCode || model.data.caenDescription?' - ':''}}{{model.data.caenDescription || ''}}</span>
  </div>
  <div *ngIf="(model.data.hasStatutoryReportsDefinitions===false)" class="col-auto">
    <label i18n="@@DocumentsSummary$VF">Vector Fiscal:</label>
    <span i18n="@@DocumentsSummary$VFMiffing" class="red-text pl-2 font-weight-bold">Lipsa</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$VF">Ultima luna inchisa:</label>
    <span i18n="@@DocumentsSummary$VFMiffing" class="pl-2">{{model.lastClosedMonth}}</span>
  </div>
  <div class="col-auto">
    <label i18n="@@DocumentsSummary$VF">OSS:</label>
    <span *ngIf="model.data.hasOSS" class="pl-2">{{model.ossStartDate | date: 'dd.MM.yyyy'}}</span>
    <span *ngIf="!model.data.hasOSS" class="pl-2">nu</span>
  </div>
</div>
