import { Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';

import { PredefinedUserZone } from '@saliente/common-auth';
import { AppMenuService, AppMenuModel } from '@saliente/common-appmenu';

import { EXPERT_ROUTES } from '@saliente/expert';

import { CLIENTS_ROUTES } from '../clients.routes.constants';
import { PAYROLL_ROUTES } from 'src/app/payroll/payroll.routes.constants';
import { STOCK_ROUTES } from 'src/app/stocks/stock.routes.constants';
import { CLIENT_FORMS_ROUTES } from 'src/app/client-forms';
import { ESHOP_ROUTES } from 'src/app/eshop/eshop.routes.constants';


@Injectable()
export class ClientsMenuService {
	constructor(private appMenuService: AppMenuService) {
		/** MAIN MENUS */
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				accessRole: 'DASHBOARD_VIEW',
				home: true,
				zone: PredefinedUserZone.Administrator,
				text: 'Dashboard',
				icon: 'mdi-action-dashboard',
				link: [CLIENTS_ROUTES.ROOT],
			})
		);
		/*
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			accessRole: 'NOTIFICATIONS_VIEW',
			zone: PredefinedUserZone.Administrator,
			text: 'Notificări',
			icon: 'mdi-social-notifications',
			link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.NOTIFICATIONS],
		}));
		*/
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				accessRole: 'CONNECT_KEEZ_ACCESS',
				zone: PredefinedUserZone.Administrator,
				text: 'Connect',
				isChat: true,
				icon: 'mdi-communication-chat',
				link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CHAT],
			})
		);
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				accessRole: 'ANAF_DATA_VIEW',
				zone: PredefinedUserZone.Administrator,
				text: 'Anaf',
				icon: 'mdi-action-settings-input-antenna',
				link: [EXPERT_ROUTES.ROOT, EXPERT_ROUTES.ANAF_MESSAGES],
			})
		);

		/** ACCOUNTING */
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				zone: PredefinedUserZone.Administrator,
				text: 'Contabilitate',
				//class: 'grey lighten-2',
				icon: 'mdi-communication-vpn-key',
				canExpand: true,
				alwaysExpanded: false,
				isAtFirstExpanded: true,
				isAccountingMenu: true,
				childrenMenu: [
					plainToClass(AppMenuModel, {
						accessRole: 'ACCNT_DOCUMENTS_VIEW_OWN;ACCNT_DOCUMENTS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Documente',
						icon: 'mdi-content-content-copy',
						class: 'child',
						leafLink: true,
						isFinancialDocuments: true,
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACC_DOCUMENTS],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'DASHBOARD_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Rapoarte',
						class: 'child',
						icon: 'mdi-action-find-in-page',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACCOUNTING_REPORTS],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'ACCNT_REPORTS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Rezultate lunare',
						icon: 'mdi-content-archive', //AM. 08.11.2021. task 5094. old: mdi-action-trending-up',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ACCOUNTING_HOME],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						accessRole: 'DASHBOARD_VIEW',
						text: 'Documente lipsă',
						isMissingDocsMenu: true,
						icon: 'mdi-communication-no-sim',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISSING_DOCUMENTS],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						accessRole: 'BALANCE_SHEET_VIEW',
						text: 'Bilanțuri',
						isBalanceSheet: true,
						icon: 'mdi-av-new-releases',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.BALANCE_SHEET_CLIENT],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						text: 'Declarații unice',
						icon: 'mdi-action-note-add',
						class: 'child',
						link: [CLIENT_FORMS_ROUTES.ROOT, CLIENT_FORMS_ROUTES.DU_PFA_FORMS]
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'IMPORT_OPS',
						visibleRole: 'IMPORT_OPS',
						zone: PredefinedUserZone.Administrator,
						text: 'Import',
						isImportMenu: true,
						icon: 'mdi-action-note-add',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.IMPORT],
					}),
	
					/*plainToClass(AppMenuModel, {
					accessRole: 'ACCNT_DOCUMENTS_VIEW',
					zone: PredefinedUserZone.Administrator,
					text: 'ANAF',
					icon: 'mdi-editor-format-list-bulleted',
					class: 'child',
					leafLink: true,
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ANAF_SUMMARY]
				})*/
				],
			})
		);

		/** PAYROLL */
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				zone: PredefinedUserZone.Administrator,
				text: 'Salarizare',
				//class: 'grey lighten-2',
				icon: 'mdi-communication-vpn-key',
				canExpand: true,
				isPayrollMainMenu: true,
				childrenMenu: [
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						accessRole: 'EMPLOYEE_VIEW',
						text: 'Salariați',
						isSalariatiMenu: true,
						icon: 'mdi-social-people',
						class: 'child',
						//link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.EMPLOYEES],
						link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.EMPLOYEES],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						accessRole: 'PAYROLL_TIMESHEET_VIEW',
						text: 'Pontaj',
						icon: 'mdi-av-timer',
						class: 'child',
						leafLink: true,
						link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.CALCULATION_ROOT, 'vacation'],
						//link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TIMESHEET],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						accessRole: 'EMPLOYEE_VIEW',
						text: 'Concedii medicale',
						icon: 'mdi-maps-local-hospital',
						class: 'child',
						link: [PAYROLL_ROUTES.ROOT, PAYROLL_ROUTES.SICK_LEAVE],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'PAYROLL_DOCUMENTS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Documente',
						icon: 'mdi-content-content-copy',
						class: 'child',
						leafLink: true,
						//disabled: true,
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.PAYROLL_DOCUMENTS],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'PAYROLL_REPORTS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Rezultate lunare',
						icon: 'mdi-content-archive', //'mdi-file-folder-shared',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.PAYROLL_HOME],
					}),
				],
			})
		);

		/** INVOICING */
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				zone: PredefinedUserZone.Administrator,
				text: 'Facturare',
				isInvoiceMenu: true,
				//class: 'grey lighten-2',
				icon: 'mdi-communication-vpn-key',
				canExpand: true,
				childrenMenu: [
					plainToClass(AppMenuModel, {
						accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Facturi',
						icon: 'mdi-editor-attach-money',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.INVOICES],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						text: 'e-Factura',
						isEFacturaMenu: true,
						accessRole: 'EFACTURA',
						icon: 'mdi-e-letter ml-1 mr-4',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.E_FACTURA, 'inbox']
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Articole',
						icon: 'mdi-content-content-paste',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.ITEMS],
					}),
				],
			})
		);

		/** STOCKS */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Administrator,
			text: 'Stocuri',
			//icon: 'mdi-communication-vpn-key',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Intrări',
					icon: 'mdi-editor-vertical-align-top mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.INPUT_EVENTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Ieșiri',
					icon: 'mdi-editor-vertical-align-bottom mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.OUTPUT_EVENTS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Inventare',
					icon: 'mdi-editor-vertical-align-center mdi-rotate-90',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_MANAGEMENT, STOCK_ROUTES.INVENTORY]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Rapoarte',
					icon: 'mdi-action-find-in-page',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_REPORTS]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
					zone: PredefinedUserZone.Administrator,
					text: 'Articole',
					icon: 'mdi-content-content-paste',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.ITEMS]
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Închidere și descărcare',
					icon: 'mdi-notification-event-available',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_PERIODS]
				}),
				/*plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					text: 'Gestiuni',
					icon: 'mdi-content-content-paste',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.WAREHOUSES]
				}),*/
			]
		}));

		/** ESHOP */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Administrator,
			text: 'Magazin online',
			icon: 'mdi-action-shopping-cart',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					text: 'Servicii',
					icon: 'mdi-action-store',
					accessRole: 'ESHOP_ORDER_VIEW',
					class: 'child',
					link: [ESHOP_ROUTES.ROOT, ESHOP_ROUTES.PRODUCTS],
				}),
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					text: 'Comenzile mele',
					icon: 'mdi-action-shopping-cart',
					accessRole: 'ESHOP_ORDER_VIEW',
					class: 'child',
					link: [ESHOP_ROUTES.ROOT, ESHOP_ROUTES.ORDERS],
				}),
			]
		}));

		/** FORMS */
		this.appMenuService.addMenu(plainToClass(AppMenuModel, {
			zone: PredefinedUserZone.Administrator,
			text: 'Chestionare',
			//icon: 'mdi-communication-vpn-key',
			canExpand: true,
			childrenMenu: [
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					text: 'Chestionarele mele',
					icon: 'mdi-action-note-add',
					class: 'child',
					link: [CLIENT_FORMS_ROUTES.ROOT, CLIENT_FORMS_ROUTES.CLIENT_FORMS]
				})
			]
		}));

		/** CONFIG */
		this.appMenuService.addMenu(
			plainToClass(AppMenuModel, {
				zone: PredefinedUserZone.Administrator,
				text: 'Configurări',
				//class: 'grey lighten-2',
				icon: 'mdi-action-settings',
				canExpand: true,
				childrenMenu: [
					plainToClass(AppMenuModel, {
						accessRole: 'CONFIG_COMPANY_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Firmă',
						icon: 'mdi-social-location-city',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.COMPANY],
					}),
					/*plainToClass(AppMenuModel, {
						accessRole: 'CONFIG_COMPANY_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Abonament',
						icon: 'mdi-action-schedule',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.SUBSCRIPTION],
					}),*/
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						text: 'Contract Keez',
						icon: 'mdi-maps-map',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CLIENT_CONTRACT, 'contract'],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'INVOICES_VIEW_OWN;INVOICES_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Parteneri',
						icon: 'mdi-communication-business',
						class: 'child',
						leafLink: true,
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.CLIENTPARTNERS],
					}),
					plainToClass(AppMenuModel, {
						zone: PredefinedUserZone.Administrator,
						text: 'Formulare',
						icon: 'mdi-action-note-add',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISC, CLIENTS_ROUTES.TEMPLATES],
					}),
					/*plainToClass(AppMenuModel, {
						accessRole: 'IMPORT_OPS',
						zone: PredefinedUserZone.Administrator,
						text: 'Import',
						isImportMenu: true,
						icon: 'mdi-action-note-add',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.IMPORT],
					}),*/
					plainToClass(AppMenuModel, {
						accessRole: 'CARS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Autovehicule',
						icon: 'mdi-maps-directions-car',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.MISC, CLIENTS_ROUTES.VEHICLES],
					}),
					plainToClass(AppMenuModel, {
						accessRole: 'USERS_VIEW',
						zone: PredefinedUserZone.Administrator,
						text: 'Utilizatori',
						icon: 'mdi-action-account-circle',
						class: 'child',
						link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.USERS],
					}),
					/*plainToClass(AppMenuModel, {
					accessRole: 'USERS_VIEW',
					zone: PredefinedUserZone.Administrator,
					text: 'Agenți',
					icon: 'mdi-hardware-phone-iphone',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.AGENTS],
				}),*/
				plainToClass(AppMenuModel, {
					zone: PredefinedUserZone.Administrator,
					accessRole: 'INVENTORY_EVENT_VIEW',
					text: 'Stocuri',
					icon: 'mdi-content-content-paste',
					class: 'child',
					link: [STOCK_ROUTES.ROOT, STOCK_ROUTES.INVENTORY_CONFIG]
				}),
				plainToClass(AppMenuModel, {
					accessRole: 'TAKEOVER_VIEW',
					zone: PredefinedUserZone.Administrator,
					text: 'Preluare',
					icon: 'mdi-communication-call-merge mdi-rotate-180',
					class: 'child',
					link: [CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.TAKEOVER],
				})
			]
		}));
	}
}
