import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { AuthService, PredefinedUserZone } from "@saliente/common-auth";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MicroProfitFormModel } from "../client-forms.models";
import { ClientFormsService } from "../client-forms.service";
import { Align } from "@progress/kendo-angular-popup";

@Component({
	selector: 'mp2024-form',
	templateUrl: 'mp2024-form.component.html',
    styleUrls: ['mp2024-form.component.scss']

})
export class MP2024FormComponent implements OnInit, OnDestroy {
    @Input()
    public isDialog: boolean;
    @Input()
    public clientEidParam: string;
    @Input()
    public instanceParam: string;

    @Output() public close = new EventEmitter();

    //subscriptions
	private mp2024DataSubscription: Subscription;
    
    //models
    public mp2024FormModel: MicroProfitFormModel;

    public clientEid: string;
    public instance: string;

    //booleans
    public isReadOnly: boolean;
    public hasViewRight: boolean;
    public isLoading: boolean;
    public showConfirmationDialog: boolean = false;
    public caenCodesPopupVisible: boolean = false;
    public isConclusionSet: boolean = false;

    //UI
	public caenCodesPopupAlign: Align = { horizontal: "right", vertical: "bottom" };
	public caedCodesAnchorAlign: Align = { horizontal: "center", vertical: "top" };
	@ViewChild("caenCodesAnchor") public caenCodesAnchor: ElementRef;
	@ViewChild("caenCodesPopup", { read: ElementRef }) public caenCodesPopup: ElementRef;

    get are1FTEDisabled(): boolean {
        return this.isP2M && (this.mp2024FormModel.data.doresteMicro == null || !this.mp2024FormModel.data.doresteMicro);
    }

    get areConsultanta80Disabled(): boolean {
        return this.mp2024FormModel.data.are1FTE == null || !this.mp2024FormModel.data.are1FTE;
    }

    get caSub500kDisabled(): boolean {
        return this.mp2024FormModel.data.areConsultanta80 == null || this.mp2024FormModel.data.areConsultanta80;
    }

    get are253MicroDisabled(): boolean {
        return this.mp2024FormModel.data.caSub500k == null || !this.mp2024FormModel.data.caSub500k;
    }

    get pastreazaFirmaMicroDisabled(): boolean {
        return this.mp2024FormModel.data.are253Micro == null || !this.mp2024FormModel.data.are253Micro;
    }

    get saveDisabled(): boolean {
        return !this.isConclusionSet;
    }

    get isP2M(): boolean {
        return this.mp2024FormModel.formDataParameters.schimbare == "p2m";
    }

    get isM2P(): boolean {
        return this.mp2024FormModel.formDataParameters.schimbare == "m2p";
    }

    constructor(private clientFormsService: ClientFormsService, private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
            private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.mp2024DataSubscription = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.clientEid = this.isDialog ? this.clientEidParam : params.get('id');
                    this.instance = this.isDialog ? this.instanceParam : params.get('instance');

                    return this.clientFormsService.getMicroProfitData(this.clientEid, this.instance);
                })
		    )
			.subscribe((mp2024FormData: MicroProfitFormModel) => {
                if (mp2024FormData) {
                    this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || mp2024FormData.formData.status == 'Submitted';

                    this.hasViewRight = this.authService.isInRole('EXPERT') || this.authService.isInRole('ZEUS') 
                        || this.authService.isInRole(mp2024FormData.formData.displayRole);

                    if (this.hasViewRight) {
                        this.mp2024FormModel = mp2024FormData;
                        this.initializeConclusion();

                        if (this.mp2024FormModel.formData.status != 'Submitted') {
                            this.computeBooleans();
                        }

                        this.getMP2024FormConclusion();
                    }
                }
                else {
                    this.mp2024FormModel = null;
                }

                this.isLoading = false;

                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 200);
		});
    }

    ngOnDestroy(): void {
        if (this.mp2024DataSubscription) { this.mp2024DataSubscription.unsubscribe(); this.mp2024DataSubscription = null; }
    }

    public onAre1FTEHelp() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-angajat";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAreConsultanta80() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-venituri-din-consultanta";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onCaSub500k() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2024.html#intreprinderi-legate";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onAre253Micro() {
        let url = "https://app.keez.ro/help/client/faq/modificari_fiscale_2023.html#conditie-micro-actionari-cu-25-din-partile-sociale-la-3-firme";
		window.open(url, '_blank', 'noopener noreferrer');
    }

    public onRamaneMicroHelp() {
        let url = this.mp2024FormModel.data.ramaneMicro 
            ? "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-venit"
            : "https://app.keez.ro/help/client/antreprenor/taxe_impozite.html#impozitul-pe-profit";

        window.open(url, '_blank', 'noopener noreferrer');   
    }

    public showCaenCodes() {
		this.caenCodesPopupVisible = !this.caenCodesPopupVisible;
    }

    public computeBooleans() {
        if (this.isP2M) {
            this.onDoresteMicroChange();
        }

        this.onAre1FTEChange();
        this.onAreConsultanta80Change();
        this.onAre253MicroChange();
        this.onPastreazaFirmaMicroChange();
    }

    public initializeConclusion() {
        this.mp2024FormModel.data.ramaneMicro = this.mp2024FormModel.data.ramaneMicro == null ? false : this.mp2024FormModel.data.ramaneMicro;
        this.mp2024FormModel.data.devineProfit = this.mp2024FormModel.data.devineProfit == null ? false : this.mp2024FormModel.data.devineProfit;
        this.mp2024FormModel.data.devineMicro = this.mp2024FormModel.data.devineMicro == null ? false : this.mp2024FormModel.data.devineMicro;
        this.mp2024FormModel.data.ramaneProfit = this.mp2024FormModel.data.ramaneProfit == null ? false : this.mp2024FormModel.data.ramaneProfit;
    }

    public noConclusion() {
        this.mp2024FormModel.data.ramaneMicro = false;
        this.mp2024FormModel.data.devineProfit = false;
        this.mp2024FormModel.data.devineMicro = false;
        this.mp2024FormModel.data.ramaneProfit = false;

        this.isConclusionSet = false;
    }

    public ramaneMicro() {
        this.mp2024FormModel.data.ramaneMicro = true;
        this.mp2024FormModel.data.devineProfit = false;
        this.mp2024FormModel.data.devineMicro = false;
        this.mp2024FormModel.data.ramaneProfit = false;

        this.isConclusionSet = false;
    }

    public devineMicro() {
        this.mp2024FormModel.data.ramaneMicro = false;
        this.mp2024FormModel.data.devineProfit = false;
        this.mp2024FormModel.data.devineMicro = true;
        this.mp2024FormModel.data.ramaneProfit = false;

        this.isConclusionSet = false;
    }

    public ramaneProfit() {
        this.mp2024FormModel.data.ramaneMicro = false;
        this.mp2024FormModel.data.devineProfit = false;
        this.mp2024FormModel.data.devineMicro = false;
        this.mp2024FormModel.data.ramaneProfit = true;

        this.isConclusionSet = true;
    }

    public devineProfit() {
        this.mp2024FormModel.data.ramaneMicro = false;
        this.mp2024FormModel.data.devineProfit = true;
        this.mp2024FormModel.data.devineMicro = false;
        this.mp2024FormModel.data.ramaneProfit = false;

        this.isConclusionSet = true;
    }

    public resetAnswersFromQuestionBelow(fromQuestionBelow: string) {
        if (fromQuestionBelow == "DoresteMicro") {
            this.mp2024FormModel.data.are1FTE = null;
            this.mp2024FormModel.data.areConsultanta80 = null;
            this.mp2024FormModel.data.caSub500k = null;
            this.mp2024FormModel.data.are253Micro = null;
            this.mp2024FormModel.data.pastreazaFirmaMicro = null;
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "Are1FTE") {
            this.mp2024FormModel.data.areConsultanta80 = null;
            this.mp2024FormModel.data.caSub500k = null;
            this.mp2024FormModel.data.are253Micro = null;
            this.mp2024FormModel.data.pastreazaFirmaMicro = null;
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "AreConsultanta80") {
            this.mp2024FormModel.data.caSub500k = null;
            this.mp2024FormModel.data.are253Micro = null;
            this.mp2024FormModel.data.pastreazaFirmaMicro = null;
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "CaSub500k") {
            this.mp2024FormModel.data.are253Micro = null;
            this.mp2024FormModel.data.pastreazaFirmaMicro = null;
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "Are253Micro") {
            this.mp2024FormModel.data.pastreazaFirmaMicro = null;
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
        if (fromQuestionBelow == "PastreazaFirmaMicro") {
            this.mp2024FormModel.data.hasCaenCode = null;
            this.mp2024FormModel.data.micro1p = null;
            this.mp2024FormModel.data.micro3p = null;
        }
    }

    public onDoresteMicroChange() {
        if (this.mp2024FormModel.data.doresteMicro == null) 
            return;

        if (this.mp2024FormModel.data.doresteMicro) { // answer: yes - no conclusion yet, need to answer the following questions
           this.noConclusion();
        }
        else { // answer: no - there is no need for the other questions; conclusion: ramaneProfit
            this.ramaneProfit();

            this.resetAnswersFromQuestionBelow("DoresteMicro");
        }
    }

    public onAre1FTEChange() {
        if (this.mp2024FormModel.data.are1FTE == null) 
            return;

        if (this.mp2024FormModel.data.are1FTE) { // answer: yes - no conclusion yet, need to answer the following questions
           this.noConclusion();
        }
        else { // answer: no - there is no need for the other questions: devineProfit / ramaneProfit
            if (this.isM2P) {
                this.devineProfit();
            }
            if (this.isP2M) {
                this.ramaneProfit();
            }

            this.resetAnswersFromQuestionBelow("Are1FTE");
        }
    }

    public onAreConsultanta80Change() {
        if (this.mp2024FormModel.data.areConsultanta80 == null) 
            return;

        if (this.mp2024FormModel.data.areConsultanta80) { // answer: yes - there is no need for the following questions: devineProfit / ramaneProfit
            if (this.isM2P) {
                this.devineProfit();
            }
            if (this.isP2M) {
                this.ramaneProfit();
            }

            this.resetAnswersFromQuestionBelow("AreConsultanta80");
        }
        else { // answer: no - no conclusion yet, need to answer the following questions
            this.noConclusion();
        }
    }

    public onCaSub500kChange() { 
        if (this.mp2024FormModel.data.caSub500k == null) 
            return;

        if (this.mp2024FormModel.data.caSub500k) { // answer: yes - no conclusion yet, need to answer the following questions
            this.noConclusion();
        }
        else { // answer: no - there is no need for the following questions: devineProfit / ramaneProfit
            if (this.isM2P) {
                this.devineProfit();
            }
            if (this.isP2M) {
                this.ramaneProfit();
            }
            
            this.resetAnswersFromQuestionBelow("CaSub500k");
        }
    }

    public onAre253MicroChange() {
        if (this.mp2024FormModel.data.are253Micro == null) 
            return;

        if (this.mp2024FormModel.data.are253Micro) { // answer: yes - no conclusion yet, need to answer the following questions
            this.noConclusion();
        }
        else { // answer: no - there is no need for the following questions: ramaneMicro / devineMicro
            if (this.isM2P) {
                this.ramaneMicro();
            }
            if (this.isP2M) {
                this.devineMicro();
            }

            this.resetAnswersFromQuestionBelow("Are253Micro");
        }
    }

    public onPastreazaFirmaMicroChange() {
        if (this.mp2024FormModel.data.pastreazaFirmaMicro == null) 
            return;

        if (this.mp2024FormModel.data.pastreazaFirmaMicro) { // answer: yes - ramaneMicro / devineMicro
            if (this.isM2P) {
                this.ramaneMicro();
            }
            if (this.isP2M) {
                this.devineMicro();
            }
        }
        else { // answer: no - devineProfit / ramaneProfit
            if (this.isM2P) {
                this.devineProfit();
            }
            if (this.isP2M) {
                this.ramaneProfit();
            }

            this.resetAnswersFromQuestionBelow("PastreazaFirmaMicro");
        }
    }

    public onAreCodCaenChange() {
        if (this.mp2024FormModel.data.hasCaenCode) {
            this.mp2024FormModel.data.micro3p = true;
            this.mp2024FormModel.data.micro1p = false;
        }
        else {
            this.mp2024FormModel.data.micro1p = true;
            this.mp2024FormModel.data.micro3p = false;
        }

        this.isConclusionSet = true;
    }
    
    public getMP2024FormConclusion() {
        if (this.mp2024FormModel.data.ramaneMicro) {
            return 'Firma va rămâne Microîntreprindere - plătitoare de impozit pe venit.';
        }
        else if (this.mp2024FormModel.data.devineMicro) {
            return 'Firma va deveni Microîntreprindere - plătitoare de impozit pe venit.'
        }
        else if (this.mp2024FormModel.data.ramaneProfit) {
            return 'Firma va ramane plătitoare de impozit pe profit.';
        }
        else if (this.mp2024FormModel.data.devineProfit) {
            return 'Firma va deveni plătitoare de impozit pe profit.';
        }
        else {
            return null;
        }
    }

    public getMP2024ProcentVenitConclusion() {
        if (this.mp2024FormModel.data.micro1p) {
            return 'Microîntreprindere - plătitoare de impozit pe venit 1%.';
        }
        else if (this.mp2024FormModel.data.micro3p) {
            return 'Microîntreprindere - plătitoare de impozit pe venit 3%.'
        }
        else {
            return null;
        }
    }

    public openConfirmationDialog() {
        this.showConfirmationDialog = true;
    }

    public closeConfirmationDialog() {
        this.showConfirmationDialog = false;
    }

    public async saveMicroProfitForm() {
        let result = await this.clientFormsService.updateClientFormMicroProfitData(this.clientEid, this.instance, this.mp2024FormModel.data);
        if (result) {
            if (!this.isDialog) {
                this.mp2024FormModel.formData.status = "Submitted";
                this.isReadOnly = this.authService.selectedZoneCode == PredefinedUserZone.Expert || this.mp2024FormModel.formData.status == 'Submitted';
            }
            
            this.cancelMicroProfitForm();
            this.toastr.success("Formularul a fost salvat cu succes!");
        }
    }

    public cancelMicroProfitForm() {
        this.closeConfirmationDialog();

        if (this.isDialog) {
            this.close.emit();
        }
    }

    @HostListener("document:click", ["$event"])
	public documentClick(event: any): void {
		if (!this.contains(event.target)) {
			this.caenCodesPopupVisible = false;
		}
	}

	private contains(target: any): boolean {
		return (
		  (this.caenCodesAnchor ? this.caenCodesAnchor.nativeElement.contains(target) : false) ||
		  (this.caenCodesPopup ? this.caenCodesPopup.nativeElement.contains(target) : false)
		);
	}
}
