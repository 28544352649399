import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { GalleryImage } from "@saliente/library";
import { from, Observable, Subscription } from "rxjs";
import { ClientAccDocumentsService } from "src/app/clients/documents/acc-documents.service";
import { ClientDocumentItemComponent } from "src/app/clients/documents/document-item.component";
import { AccDocumentFileModel } from "src/app/clients/documents/documents.models";
import { ExpertDocumentItemComponent } from "src/app/expert/documents/expert-document-item.component";
import { PredefinedUserZone } from "../auth/auth.models";
import { AuthService } from "../auth/auth.service";
import { DocumentFileBaseModel } from "../documents/documents-base.models";
import { DocumentFileModel } from "../documents/documents.models";
import { FinancialDocumentFileModel } from "../documents/financial-documents.models";
import { NavigableDataSource } from "../general/navigable_datasource";
import { IClientDocumentItemParent } from "./client-document-item-parent";
import { DocumentIndentificationModel } from "./document-display.models";
import { DocumentDisplayService } from "./document-display.service";
import { IExpertDocumentItemParent } from "./expert-document-item-parent";

@Component({
	selector: 'document-display',
	templateUrl: 'document-display.component.html',
	styleUrls: ['document-display.component.scss'],
})
export class DocumentDisplayComponent implements OnInit, OnDestroy {
	public documentFileSubscription: Subscription;
	public documentFile: any;

	public documentFileExists: boolean = false;
	public documentFileLoading: boolean;

	public isExpert: boolean = false;
	public isClient: boolean = false;
	public isPayrollDoc: boolean = false;

	@Input() public documentIndentification: DocumentIndentificationModel;
	@Input() public documentsNavigator: NavigableDataSource<string, DocumentFileModel>;
	@Input() public expertDocumentsNavigator: NavigableDataSource<string, FinancialDocumentFileModel>;

	@Output() public close = new EventEmitter();

    constructor(public documentDisplayService: DocumentDisplayService, private authService: AuthService, private cdr: ChangeDetectorRef,) {
		this.isClient = this.authService.selectedZoneCode == PredefinedUserZone.Administrator;
		this.isExpert = this.authService.selectedZoneCode == PredefinedUserZone.Expert;
	}

	ngOnInit(): void {
		this.isPayrollDoc = this.documentIndentification.documentType == "payroll" ? true : false;

		this.documentFileLoading = true;

		this.documentFileSubscription = from(this.documentDisplayService.getDocument(this.documentIndentification))
			.subscribe((document) => {
				this.documentFileLoading = false;
				
				if (document) {
					this.documentFile = document;
					this.documentFileExists = true;
					this.cdr.detectChanges();
				}
			})
		
		document.body.style.overflowY = "hidden";
	}

	ngOnDestroy() {
		if (this.documentFileSubscription) { this.documentFileSubscription.unsubscribe(); this.documentFileSubscription = null; }

		// show parent scroll
		document.body.style.overflowY = "";
	}

	get showDocument(): boolean {
		return !this.documentFileLoading && this.documentFile;
    }

	get showDocumentTextNotExist(): boolean {
		return !this.documentFileLoading && !this.documentFileExists;
	}

	getRouteComponent(): string {
		return null;
	}

	initLazy(): void {
		return null;
	}
}