<section class="row m-0">
	<form #documentStatusChangeForm="ngForm" class="col-24 px-0">
		<label class="radio-input">
			<input type="radio" name="reasonCode" value="neclar" [(ngModel)]="reasonCode" kendoRadioButton />Neclar(nu se poate citi, trebuie refăcută
			poza)
		</label>
		<label class="radio-input">
			<input type="radio" name="reasonCode" value="incomplet" [(ngModel)]="reasonCode" kendoRadioButton />Incomplet
		</label>
		<kee-textarea *ngIf="model.reasonCode=='incomplet'" name="comment" class="col-24" i18n-label="@@DocumentStatusChange$Comment" label="Detalii"
			required [(ngModel)]="model.comment">
		</kee-textarea>
		<label class="radio-input">
			<input type="radio" name="reasonCode" value="irelevant" [(ngModel)]="reasonCode" kendoRadioButton />Irelevant(Ex: aviz, contract sau alt
			document care nu se
			înregistrează în contabilitate)
		</label>
		<label class="radio-input">
			<input type="radio" name="reasonCode" value="non-eFactura" [(ngModel)]="reasonCode" kendoRadioButton />
			Factura de achiziție în format non-eFactura (PDF sau imagine).
		</label>
		<label class="radio-input">
			<input type="radio" name="reasonCode" value="alt" [(ngModel)]="reasonCode" kendoRadioButton />Alt motiv
		</label>
		<kee-textarea id="txtComment" *ngIf="model.reasonCode=='alt'" name="comment" class="col-24" i18n-label="@@DocumentStatusChange$Comment"
			label="Detalii" required [(ngModel)]="model.comment" [autofocus]="true"></kee-textarea>

		<div class="divider col-24 p-0 mt-1 mb-4"></div>
		<checkbox *ngIf="model.alsoSendInConnect != undefined" name="alsoSendInConnect" i18n-label="@@DocumentStatusChange$AlsoSendInConnect" label="Trimite mesaj în Connect"
			[(ngModel)]="model.alsoSendInConnect">
		</checkbox>
		<div class="col-24 d-flex justify-content-around">
			<button class="btn btn-primary waves-effect waves-light" type="submit" name="action" i18n="@@Actions$Save"
				(click)="this.save.emit(documentStatusChangeForm)">
				<i class="mdi-content-save"></i>
				Salvează
			</button>
			<button class="btn waves-effect waves-light" type="button" name="action" i18n="@@Actions$Cancel"
				(click)="this.close.emit(documentStatusChangeForm)">
				<i class="mdi-content-clear"></i>
				Renunță
			</button>
		</div>
	</form>
</section>